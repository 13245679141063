import { all, call, put, takeLatest, select } from 'redux-saga/effects';

import {
  DO_REQUEST,
  DO_SOCKET_REQUEST,
  GET_PROFILE,
  GOLD_DO_REQUEST,
  GOLD_POLLING_START,
  LOGIN,
  LOGOUT,
  SET_TOKEN,
  GET_SYSTEM,
  RESET_SYSTEM,
  SET_SOCKET,
  SET_STORE,
  SET_DATA_SOCKET
} from '../constants';
import {
  loginFailed,
  logged,
  me,
  requestFailed,
  requestSuccess,
  reset,
  getSystemOk,
  getSystemKo,
  patchSystem,
  resetSystemOk,
  setSocketOk,
  setStoreOk,
  socketRequestFailed,
  socketRequestSuccess,
  socketRequestReset,
  setDataSocketOk,
  goldRequestEnd,
  goldPollingEnd
} from '../actions';
import * as Cloud from '../api/Cloud';

function* callApi(action) {
  const refreshMethods = [
    'enroll'
  ];
  if (Cloud[action.request] === undefined) {
    yield put(requestFailed(`La richiesta é fallita perché il ${action.request} non esiste`));
  } else {
    var [data, code] = yield call(Cloud[action.request], action.payload);
    if (code === 200 || code === 201) {
      yield put(requestSuccess(data));
      if (action.request == 'editSystem') {
        [data, code] = yield call(Cloud.getSystem, action.payload.id);
        if (code === 200) {
          yield put(getSystemOk(data));
        } else {
          yield put(getSystemKo(data));
        }
      } else if (action.request == 'patchSystem') {
        yield put(patchSystem(action.payload));
      } else if (refreshMethods.includes(action.request)) {
        [data, code] = yield call(Cloud.getUser);
        if (code === 200) {
          yield put(me(data));
        }
      }
    } else if (code === 401) {
      yield put(loginFailed("Sessione scaduta, effettuare nuovamente il login"));
    } else if (code === 504 && action.request === 'createSystem') {
      yield put(requestFailed(`La centrale non risulta collegata al Cloud, effettuare la connessione e riprovare.`));
    } else {
      var errors = data || process.env.REACT_APP_ERROR_TEXT;
      yield put(requestFailed(`${errors}`));
    }
  }
}

function* callGoldApi(action) {
  const data = [];
  const errors = [];
  var _data = { status: 'OK' };
  var _code = 200;
  var k = 0;
  for(let j = 0; j < 5; j++) {
    let polling = yield select((state) => state.polling);
    if(polling) {
      yield call(Cloud.sleep, 1000);
      k = 1;
    } else {
      if(action.login) {
        [_data, _code] = yield call(Cloud.goldLogin, { 
          id_centrale: sessionStorage.getItem('systemIDC'), 
          code: sessionStorage.getItem('systemCode') 
        });  
      }
      if (_code === 200 && _data.status === 'OK') {
        yield call(Cloud.sleep, 500);
        for(let i = 0; i < action.requests.length; i++) {
          let [__data, __code] = yield call(Cloud[action.requests[i]], action.payloads[i]);
          if (__code === 200 && (__data.status === 'OK' || __data.status === 'SYNC')) {
            data.push(__data);
          } else {
            errors.push(action.requests[i]);
            break;
          }
          yield call(Cloud.sleep, 500);
        }
        yield put(goldRequestEnd(data, errors.length > 0 ? errors : null));
      } else if (_code === 401) {
        yield put(loginFailed("Sessione scaduta, effettuare nuovamente il login"));
      } else {
        yield put(goldRequestEnd(true, [process.env.REACT_APP_ERROR_TEXT]));
      }
      k = 0;
      break;  
    }
  }
  if(k === 1) {
    yield put(goldRequestEnd(true, [process.env.REACT_APP_ERROR_TEXT]));
  }
}

function* callSocket({ socket, trama, payload, auth, noSuccess, noError, redirect = null }) {
  const system = yield select((state) => state.system);
  if (!!!system) {
    yield put(socketRequestFailed(`Impianto non riconosciuto`));
  } else {
    const status = system.store.status;
    if (auth && !!!status.structs.isTeknoxAuthorized.auth_level) {
      if (noError) {
        yield put(socketRequestReset());
      } else {
        yield put(socketRequestFailed(`Necessario effettuare il login all'impianto`));
      }
    } else if (!!!system.store.connected) {
      if (noError) {
        yield put(socketRequestReset());
      } else {
        yield put(socketRequestFailed(`Impianto non connesso`));
      }
    } else if (status.structs.comandicentrale.sync_euronet_cloud) {
      if (noError) {
        yield put(socketRequestReset());
      } else {
        yield put(socketRequestFailed(`Sincronizzazione Centrale -> Euronet in corso`));
      }
    } else if (system.store.sync_cloud_euronet) {
      if (noError) {
        yield put(socketRequestReset());
      } else {
        yield put(socketRequestFailed(`Sincronizzazione Euronet -> Cloud in corso`));
      }
    } else {
      const obj = {
        type: trama,
        payload
      };
      socket.emit('sendCommand', JSON.stringify(obj));
      if (noSuccess) {
        yield put(socketRequestReset());
      } else {
        yield put(socketRequestSuccess(`Comando inviato con successo all'impianto`, redirect));
      }
    }
  }
}

function* goldPolling(action) {
  const xhr = yield select((state) => state.xhr);
  const polling_pause = yield select((state) => state.system.store.polling_pause);
  if(!xhr && !polling_pause && Array.isArray(action.requests)) {
    for(let i = 0; i < action.requests.length; i++) {
      let [__data, __code] = yield call(Cloud[action.requests[i]], action.payloads[i]);
      if(i === 0) {
        if(__code !== 200 || __data.status === 'UNAUTHORIZED') {
          yield call(Cloud.sleep, 100);
          let [_data, _code] = yield call(Cloud.goldLogin, { 
            id_centrale: sessionStorage.getItem('systemIDC'), 
            code: sessionStorage.getItem('systemCode') 
          }); 
          if(_code === 200 && _data.status === 'OK') {
            yield call(Cloud.sleep, 100);
            yield call(Cloud[action.requests[i]], action.payloads[i]);
          }    
        }
      }
      yield call(Cloud.sleep, 100);
    }
  }
  yield put(goldPollingEnd());
}

function* login(action) {
  let { email, password } = action.payload;
  let [data, code] = yield call(Cloud.login, email, password);
  if (code === 201) {
    let { token, user } = data;
    Cloud.setToken(token);
    yield put(logged(user));
  } else {
    yield put(loginFailed(data || "Accesso non valido, controllare le credenziali e riprovare"));
  }
}

function* logout() {
  Cloud.unsetToken();
  yield put(resetSystemOk());
  yield put(reset());
}

function* setUserProfile() {
  let token = Cloud.getToken();
  if (token) {
    let [data, code] = yield call(Cloud.getUser);
    if (code === 200) {
      yield put(me(data));
    } else {
      Cloud.unsetToken();
      yield put(reset());
    }
  } else {
    yield put(reset());
  }
}

function* setSystem(action) {
  let token = Cloud.getToken();
  if (token) {
    let data, code;
    if(!!action.access) {
      [data, code] = yield call(Cloud.getSystemAccess, action.id);
    } else {
      [data, code] = yield call(Cloud.getSystem, action.id);
    }
    if (code === 200) {
      yield put(getSystemOk(data));
    } else {
      yield put(getSystemKo(data));
    }
  }
}

function* callResetSystem() {
  yield put(resetSystemOk());
}

function* callSetSocket(action) {
  yield put(setSocketOk(action.socket));
}

function* callSetStore(action) {
  yield put(setStoreOk(action.data));
}

function* callSetDataSocket(action) {
  yield put(setDataSocketOk(action.data));
}

function* writeToken(action) {
  Cloud.setToken(action.token);
  yield put(requestSuccess(true));
}

function* goldRequestWatcher() {
  yield takeLatest(GOLD_DO_REQUEST, callGoldApi);
}

function* goldPollingWatcher() {
  yield takeLatest(GOLD_POLLING_START, goldPolling);
}

function* loginWatcher() {
  yield takeLatest(LOGIN, login);
}

function* logoutWatcher() {
  yield takeLatest(LOGOUT, logout);
}

function* profileWatcher() {
  yield takeLatest(GET_PROFILE, setUserProfile);
}

function* requestWatcher() {
  yield takeLatest(DO_REQUEST, callApi);
}

function* requestSocketWatcher() {
  yield takeLatest(DO_SOCKET_REQUEST, callSocket);
}

function* setTokenWatcher() {
  yield takeLatest(SET_TOKEN, writeToken);
}

function* systemWatcher() {
  yield takeLatest(GET_SYSTEM, setSystem);
}

function* resetSystemWatcher() {
  yield takeLatest(RESET_SYSTEM, callResetSystem);
}

function* setSocketWatcher() {
  yield takeLatest(SET_SOCKET, callSetSocket);
}

function* setStoretWatcher() {
  yield takeLatest(SET_STORE, callSetStore);
}

function* setDataSocketWatcher() {
  yield takeLatest(SET_DATA_SOCKET, callSetDataSocket);
}

export default function* rootSaga() {
  yield all([
    goldRequestWatcher(),
    loginWatcher(),
    logoutWatcher(),
    profileWatcher(),
    requestWatcher(),
    setTokenWatcher(),
    resetSystemWatcher(),
    requestSocketWatcher(),
    setSocketWatcher(),
    setStoretWatcher(),
    systemWatcher(),
    setDataSocketWatcher(),
    goldPollingWatcher()
  ]);
}
