import React, { useState } from 'react';
import ReactSlider from 'react-slider';

const RilevatorePerimetraleEsterno = ({ current, auth, setMw, setPir1, setAm, setTempoIngresso, setTempoUscita }) => {
    const [ritardatoChecked, setRitardatoChecked] = useState(current.ritardato);
    const [percorsoChecked, setPercorsoChecked] = useState(current.percorso);
    const [cwsEnabled, setCwsEnabled] = useState(current.logica > 0);
    return (
        <>
            <div className="rack">
                <div className="w33">
                    <p>Numero massimo di allarmi<br /><br /></p>
                    <select defaultValue={current.cicli_autoesclusione} name="cicli_autoesclusione" disabled={auth !== 2}>
                        <option value={0}>Infiniti</option>
                        {(Array.from({length: 15}, (_, i) => i+1).map(x => <option key={`numero_allarmi_${x}`} value={x}>{x}</option>))}
                    </select>
                </div>
                <div className="w33">
                    <p>AM</p>
                    <ReactSlider
                        defaultValue={current.am}
                        onChange={value => { setAm(value); }}
                        disabled={auth !== 2}
                        max={100}
                        min={20}
                        step={5}
                        className="horizontal-slider"
                        thumbClassName="slider-thumb"
                        trackClassName="slider-track"
                        renderThumb={(props, state) => {
                            return (<div {...props}>{(state.valueNow < 25 ? 'disattivo' : `${state.valueNow}%`)}</div>);
                        }}
                    />
                </div>
                <div className="w33">
                    <p>Logica<br /><br /></p>
                    <select defaultValue={current.logica} name="logica" disabled={auth !== 2} onChange={e => { let _value = parseInt(e.target.value); setCwsEnabled(_value > 0) } }>
                        <option value={0}>PIR1 or PIR2 and MW</option>
                        <option value={0x40}>PIR1 and PIR2 and MW</option>
                        <option value={0x60}>PIR1 and PIR2</option>
                    </select>
                </div>
            </div>
            <div className="rack bottomBorder">
                <div className="w33">
                    <p>PIR</p>
                    <ReactSlider
                        defaultValue={current.pir1}
                        onChange={value => { setPir1(value); }}
                        disabled={auth !== 2}
                        min={25}
                        step={5}
                        className="horizontal-slider"
                        thumbClassName="slider-thumb"
                        trackClassName="slider-track"
                        renderThumb={(props, state) => {
                            return (<div {...props}>{`${state.valueNow}%`}</div>);
                        }}
                    />
                </div>
                <div className="w33">
                    <p>MW</p>
                    <ReactSlider
                        defaultValue={current.mw}
                        onChange={value => { setMw(value); }}
                        disabled={auth !== 2}
                        max={100}
                        min={25}
                        step={5}
                        className="horizontal-slider"
                        thumbClassName="slider-thumb"
                        trackClassName="slider-track"
                        renderThumb={(props, state) => {
                            return (<div {...props}>{`${state.valueNow}%`}</div>);
                        }}
                    />
                </div>
                <div className="w33">
                    <p>CWS<br /><br /></p>
                    <select defaultValue={current.cd_cs} name="cd_cs" disabled={auth !== 2 || !cwsEnabled}>
                        <option value={0}>Off</option>
                        <option value={0x08}>CD</option>
                        <option value={0x10}>CS</option>
                    </select>
                </div>
            </div>
            <div className="rack bottomBorder">
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="escluso" name="escluso" defaultValue="0" defaultChecked={current.escluso} />
                        <label htmlFor={`escluso`}>
                            <span>
                                <span />
                            </span>
                            Escluso
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="silenzioso" name="silenzioso" defaultValue="0" defaultChecked={current.silenzioso} disabled={auth !== 2} />
                        <label htmlFor={`silenzioso`}>
                            <span>
                                <span />
                            </span>
                            Silenzioso
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="test" name="test" defaultValue="0" defaultChecked={current.test} disabled={auth !== 2} />
                        <label htmlFor={`test`}>
                            <span>
                                <span />
                            </span>
                            Test
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="parzializzabile" name="parzializzabile" defaultValue="0" defaultChecked={current.parzializzabile} disabled={auth !== 2} />
                        <label htmlFor={`parzializzabile`}>
                            <span>
                                <span />
                            </span>
                            Parzializzabile
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="ronda" name="ronda" defaultValue="0" defaultChecked={current.ronda} disabled={auth !== 2} />
                        <label htmlFor={`ronda`}>
                            <span>
                                <span />
                            </span>
                            Ronda
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="24h" name="sempre_attivo" defaultValue="0" defaultChecked={current.sempre_attivo} disabled={auth !== 2} />
                        <label htmlFor={`24h`}>
                            <span>
                                <span />
                            </span>
                            24h
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="supervisione" name="supervisione" defaultValue="0" defaultChecked={current.supervisione} disabled={auth !== 2} />
                        <label htmlFor={`supervisione`}>
                            <span>
                                <span />
                            </span>
                            Supervisione
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="led_on" name="led_on" defaultValue="0" defaultChecked={current.led_on} disabled={auth !== 2} />
                        <label htmlFor={`led_on`}>
                            <span>
                                <span />
                            </span>
                            LED ON
                        </label>
                    </div>
                </div>
            </div>
            <div className="rack bottomBorder">
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="ritardato" name="ritardato" defaultValue="0" checked={ritardatoChecked} disabled={auth !== 2} onChange={e => { let _checked = e.target.checked;  setRitardatoChecked(_checked); if(_checked) { setPercorsoChecked(false); }} }/>
                        <label htmlFor={`ritardato`}>
                            <span>
                                <span />
                            </span>
                            Ritardato
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="percorso" name="percorso" defaultValue="0" disabled={auth !== 2} checked={percorsoChecked} onChange={e => { let _checked = e.target.checked; setPercorsoChecked(_checked); if(_checked) { setRitardatoChecked(false); }} } />
                        <label htmlFor={`percorso`}>
                            <span>
                                <span />
                            </span>
                            Percorso
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <p>Tempo Ingresso</p>
                    <ReactSlider
                        defaultValue={current.tempo_ingresso}
                        onChange={value => { setTempoIngresso(value); }}
                        disabled={auth !== 2}
                        max={239}
                        min={0}
                        className="horizontal-slider"
                        thumbClassName="slider-thumb"
                        trackClassName="slider-track"
                        renderThumb={(props, state) => {
                            const [min, sec] = [
                                Math.floor(parseInt(state.valueNow) / 60),
                                parseInt(state.valueNow) % 60,
                            ];
                            return (<div {...props}>{`${min} min ${sec} sec`}</div>);
                        }}
                    />
                </div>
                <div className="w25">
                    <p>Tempo Uscita</p>
                    <ReactSlider
                        defaultValue={current.tempo_uscita}
                        onChange={value => { setTempoUscita(value); }}
                        disabled={auth !== 2}
                        max={239}
                        min={0}
                        className="horizontal-slider"
                        thumbClassName="slider-thumb"
                        trackClassName="slider-track"
                        renderThumb={(props, state) => {
                            const [min, sec] = [
                                Math.floor(parseInt(state.valueNow) / 60),
                                parseInt(state.valueNow) % 60,
                            ];
                            return (<div {...props}>{`${min} min ${sec} sec`}</div>);
                        }}
                    />
                </div>
            </div>
            <div className="rack bottomBorder">
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="a" name="a" defaultValue="0" defaultChecked={current.a} disabled={auth !== 2} />
                        <label htmlFor={`a`}>
                            <span>
                                <span />
                            </span>
                            A
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="k" name="k" defaultValue="0" defaultChecked={current.k} disabled={auth !== 2} />
                        <label htmlFor={`k`}>
                            <span>
                                <span />
                            </span>
                            K
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="fuoco" name="fuoco" defaultValue="0" defaultChecked={current.fuoco} disabled={auth !== 2} />
                        <label htmlFor={`fuoco`}>
                            <span>
                                <span />
                            </span>
                            Fuoco
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="campanello" name="campanello" defaultValue="0" defaultChecked={current.campanello} disabled={auth !== 2} />
                        <label htmlFor={`campanello`}>
                            <span>
                                <span />
                            </span>
                            Campanello
                        </label>
                    </div>
                </div>
                <div className="w33">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="elettroserratura" name="elettroserratura" defaultValue="0" defaultChecked={current.elettroserratura} disabled={auth !== 2} />
                        <label htmlFor={`elettroserratura`}>
                            <span>
                                <span />
                            </span>
                            Elettroserratura
                        </label>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RilevatorePerimetraleEsterno;