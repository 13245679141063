import React from 'react';
import { connect } from 'react-redux';

import './Loaders.scss';

const Loader = connect(
  state => ({
    loading: state.loading,
  })
)(
  (() => ({ loading }) => {
    if (loading) {
      return (
        <div className="overlay">
          <div className="spinner">
            <div className="lds-dual-ring"></div>
          </div>
        </div>
      );
    }
    return null;
  })()
);

export default Loader;
