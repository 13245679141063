import React from 'react';
import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";

import './Loaders.scss';

const LoaderTimed = ({ url, time }) => {
  const [redirect, setRedirect] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => setRedirect(redirect => true), time);
    return () => clearTimeout(timer);
  }, []);
  return redirect ? ( 
    <Redirect to={url} />
  ) : (
    <div className="overlay">
      <div className="spinner">
        <div className="lds-dual-ring"></div>
      </div>
    </div>
  );
};

export default LoaderTimed;
