import {
  DO_REQUEST,
  ERROR_READ,
  GET_PROFILE,
  GET_SYSTEM_KO,
  GET_SYSTEM_OK,
  GOLD_DO_REQUEST,
  GOLD_REQUEST_END,
  GOLD_POLLING_END,
  GOLD_POLLING_START,
  GOLD_UPDATE_DEV_STATS,
  LOGIN,
  LOGIN_FAILED,
  LOGGED,
  ME,
  REQUEST_FAILED,
  REQUEST_SUCCESS,
  RESET,
  RESET_SYSTEM_OK,
  SET_SOCKET,
  SET_SOCKET_OK,
  SET_STORE_OK,
  DO_SOCKET_REQUEST,
  SOCKET_REQUEST_RESET,
  SOCKET_REQUEST_FAILED,
  SOCKET_REQUEST_SUCCESS,
  SET_DATA_SOCKET_OK,
  GOLD_RESET_DEV_STATS,
  PATCH_SYSTEM,
} from '../constants';

import deepAssign from '../lib/deepAssign';

const initialState = {
  data: null,
  data_socket: null,
  error: null,
  queue_data: null,
  queue_errors: null,
  socket_error: null,
  socket_redirect: null,
  loading: false,
  polling: false,
  reset_dev_stats: false,
  xhr: false,
  user: {
    change_email: false,
    email: null,
    privacy_date: null,
    name: null,
    lastname: null,
    newsletter: null,
    premium_date: null,
    sim_premium_date: null,
    fiscal_code: null,
    vat: null,
    company: null,
    mobile: null,
    fax: null,
    phone: null,
    email2: null,
    geodata: null,
    expiration_date: null,
    create_time: null,
    can_create_system: null,
    pic: null
  },
  system: null,
  socket: null
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case DO_REQUEST:
    case DO_SOCKET_REQUEST:
    case GET_PROFILE:
    case LOGIN:
      return {
        ...state,
        data: null,
        loading: true,
        xhr: true
      };
    case GOLD_DO_REQUEST:
      return {
        ...state,
        loading: action.loading,
        queue_data: null,
        queue_errors: null,
        xhr: true
      };
    case GOLD_POLLING_START:
        return {
          ...state,
          polling: true
      };
    case GOLD_POLLING_END:
        return {
          ...state,
          polling: false
      };
    case GOLD_RESET_DEV_STATS:
        return {
          ...state,
          reset_dev_stats: false
      };
    case GOLD_UPDATE_DEV_STATS:
        let __system = { ...state.system };
        if(!!__system.store && !!__system.store.dev_stats) {
          const { group, type, stats } = action.data;
          __system.store.dev_stats[type][group] = stats;
          return {
            ...state,
            reset_dev_stats: true,
            system: __system
          };  
        } else {
          return state;
        }
    case GOLD_REQUEST_END:
      return {
        ...state,
        loading: false,
        queue_data: action.data || null,
        queue_errors: action.errors || null,
        xhr: false
      };
    case SET_SOCKET:
      return {
        ...state,
        loading: true
      };
    case ERROR_READ:
      return {
        ...state,
        error: null
      };
    case GET_SYSTEM_KO:
      return {
        ...state,
        error: action.error,
        loading: false,
        system: null,
        xhr: false
      };
    case GET_SYSTEM_OK:
      return {
        ...state,
        data: true,
        loading: false,
        system: action.data,
        xhr: false
      };
    case PATCH_SYSTEM:
      return {
        ...state,
        data: true,
        loading: false,
        system: {...state.system, ...action.data.payload },
        xhr: false
      };
      case LOGIN_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
        user: initialState.user,
        xhr: false
      };
    case LOGGED:
      return {
        ...state,
        error: null,
        loading: false,
        user: action.payload,
        xhr: false
      };
    case ME:
      return {
        ...state,
        loading: false,
        user: action.payload,
        xhr: false
      };
    case REQUEST_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
        xhr: false
      };
    case REQUEST_SUCCESS:
      return {
        ...state,
        data: action.data || true,
        error: null,
        loading: false,
        xhr: false
      };
    case RESET:
      return initialState;
    case RESET_SYSTEM_OK:
      if (!!state.socket) {
        state.socket.close();
      }
      sessionStorage.removeItem('systemCode');
      sessionStorage.removeItem('systemIDC');
      return {
        ...state,
        data: null,
        error: null,
        loading: false,
        system: null,
        socket: null
      };
    case SET_SOCKET_OK:
      return {
        ...state,
        data: null,
        error: null,
        loading: false,
        socket: action.socket
      };
    case SET_STORE_OK:
      let _system = { ...state.system };
      _system.store = deepAssign({ nonEnum: true, symbols: true, descriptors: true, proto: true })(_system.store || {}, action.data);
      return {
        ...state,
        error: null,
        system: _system
      };
    case SET_DATA_SOCKET_OK:
      return {
        ...state,
        loading: false,
        data_socket: action.data
      }
    case SOCKET_REQUEST_FAILED:
      return {
        ...state,
        loading: false,
        data_socket: null,
        socket_error: action.error,
        socket_redirect: null
      }
    case SOCKET_REQUEST_RESET:
      return {
        ...state,
        loading: false,
        data_socket: null,
        socket_error: null,
        socket_redirect: null
      }
    case SOCKET_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        data_socket: null,
        socket_error: action.data,
        socket_redirect: action.redirect
      }
    default:
      return state;
  }
};

export default rootReducer;
