import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ReactSlider from 'react-slider';

import Modal from '../../../elements/Modal';
import { clone } from '../../../../lib/clone';
import { j2bTel } from '../../../../lib/goldParser/converter';

import {
    goldDoRequest,
    setStore
} from '../../../../actions';

import { goldGetGsmStat, goldTestCall, sleep } from '../../../../api/Cloud';
import { goldGetSiaIp, goldSetSiaIp, goldInsertSiaIp } from '../../../../api/Cloud';
import { goldSetLogicMap } from '../../../../api/Cloud';

const mapStateToProps = state => ({
    data: state.queue_data,
    errors: state.queue_errors,
    system: state.system
});

const mapDispatchToProps = (dispatch) => ({
    goldSetPhoneNumbers: payload => dispatch(goldDoRequest('goldSetPhoneNumbers', payload)),
    setStore: data => dispatch(setStore(data))
});

const _SiaIp = ({ match, data, errors, system, goldSetPhoneNumbers, setStore }) => {
    const espansioni = [];
    const offset = system.model < 5 ? 2 : 5;
    const _system = clone(system);
    const codeForm = useRef(null);
    const [sub, setSub] = useState(null);
    const [firstLoad, setFirstLoad] = useState(false);
    const [redir, setRedir] = useState(null);
    const [ripetizioni, setRipetizioni] = useState(0);
    const [durataImpulso, setDurataImpulso] = useState(1);
    const [numeri, setNumeri] = useState([]);
    const [current, setCurrent] = useState(null);
    const [processed, setProcessed] = useState(null);
    const [success, setSuccess] = useState(null);
    const [calling, setCalling] = useState(false);
    const [callError, setCallError] = useState(null);
    const [statusSia, setStatusSia] = useState([]);

    useEffect(() => {
        return async () => {
            startPolling();
            return true;
        }
    }, []);

    useEffect(
        () => {
            setFirstLoad(true);
            getSiaIp(system.id_centrale);
            setSub((match.params.sub > 0 && match.params.sub <= 16) ? parseInt(match.params.sub) : 1);
            return () => null;
        },
        [match.params.sub]
    );

    useEffect(
        () => {
            if (firstLoad) {
                setRedir(null);
                setCurrent(null);
                setTimeout(() => {
                    let _numeri = [];
                    for (let i = 0; i < 16; i++) {
                        _numeri.push({
                            idx: i,
                            ..._system.store.pm.tel[i]
                        });
                    }
                    setNumeri(_numeri);
                    setFirstLoad(false);
                    setCurrent(_numeri[(sub - 1)]);
                    //setRipetizioni(_numeri[(sub - 1)].ripetizioni);
                    setDurataImpulso(_numeri[(sub - 1)].durata_impulso);    
                    //getSiaIp(system.id_centrale);
                }, 100);
            }
            return () => null;
        },
        [firstLoad]
    );

    if (!!!system.store || !!!system.store.auth || !(system.store.auth & 6)) {
        return (
            <Modal
                title={`LinceCloud -> Impianto`}
                text={`Permessi insufficenti`}
                redirect={`/gold/${system.id}`}
            />
        );
    }

    const makePayload = () => {
        const form = codeForm.current;
        let now = Math.floor(((Date.now() / 1000)) / 3600); //ore

        return {
            enabled: form.abilitazione.checked, 
            account_sia: form.numero.value,
            ip_address: form.ip_csr.value,
            port: form.port_csr.value,
            supervisione_rp: (form.sup_rp.checked) ? ripetizioni : 0,
            supervisione_blank: (form.sup_blank.checked) ? ripetizioni : 0,
            start_date: now //inserire date now
        };
    };

    const handleSelectChange = e => {
        const value = parseInt(e.target.value) + 1;
        setRedir(`/gold/${system.id}/phone/${value}`);
    };

    const handleSubmit = e => {
        e.preventDefault();
        const payload = makePayload();
        
        //console.log('date_start: ' + payload.start_date);
        if (statusSia.length == 0) 
            insertSiaIp(system.id_centrale, payload);
        else
            setSiaIp(system.id_centrale, payload);

        setProcessed(true);
    }

    const handleReset = e => { 
        e.preventDefault();
        const idx = current.idx;
        setCurrent(null);
        setTimeout(() => setCurrent(numeri[idx]), 100);
    };

    if (redir) {
        const _redir = redir;
        return <Redirect to={_redir} />;
    }

    const startPolling = () => {
        setStore({
            polling_pause: false
        });
    };

    const stopPolling = () => {
        setStore({
            polling_pause: true
        });
    };

    const pollCall = async () => {
        let prevGsmState = 0;
        let k = 0;
        while(k < 30) {
            await sleep(3000);
            let [__data, __code] = await goldGetGsmStat({ id_centrale: system.id_centrale });
            if(__code === 200 && __data.status === 'OK') {
                if(__data.stato_gsm != 2 && prevGsmState === 2) {
                    setCalling(false);
                    startPolling();
                    break;
                } else {
                    prevGsmState = parseInt(__data.stato_gsm);
                }
            } else {
                setCalling(false);
                startPolling();
                setCallError("Si è verificato un errore. Riprovare");
                break;
            }
            k++;
        }
    };

    const getSiaIp = async (id_centrale) => {
        const payload = 
            {
                id_centrale: id_centrale
            }
        ;
        const [__data, __code] = await goldGetSiaIp(payload);
        if(__code === 200) {
            if (__data[0])
                setStatusSia(__data[0]);
            //console.log(statusSia);
            //console.log(__data[0].account_sia);
        }
    };

    const setSiaIp = async (id_centrale, payload_sia) => {
        const payload = 
            {
                id_centrale: id_centrale,
                //altri parametri
                enabled: payload_sia.enabled, 
                account_sia: payload_sia.account_sia,
                ip_address: payload_sia.ip_address,
                port: payload_sia.port,
                supervisione_rp: payload_sia.supervisione_rp,
                supervisione_blank: payload_sia.supervisione_blank,
                start_date: payload_sia.start_date
            }
        ;
        const [__data, __code] = await goldSetSiaIp(payload);
        if(!!!system.store.lm) {
            var _lm = makeLm();
            const payload = { 
                id_centrale: system.id_centrale,
                logic_map: _lm
            };
            const [___data, ___code] = await goldSetLogicMap(payload);
            if(___code == 200) {
                setStore({ lm: _lm });                
            }
        }
    };

    const insertSiaIp = async (id_centrale, payload_sia) => {
        const payload = 
            {
                id_centrale: id_centrale,
                //altri parametri
                enabled: payload_sia.enabled, 
                account_sia: payload_sia.account_sia,
                ip_address: payload_sia.ip_address,
                port: payload_sia.port,
                supervisione_rp: payload_sia.supervisione_rp,
                supervisione_blank: payload_sia.supervisione_blank,
                start_date: payload_sia.start_date
            }
        ;
        const [__data, __code] = await goldInsertSiaIp(payload);
        if(!!!system.store.lm) {
            var _lm = makeLm();
            const payload = { 
                id_centrale: system.id_centrale,
                logic_map: _lm
            };
            const [___data, ___code] = await goldSetLogicMap(payload);
            if(___code == 200) {
                setStore({ lm: _lm });                
            }
        }
 
    };

    const makeLm = () => {
        const elems = [
            ...system.store.pm.filari.map((x, idx) => ({ nome: x.nome, tipo: "filare", sub: "", pos: idx, zona: "Default" })).filter((_, idx) => (idx < offset ? true : (espansioni[Math.floor(idx / 5)] || null))),
            ...system.store.pm.radio.filter(x => x.num_tipo_periferica > 0 && x.num_tipo_periferica <= 8).map((x, idx) => ({ nome: x.nome, tipo: "radio", sub: x.sub, pos: idx, zona: "Default" })),
            { nome: "Allarme", tipo: "filare", sub: "", pos: 100, zona: "Default" },
            { nome: system.out1 || "OUT1", tipo: "filare", sub: "", pos: 101, zona: "Default" },
            { nome: system.out2 || "OUT2", tipo: "filare", sub: "", pos: 102, zona: "Default" },
            { nome: system.out3 || "OUT3", tipo: "filare", sub: "", pos: 103, zona: "Default" },
            { nome: system.out4 || "OUT4", tipo: "filare", sub: "", pos: 104, zona: "Default" }
        ];
        const _lm = {};
        _lm.elementi = elems;
        _lm.zone = [
            { nome:'Interno', tipo:1 },
            { nome:'Garage', tipo:1 },
            { nome:'Giardino', tipo:1 },
            { nome:'Default', tipo:1 }
        ];
        _lm.model = system.model;
        _lm.map_type = "logical";
        return _lm;
    };

    const startCall = async e => {
        e.preventDefault();
        stopPolling();
        setCalling(true);
        await sleep(1500);
        const [__data, __code] = await goldTestCall({ id_centrale: system.id_centrale, pos: current.idx });
        if(__code === 200 && __data.status === 'OK') {
            await pollCall();
        } else {
            setCallError("Impossibile effettuare la chiamata di test. Riprovare");
            setCalling(false);
            startPolling();
        }
    };

    if (!!!current) {
        return <>caricamento in corso....</>;
    }

    if(data && processed) {
        setProcessed(null);
        const _success = !!data && !errors;
        if(_success) {
            const form = codeForm.current;
            const numero = form.numero.value;
            const _current = clone(current);
            _current.numero = numero;
            setCurrent(_current);
        }
        setSuccess(_success);
    }

    return (
        <section>
            {success === true && <Modal title={`Impianto -> LinceCloud`} text={`Comando inviato con successo`} onClose={() => { setSuccess(null); }}/> }
            {success === false && <Modal title={`Impianto -> LinceCloud`} text={`Si é verificato un errore`} onClose={() => { setSuccess(null); }}/> }
            <div className="innerSection">
                <div className="rack">
                    <div className="w100 formSection">
                        <form ref={codeForm} className="stackableW33">
                            <h1>Configurazione SIA-IP</h1>
                            <fieldset>
                                <div className="rack">
                                    <div className="w50">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="abilitazione" name="abilitazione" defaultValue="0" defaultChecked={(statusSia.enabled == 0) ? false : true} />
                                            <label htmlFor={`abilitazione`}>
                                                <span>
                                                    <span />
                                                </span>
                                                Utenza abilitata
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="rack">
                                    <div className="w50">
                                        <label>Account</label>
                                        <input type="text" name="numero" defaultValue={statusSia.account_sia} maxLength={16} onKeyPress={e => { if (e.which < 48 || e.which > 57) { e.preventDefault(); } }} />
                                    </div>
                                </div>
                                <div className="rack">
                                    <div className="w50">
                                        <label>Indirizzo IP CSR</label>
                                        <input type="text" name="ip_csr" defaultValue={statusSia.ip_address} maxLength={16} />
                                    </div>
                                    <div className="w50">
                                        <label>Porta CSR</label>
                                        <input type="text" name="port_csr" defaultValue={statusSia.port} maxLength={16} />
                                    </div>
                                </div>
                                <div className="rack bottomBorder">
                                        
                                 </div>
                                <h4>Supervisione</h4>
                                <div className="rack switchRack bottomBorder">
                                    <div className="rack">
                                        <div className="w20">
                                            <div className="switchButton">
                                                <input type="checkbox" className="switch" id="sup_rp" name="sup_rp" defaultValue="0" defaultChecked={(statusSia.supervisione_rp == 0) ? false : true} />
                                                <label htmlFor={`sup_rp`}>
                                                    <span>
                                                        <span />
                                                    </span>
                                                    Messaggio RP
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rack">
                                        <div className="w20">
                                            <div className="switchButton">
                                                <input type="checkbox" className="switch" id="sup_blank" name="sup_blank" defaultValue="0" defaultChecked={(statusSia.supervisione_blank == 0) ? false : true} />
                                                <label htmlFor={`sup_blank`}>
                                                    <span>
                                                        <span />
                                                    </span>
                                                    Messaggio Blank
                                                </label>
                                            </div>
                                        </div>   
                                    </div>
                                    <div className="rack">
                                        <br></br>
                                    </div>
                                    <div className="rack">
                                        <div className="w50">
                                            <p>Tempo (ore)</p>
                                            <ReactSlider
                                                defaultValue={(statusSia.supervisione_rp == 0) ? ((statusSia.supervisione_blank == 0) ? 0 : statusSia.supervisione_blank) : statusSia.supervisione_rp}
                                                onChange={value => { setRipetizioni(parseInt(value)); }}
                                                max={1080}
                                                min={0}
                                                step={1}
                                                className="horizontal-slider"
                                                thumbClassName="slider-thumb"
                                                trackClassName="slider-track"
                                                renderThumb={(props, state) => {
                                                    return (<div {...props}>{`${state.valueNow}`}</div>);
                                                }}
                                            />
                                        </div>   
                                    </div>
                                </div>
                            </fieldset>
                            
                            <fieldset>
                                <div className="rack">
                                    <div className="w100 right">
                                        <button className="ok auto spaced" onClick={handleReset} disabled={calling}>Annulla</button>
                                        <button className="ok auto spaced" onClick={handleSubmit} disabled={system.store.state.prog_active || calling}>Applica</button>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const SiaIp = connect(mapStateToProps, mapDispatchToProps)(_SiaIp);

export default SiaIp;