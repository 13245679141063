export const DO_REQUEST = "DO_REQUEST";
export const DO_SOCKET_REQUEST = "DO_SOCKET_REQUEST";
export const ERROR_READ = "ERROR_READ";
export const GET_PROFILE = "GET_PROFILE";
export const GET_SYSTEM = "GET_SYSTEM";
export const GET_SYSTEM_OK = "GET_SYSTEM_OK";
export const GET_SYSTEM_KO = "GET_SYSTEM_KO";
export const GOLD_DO_REQUEST = "GOLD_DO_REQUEST";
export const GOLD_POLLING_END = "GOLD_POLLING_END";
export const GOLD_POLLING_START = "GOLD_POLLING_START";
export const GOLD_REQUEST_END = "GOLD_REQUEST_END";
export const GOLD_RESET_DEV_STATS = "GOLD_RESET_DEV_STATS";
export const GOLD_UPDATE_DEV_STATS = "GOLD_UPDATE_DEV_STATS";
export const LOGGED = "LOGGED";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const ME = "ME";
export const PATCH_SYSTEM = "PATCH_SYSTEM";
export const REQUEST_FAILED = "REQUEST_FAILED";
export const REQUEST_SUCCESS = "REQUEST_SUCCESS";
export const RESET = "RESET";
export const RESET_SYSTEM = "RESET_SYSTEM";
export const RESET_SYSTEM_OK = "RESET_SYSTEM_OK";
export const SET_SOCKET = "SET_SOCKET";
export const SET_SOCKET_OK = "SET_SOCKET_OK";
export const SET_STORE = "SET_STORE";
export const SET_STORE_OK = "SET_STORE_OK";
export const SET_DATA_SOCKET = "SET_DATA_SOCKET";
export const SET_DATA_SOCKET_OK = "SET_DATA_SOCKET_OK";
export const SET_TOKEN = "SET_TOKEN";
export const SOCKET_REQUEST_FAILED = "SOCKET_REQUEST_FAILED";
export const SOCKET_REQUEST_SUCCESS = "SOCKET_REQUEST_SUCCESS";
export const SOCKET_REQUEST_RESET = "SOCKET_REQUEST_RESET";

export const roles = [
    'manutentore', 'proprietario', 'utilizzatore'
];

export const models = [
    "4003EUROPLUS10", "4103EUROPLUS10/E", "4203EUROPLUS10/LAN", "4051GR868E-PLUS94", "4074EUROPLUS10/M", "4078E-PLUS10MST", "4278E-PLUS10MST/LAN", "4900E-PLUS10MCT/LAN", "4279E-PLUS10MST/E", "4280E-PLUS10MST/E-LAN", "4002EUROPLUS5", "4102EUROPLUS5/E", "4202EUROPLUS5/LAN", "4899EUROPLUS5/E-T", "4092E-PLUSTOSCA"
];

export const states = [
    'operativo', 'in costruzione', 'in assistenza', 'dismesso'
];