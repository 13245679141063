import axios from 'axios';

const makeRequest = ({ endpoint, method = 'GET', data = null, token = false }) => {
    const headers = {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
    };
    if (token) {
        headers['Authorization'] = `Bearer ${getToken()}`;
    }
    const request = {
        headers,
        method,
        url: process.env.REACT_APP_API_URL + `/${endpoint}`
    };
    if (data) {
        request.data = JSON.stringify(data);
    }
    return request;
};

const upload = async ({ formData }) => {
    const run = async () => {
        const headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${getToken()}`
        };
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_URL + `/upload`,
                formData,
                { headers }
            );
            const { data, status } = response;
            return [data, status];
        } catch (error) {
            const { response } = error;
            if (response) {
                const { data, status } = response;
                return (status === 502 || status === 504) ? [process.env.REACT_APP_NOSERVER_TEXT, status] : [data, status];
            } else {
                return [process.env.REACT_APP_NOSERVER_TEXT, 502];
            }
        }
    }
    return await run();
};

const call = async (request, managed = false) => {
    if(!!managed) {
        return await managedCall(request);
    }
    const { url } = request;
    try {
        const response = await axios.request(url, request);
        const { data, status } = response;
        return [data, status];
    } catch (error) {
        const { response } = error;
        if (response) {
            const { data, status } = response;
            return (status === 502 || status === 504) ? [process.env.REACT_APP_NOSERVER_TEXT, status] : [data, status];
        } else {
            return [process.env.REACT_APP_NOSERVER_TEXT, 502];
        }
    }
};

const managedCall = async request => {
    var counter = 0;
    var data = process.env.REACT_APP_NOSERVER_TEXT, status = 502;
    while(counter < 5) {
        let response = await call(request);
        if(response[1] === 200) {
            let _status = response[0].status || null;
            if(_status === 'OK') {
                data = response[0];
                status = response[1];
                break;
            } else if(_status === 'NACK') {
                await sleep(1500);
                let loginResponse = await goldLogin({ 
                    id_centrale: sessionStorage.getItem('systemIDC'), 
                    code: sessionStorage.getItem('systemCode') 
                });
                if(loginResponse[1] === 200) {
                    let __status = loginResponse[0].status || null;
                    if(__status != 'OK') {
                        break;
                    }
                }
            }
        } else {
            break;
        }
        counter++;
        await sleep(1500);
    }
    return [data, status];
}

export const sleep = async (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const activate = token => call(makeRequest({ endpoint: `activations/${token}` }));
export const checkOTP = payload => call(makeRequest({ endpoint: 'users/otp', method: 'PUT', data: payload, token: true }));
export const createAssociation = payload => call(makeRequest({ endpoint: 'associations', method: 'POST', data: payload, token: true }));
export const createCAT = payload => call(makeRequest({ endpoint: 'cat', method: 'POST', data: payload, token: true }));
export const createOTP = payload => call(makeRequest({ endpoint: 'users/otp', method: 'POST', data: payload, token: true }));
export const createSystem = payload => call(makeRequest({ endpoint: 'system', method: 'POST', data: payload, token: true }));
export const deleteAssociation = id => call(makeRequest({ endpoint: `associations/${id}`, method: 'DELETE', token: true }));
export const deleteRole = systemId => call(makeRequest({ endpoint: `role/${systemId}`, method: 'DELETE', token: true }));
export const deleteSocket = id => call(makeRequest({ endpoint: `socket/${id}`, method: 'DELETE', token: true }));
export const deleteSystem = id => call(makeRequest({ endpoint: `system/${id}`, method: 'DELETE', token: true }));
export const editSystem = ({ payload, id }) => call(makeRequest({ endpoint: `system/${id}`, method: 'PUT', data: payload, token: true }));
export const emailChange = payload => call(makeRequest({ endpoint: 'emails', method: 'PUT', data: payload, token: true }));
export const emailChangeConfirm = payload => call(makeRequest({ endpoint: 'emails', method: 'PUT', data: payload, token: true }));
export const emailChangeDismiss = () => call(makeRequest({ endpoint: 'emails', method: 'DELETE', token: true }));
export const enroll = payload => call(makeRequest({ endpoint: 'users', method: 'PUT', data: payload, token: true }));
export const getAllSystem = () => call(makeRequest({ endpoint: 'system', token: true }));
export const getAssociations = () => call(makeRequest({ endpoint: 'associations', token: true }));
export const getAssociationsCAT = () => call(makeRequest({ endpoint: 'cat', token: true }));
export const getEvents = () => call(makeRequest({ endpoint: `events`, token: true }));
export const deleteSubscription = () => call(makeRequest({ endpoint: `sessions/subscription`, method: 'DELETE', token: true }));
export const getCheckoutSession = type => call(makeRequest({ endpoint: `sessions/${type}`, token: true }));
export const verifyCheckoutSession = payload => call(makeRequest({ endpoint: `sessions/checkout`, method: 'POST', data: payload, token: true }));
export const approveSessionAuth = token => call(makeRequest({ endpoint: `session-auth/${token}`, method: 'POST' }));
export const getSessionAuth = id => call(makeRequest({ endpoint: `session-auth/${id}`, token: true }));
export const getSystemAccess = id => call(makeRequest({ endpoint: `system-access/${id}`, token: true }));
export const getSystem = id => call(makeRequest({ endpoint: `system/${id}`, token: true }));
export const getPasswordToken = token => call(makeRequest({ endpoint: `passwords/${token}` }));
export const getToken = () => sessionStorage.getItem('token');
export const getUser = () => call(makeRequest({ endpoint: 'sessions', token: true }));
export const getModels = (payload = null) => call(makeRequest({ endpoint: `models${(payload ? `/${payload}` : '')}`, token: true }));
export const login = (email, password) => call(makeRequest({ endpoint: 'sessions', method: 'POST', data: { email, password } }));
export const passwordChange = payload => call(makeRequest({ endpoint: 'passwords', method: 'PUT', data: payload, token: true }));
export const passwordRecovery = payload => call(makeRequest({ endpoint: 'users/password', method: 'PUT', data: payload }));
export const passwordReset = payload => call(makeRequest({ endpoint: 'users/password', method: 'PUT', data: payload }));
export const patchSystem = ({ payload, id }) => call(makeRequest({ endpoint: `system/${id}`, method: 'PUT', data: payload, token: true }));
export const setToken = token => token === null ? false : sessionStorage.setItem('token', token);
export const signup = payload => call(makeRequest({ endpoint: 'registrations', method: 'POST', data: payload }));
export const searchUser = payload => call(makeRequest({ endpoint: 'users/search', method: 'POST', data: payload, token: true }));
export const uploadPhoto = payload => upload(payload);
export const unsetToken = () => sessionStorage.removeItem('token');

export const goldAcquirePeripheral = payload => call(makeRequest({ endpoint: 'centrale/acquire_peripheral', method: 'POST', data: payload, token: true }));
export const goldCheckSync = payload => call(makeRequest({ endpoint: 'centrale/check_sync', method: 'POST', data: payload, token: true }));
export const goldClearMemory = payload => call(makeRequest({ endpoint: 'centrale/clear_memory', method: 'POST', data: payload, token: true }));
export const goldConfirmPeripheral = payload => call(makeRequest({ endpoint: 'centrale/confirm_peripheral', method: 'POST', data: payload, token: true }));
export const goldDelCode = payload  => call(makeRequest({ endpoint: 'centrale/del_code', method: 'POST', data: payload, token: true }));
export const goldDelPeripheral = payload  => call(makeRequest({ endpoint: 'centrale/delete_peripheral', method: 'POST', data: payload, token: true }));
export const goldDelPin = payload  => call(makeRequest({ endpoint: 'centrale/delete_pin', method: 'POST', data: payload, token: true }));
export const goldDevStat = payload  => call(makeRequest({ endpoint: 'centrale/get_group_dev_stat', method: 'POST', data: payload, token: true }));
export const goldExitSystem = (payload, managed)  => call(makeRequest({ endpoint: 'centrale/exit_system', method: 'POST', data: payload, token: true }), managed);
export const goldGetOutBus = payload => call(makeRequest({ endpoint: 'centrale/get_out_bus', method: 'POST', data: payload, token: true }));
export const goldGetGsmStat = payload => call(makeRequest({ endpoint: 'centrale/get_gsm_stat', method: 'POST', data: payload, token: true }));
export const goldGetGprsAPN = payload => call(makeRequest({ endpoint: 'centrale/get_gprs_apn', method: 'POST', data: payload, token: true }));
export const goldSetGprsAPN = payload => call(makeRequest({ endpoint: 'centrale/set_gprs_apn', method: 'POST', data: payload, token: true }));
export const goldGetRec = payload  => call(makeRequest({ endpoint: 'centrale/get_rec', method: 'POST', data: payload, token: true }));
export const goldGetCode = payload => call(makeRequest({ endpoint: 'centrale/get_code', method: 'POST', data: payload, token: true }));
export const goldGetEvents = payload => call(makeRequest({ endpoint: 'centrale/get_daily_events', method: 'POST', data: payload, token: true }));
export const goldGetMemoryEvents = payload => call(makeRequest({ endpoint: 'centrale/get_mem_events', method: 'POST', data: payload, token: true }));
export const goldGetMemoryEventsLast = payload => call(makeRequest({ endpoint: 'centrale/get_last_mem_ev', method: 'POST', data: payload, token: true }));
export const goldGetModel = payload  => call(makeRequest({ endpoint: 'centrale/get_model', method: 'POST', data: payload, token: true }));
export const goldGetOutRadio = payload => call(makeRequest({ endpoint: 'centrale/get_out_radio', method: 'POST', data: payload, token: true }));
export const goldGetRadioAnalysis = payload => call(makeRequest({ endpoint: 'centrale/get_radio_analysis', method: 'POST', data: payload, token: true }));
export const goldGetState = payload => call(makeRequest({ endpoint: 'centrale/get_state', method: 'POST', data: payload, token: true }));
export const goldGetTime = payload => call(makeRequest({ endpoint: 'centrale/get_time', method: 'POST', data: payload, token: true }));
export const goldGetTimer = payload => call(makeRequest({ endpoint: 'centrale/get_timer', method: 'POST', data: payload, token: true }));
export const goldLogin = payload => call(makeRequest({ endpoint: 'centrale/login', method: 'POST', data: payload, token: true }));
export const goldModOut = payload  => call(makeRequest({ endpoint: 'centrale/mod_out', method: 'POST', data: payload, token: true }));
export const goldModOutBus = payload => call(makeRequest({ endpoint: 'centrale/mod_out_bus', method: 'POST', data: payload, token: true }));
export const goldModOutFilare = payload  => call(makeRequest({ endpoint: 'centrale/mod_out_filare', method: 'POST', data: payload, token: true }));
export const goldModOutRadio = payload  => call(makeRequest({ endpoint: 'centrale/mod_out_radio', method: 'POST', data: payload, token: true }));
export const goldSetRec = payload  => call(makeRequest({ endpoint: 'centrale/set_rec', method: 'POST', data: payload, token: true }));
export const goldSendComm = payload => call(makeRequest({ endpoint: 'centrale/send_comm', method: 'POST', data: payload, token: true }));
export const goldSetGsmMessages = payload => call(makeRequest({ endpoint: 'centrale/set_gsm_messages', method: 'POST', data: payload, token: true }));
export const goldSetDisplay = payload => call(makeRequest({ endpoint: 'centrale/set_display', method: 'POST', data: payload, token: true }));
export const goldSetGsmConfig = payload => call(makeRequest({ endpoint: 'centrale/set_gsm_config', method: 'POST', data: payload, token: true }));
export const goldSetGsmOptions = payload => call(makeRequest({ endpoint: 'centrale/set_gsm_options', method: 'POST', data: payload, token: true }));
export const goldSetLogicMap = payload => call(makeRequest({ endpoint: 'centrale/set_logic_map', method: 'POST', data: payload, token: true }));
export const goldSetOtpSystem = payload => call(makeRequest({ endpoint: 'centrale/set_otp_system', method: 'POST', data: payload, token: true }));
export const goldSetOut = payload => call(makeRequest({ endpoint: 'centrale/set_out', method: 'POST', data: payload, token: true }));
export const goldSetPeripheral = payload => call(makeRequest({ endpoint: 'centrale/set_peripheral', method: 'POST', data: payload, token: true }));
export const goldSetPhoneNumbers = payload => call(makeRequest({ endpoint: 'centrale/set_phone_numbers', method: 'POST', data: payload, token: true }));
export const goldSetService = payload => call(makeRequest({ endpoint: 'centrale/set_service', method: 'POST', data: payload, token: true }));
export const goldSetTime = payload => call(makeRequest({ endpoint: 'centrale/set_time', method: 'POST', data: payload, token: true }));
export const goldSetTimer = payload => call(makeRequest({ endpoint: 'centrale/set_timer', method: 'POST', data: payload, token: true }));
export const goldSetTimers = payload => call(makeRequest({ endpoint: 'centrale/set_alarm_time', method: 'POST', data: payload, token: true }));
export const goldSetWifi = payload => call(makeRequest({ endpoint: 'centrale/set_wifi', method: 'POST', data: payload, token: true }));
export const goldSyncMemMap = payload => call(makeRequest({ endpoint: 'centrale/sync_mem_map', method: 'POST', data: payload, token: true }));
export const goldSyncTime = payload => call(makeRequest({ endpoint: 'centrale/sync_time', method: 'POST', data: payload, token: true }));
export const goldTestCall = payload => call(makeRequest({ endpoint: 'centrale/test_call', method: 'POST', data: payload, token: true }));
export const goldTestRadio = (payload, managed) => call(makeRequest({ endpoint: 'centrale/test_radio', method: 'POST', data: payload, token: true }), managed);
export const goldWriteAutoIns = payload  => call(makeRequest({ endpoint: 'centrale/write_autoins', method: 'POST', data: payload, token: true }));
export const goldWriteCycles = payload  => call(makeRequest({ endpoint: 'centrale/set_cycles', method: 'POST', data: payload, token: true }));
export const goldWriteCode = payload  => call(makeRequest({ endpoint: 'centrale/write_code', method: 'POST', data: payload, token: true }));
export const goldWriteOptions = payload  => call(makeRequest({ endpoint: 'centrale/set_options', method: 'POST', data: payload, token: true }));
export const goldWriteSuperButtons = payload  => call(makeRequest({ endpoint: 'centrale/write_superbutton', method: 'POST', data: payload, token: true }));

export const goldGetSiaIp = payload  => call(makeRequest({ endpoint: 'siaip/get-sia', method: 'POST', data: payload, token: true }));
export const goldInsertSiaIp = payload  => call(makeRequest({ endpoint: 'siaip/insert-sia', method: 'POST', data: payload, token: true }));
export const goldSetSiaIp = payload  => call(makeRequest({ endpoint: 'siaip/update-sia', method: 'POST', data: payload, token: true }));

export const simGetAuth = payload  => call(makeRequest({ endpoint: 'sim/auth', data: payload, token: true }));
export const simGetStatus = payload  => call(makeRequest({ endpoint: 'sim/status', method: 'POST', data: payload, token: true }));
export const simGetUserSim = payload  => call(makeRequest({ endpoint: 'sim/get-user-sim', method: 'POST', data: payload, token: true }));
export const simGetSimConsumption = payload  => call(makeRequest({ endpoint: 'sim/consumption', method: 'POST', data: payload, token: true }));
export const simInsertNewSim = payload => call(makeRequest({ endpoint: 'sim/insert-new-sim', method: 'POST', data: payload, token: true }));
export const simGetHistory = payload => call(makeRequest({ endpoint: 'sim/get-history', method: 'POST', data: payload, token: true }));
export const simGetAvailableRates = payload => call(makeRequest({ endpoint: 'sim/available-rates', method: 'POST', data: payload, token: true }));
export const simGetChangeRate = payload => call(makeRequest({ endpoint: 'sim/change-rate', method: 'POST', data: payload, token: true }));
export const simGetPrectivate = payload => call(makeRequest({ endpoint: 'sim/preactivate', method: 'POST', data: payload, token: true }));
export const simGetDeactivate = payload => call(makeRequest({ endpoint: 'sim/deactivate', method: 'POST', data: payload, token: true }));
export const simCheckSimSystem = payload => call(makeRequest({ endpoint: 'sim/check-sim-system', method: 'POST', data: payload, token: true }));
export const simChangeSimSystem = payload => call(makeRequest({ endpoint: 'sim/change-sim-system', method: 'POST', data: payload, token: true }));

export const simCreateCheckoutSession = payload  => call(makeRequest({ endpoint: 'sim/create-checkout-session', method: 'POST', data: payload, token: true }));
export const simChangePaymentMethod = payload  => call(makeRequest({ endpoint: 'sim/change-payment-method', method: 'POST', data: payload, token: true }));
export const simCheckStripeSession = payload => call(makeRequest({ endpoint: 'sim/check-stripe-session', method: 'POST', data: payload, token: true }));
export const simRetrieveSession = payload => call(makeRequest({ endpoint: 'sim/retrieve-session', method: 'POST', data: payload, token: true }));
export const simRetrieveSessionUrl = payload => call(makeRequest({ endpoint: 'sim/retrieve-session-url', method: 'POST', data: payload, token: true }));
export const simInsertStripeIdSession = payload => call(makeRequest({ endpoint: 'sim/insert-stripe-idsession', method: 'POST', data: payload, token: true }));
export const simDeleteStripeSession = payload => call(makeRequest({ endpoint: 'sim/delete-stripe-sim-session', method: 'POST', data: payload, token: true }));
export const simDeleteStripeSessionSubscription = payload => call(makeRequest({ endpoint: 'sim/delete-stripe-sim-subscription', method: 'POST', data: payload, token: true }));
export const simDeleteUtentiSim = payload => call(makeRequest({ endpoint: 'sim/delete-utenti-sim', method: 'POST', data: payload, token: true }));
export const simRetrieveStripeSubscription = payload => call(makeRequest({ endpoint: 'sim/retrieve-subscription', method: 'POST', data: payload, token: true }));
export const simInsertStripeIdSubscription = payload => call(makeRequest({ endpoint: 'sim/insert-stripe-idsubscription', method: 'POST', data: payload, token: true }));
export const simDeleteStripeIdSession = payload => call(makeRequest({ endpoint: 'sim/delete-stripe-idsession', method: 'POST', data: payload, token: true }));
export const simDeleteStripeSubscription = payload => call(makeRequest({ endpoint: 'sim/delete-subscription', method: 'POST', data: payload, token: true }));
export const simChangeStripeRate = payload => call(makeRequest({ endpoint: 'sim/change-stripe-rate', method: 'POST', data: payload, token: true }));

export const simMailActivate = payload => call(makeRequest({ endpoint: 'sim/mail-activate', method: 'POST', data: payload, token: true }));
export const simMailChangeRate = payload => call(makeRequest({ endpoint: 'sim/mail-new-rate', method: 'POST', data: payload, token: true }));
export const simMailDeactivate = payload => call(makeRequest({ endpoint: 'sim/mail-delete', method: 'POST', data: payload, token: true }));

export const simGetAllSim = payload  => call(makeRequest({ endpoint: 'sim/get-active-sim', data: payload, token: true }));
export const simInsertAllHistory = payload => call(makeRequest({ endpoint: 'sim/insert-history', method: 'POST', data: payload, token: true }));
