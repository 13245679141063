import {
    DO_REQUEST,
    DO_SOCKET_REQUEST,
    ERROR_READ,
    GET_PROFILE,
    GET_SYSTEM,
    GET_SYSTEM_KO,
    GET_SYSTEM_OK,
    GOLD_DO_REQUEST,
    GOLD_POLLING_END,
    GOLD_POLLING_START,
    GOLD_REQUEST_END,
    GOLD_UPDATE_DEV_STATS,
    LOGGED,
    LOGIN,
    LOGIN_FAILED,
    LOGOUT,
    ME,
    PATCH_SYSTEM,
    REQUEST_FAILED,
    REQUEST_SUCCESS,
    RESET,
    RESET_SYSTEM,
    RESET_SYSTEM_OK,
    SET_SOCKET,
    SET_SOCKET_OK,
    SET_STORE,
    SET_STORE_OK,
    SET_TOKEN,
    SOCKET_REQUEST_FAILED,
    SOCKET_REQUEST_SUCCESS,
    SOCKET_REQUEST_RESET,
    SET_DATA_SOCKET_OK,
    SET_DATA_SOCKET,
    GOLD_RESET_DEV_STATS
} from '../constants';

export const doRequest = (request, payload = null) => ({ type: DO_REQUEST, request, payload });
export const doSocketRequest = (socket, trama, payload, auth = true, noSuccess = false, noError = false, redirect = null) => ({ type: DO_SOCKET_REQUEST, socket, trama, payload, auth, noSuccess, noError, redirect });
export const errorRead = () => ({ type: ERROR_READ });
export const getProfile = () => ({ type: GET_PROFILE });
export const getSystem = (id, access = false) => ({ type: GET_SYSTEM, id, access });
export const getSystemKo = error => ({ type: GET_SYSTEM_KO, error });
export const getSystemOk = data => ({ type: GET_SYSTEM_OK, data });
export const goldDoRequest = (requests, payloads, login = true, loading = true) => ({ type: GOLD_DO_REQUEST, requests: Array.isArray(requests) ? requests : [requests], payloads: Array.isArray(payloads) ? payloads : [payloads], login, loading });
export const goldPollingStart = (payloads, requests) => ({ type: GOLD_POLLING_START, payloads, requests });
export const goldPollingEnd = () => ({ type: GOLD_POLLING_END });
export const goldRequestEnd = (data, errors = null) => ({ type: GOLD_REQUEST_END, data, errors });
export const goldResetDevStats = () => ({ type: GOLD_RESET_DEV_STATS });
export const goldUpdateDevStats = data => ({ type: GOLD_UPDATE_DEV_STATS, data });
export const logged = payload => ({ type: LOGGED, payload });
export const login = payload => ({ type: LOGIN, payload });
export const loginFailed = (payload) => ({ type: LOGIN_FAILED, payload });
export const logout = () => ({ type: LOGOUT });
export const me = payload => ({ type: ME, payload });
export const patchSystem = data => ({ type: PATCH_SYSTEM, data });
export const requestFailed = error => ({ type: REQUEST_FAILED, error });
export const requestSuccess = data => ({ type: REQUEST_SUCCESS, data });
export const reset = () => ({ type: RESET });
export const resetSystem = () => ({ type: RESET_SYSTEM });
export const resetSystemOk = () => ({ type: RESET_SYSTEM_OK });
export const setSocket = socket => ({ type: SET_SOCKET, socket });
export const setSocketOk = socket => ({ type: SET_SOCKET_OK, socket });
export const setStore = data => ({ type: SET_STORE, data });
export const setStoreOk = data => ({ type: SET_STORE_OK, data });
export const setToken = token => ({ type: SET_TOKEN, token });
export const socketRequestFailed = error => ({ type: SOCKET_REQUEST_FAILED, error });
export const socketRequestSuccess = (data, redirect = null) => ({ type: SOCKET_REQUEST_SUCCESS, data, redirect });
export const setDataSocket = data => {
    return ({ type: SET_DATA_SOCKET, data })
};
export const setDataSocketOk = data => ({ type: SET_DATA_SOCKET_OK, data });
export const socketRequestReset = () => ({ type: SOCKET_REQUEST_RESET });
