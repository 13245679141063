import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Modal from '../../../elements/Modal';
import { b2jFilareStat } from '../../../../lib/goldParser/physicalMap';

import {
    setStore
} from '../../../../actions';

const mapStateToProps = state => ({
    reset_dev_stats: state.reset_dev_stats,
    system: state.system
});

const mapDispatchToProps = (dispatch) => ({
    setStore: data => dispatch(setStore(data)),
});

const _StatoIngressiFilari = ({ system, setStore, reset_dev_stats }) => {
    const [group, setGroup] = useState(0);
    const [ingressiFilari, setIngressiFilari] = useState([]);
    const init = useCallback(
        () => {
            const _espansioni = [];
            for (let i = 0; i < system.store.pm.bus.length; i++) {
                if(system.store.pm.bus[i].num_tipo_periferica === 8) {
                    _espansioni.push(system.store.pm.bus[i]);
                }
            }
            const _ingressi = [];
            for (let i = 0; i < system.store.pm.filari.length; i++) {
                const _group = Math.floor(i / 16);
                const offset = system.model < 5 ? 2 : 5;
                const _i = i-offset;
                const expansion = i < offset ? false : (_espansioni[Math.floor(_i / 5)] || null);
                const line = expansion ? (_i % 5) + 1 : null;
                const __i = i - (_group * 16);
                if(expansion !== null) {
                    _ingressi.push({
                        idx: i,
                        group: _group,
                        expansion,
                        line,
                        stat: b2jFilareStat(system.store.dev_stats.filare[_group][__i]),
                        ...system.store.pm.filari[i]
                    });    
                }
            }
            setIngressiFilari(_ingressi);
        },
        []
    );

    useEffect(
        () => {
            init();
            return () => null;
        },
        []
    );

    useEffect(
        () => {
            setStore({
                polling_requests: ['goldDevStat'],
                polling_payloads: [{ id_centrale: system.id_centrale, type: 'filare', group: group }]
            });
            return () => {
                setStore({
                    polling_requests: [],
                    polling_payloads: []
                });
            };
        },
        [group]
    );

    useEffect(
        () => {
            init();
        },
        [system.store.dev_stats.filare, reset_dev_stats]
    );

    if (!!!system.store || !!!system.store.auth || !(system.store.auth & 6)) {
        return (
            <Modal
                title={`LinceCloud -> Impianto`}
                text={`Permessi insufficenti`}
                redirect={`/gold/${system.id}`}
            />
        );
    }

    return (
        <section>
            <div className="innerSection">
                <h1>Stato Ingressi Filari</h1>
                <div className="rack">
                    <div className="w100 formSection">
                        <form>
                            <fieldset>
                                <div className="rack">
                                    <div className="w20">
                                        <label>Scelta gruppo</label>
                                        <select defaultValue={group} name="group" onChange={e => setGroup(parseInt(e.target.value))}>
                                            <option value={0}>Da 1 a 16</option>
                                            {ingressiFilari.length >= 16 && <option value={1}>Da 17 a 30</option>}
                                        </select>
                                    </div>
                                </div>    
                            </fieldset>
                            <fieldset>
                                <table className="controlPanelTable responsiveTable">
                                    <thead>
                                        <tr>
                                            <th>Nome Ingresso</th>
                                            <th>Allarmi</th>
                                            <th>Memorie</th>
                                            <th>Stato</th>
                                            <th>Programmi</th>
                                            <th>Attributi</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ textAlign: "center" }}>
                                        {ingressiFilari.length > 0
                                        ? (
                                            <> 
                                                {ingressiFilari.map((item, index) => {
                                                    const allarmi = [];
                                                    !!item.stat.allarme_24 && allarmi.push('24H');
                                                    !!item.stat.ingresso_aperto && allarmi.push('A');
                                                    const memorie = [];
                                                    !!item.stat.memoria_allarme_24 && memorie.push('24H');
                                                    !!item.stat.memoria_allarme && memorie.push('A');
                                                    const stato = [];
                                                    !!item.stat.ingresso_escluso && stato.push('E');
                                                    const attributi = [];
                                                    !!item.ronda && attributi.push('r');
                                                    !!item.percorso && attributi.push('P');
                                                    !!item.silenzioso && attributi.push('S');
                                                    !!item.parzializzabile && attributi.push('p');
                                                    !!item.a && attributi.push('A');
                                                    !!item.fuoco && attributi.push('F');
                                                    !!item.elettroserratura && attributi.push('E');
                                                    !!item.ritardato && attributi.push('R');
                                                    !!item.escluso && attributi.push('e');
                                                    !!item.test && attributi.push('T');
                                                    !!item.sempre_attivo && attributi.push('24H');
                                                    !!item.k && attributi.push('K');
                                                    !!item.campanello && attributi.push('C');
                                                    !!item.supervisione && attributi.push('s');
                                                    !!item.doppio_impulso && attributi.push('D');
                                                    return (
                                                        item.group === group
                                                        ? (
                                                            <tr key={`row_${index}`}>
                                                                <td><span className="head">Nome Ingresso</span>{item.nome}</td>
                                                                <td><span className="head">Allarmi</span>{(allarmi.join(", "))}</td>
                                                                <td><span className="head">Memorie</span>{(memorie.join(", "))}</td>
                                                                <td><span className="head">Stato</span>{(stato.join(", "))}</td>
                                                                <td>
                                                                    <span className="head">Programmi</span>
                                                                    <i className="fa fa-circle" style={{ color: item.g1 ? "red" : "gray" }} />
                                                                    <i className="fa fa-circle" style={{ color: item.g2 ? "yellow" : "gray" }} />
                                                                    <i className="fa fa-circle" style={{ color: item.g3 ? "green" : "gray" }} />
                                                                </td>
                                                                <td><span className="head">Attributi</span>{(attributi.join(", "))}</td>
                                                            </tr>
                                                        ) : (
                                                            <React.Fragment key={`row_${index}`} />
                                                        )
                                                    );
                                                })}
                                            </>
                                        ) : (
                                            <tr>
                                                <td colSpan={2}>caricamento in corso...</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </fieldset>
                            <fieldset>
                                <div className="rack">
                                    <div className="w33">
                                        <h3>Legenda</h3>
                                        <div className="rack">
                                            <div className="w100">
                                                <p>A = Allarme</p>
                                                <p>24H = Memoria Allarme 24 ore</p>
                                                <p>E = Escluso</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w66">
                                        <h3>Legenda Attributi</h3>
                                        <div className="rack">
                                            <div className="w50">
                                                <p>r = Ronda</p>
                                                <p>P = Percorso</p>
                                                <p>S = Silenzioso</p>
                                                <p>p = Parzializzabile</p>
                                                <p>A = Allarme A</p>
                                                <p>F = Fuoco</p>
                                                <p>E = Elettroserratura</p>
                                            </div>
                                            <div className="w50">
                                                <p>R = Ritardato</p>
                                                <p>e = Escluso</p>
                                                <p>T = Test</p>
                                                <p>24h = Allarme 24 ore</p>
                                                <p>K = Allarme K</p>
                                                <p>C = Campanello</p>
                                                <p>D = Doppio Impulso</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const StatoIngressiFilari = connect(mapStateToProps, mapDispatchToProps)(_StatoIngressiFilari);

export default StatoIngressiFilari;