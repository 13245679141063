import React, { useState, useEffect } from 'react';
import {
    BrowserRouter as Router,
} from "react-router-dom";
import { connect } from "react-redux";
import { useIdleTimer } from 'react-idle-timer'

import {
    getProfile,
    logout
} from './actions';

import Main from './components/sections/Main';
import Footer from './components/sections/Footer';
import Loader from './components/elements/Loaders';

const mapStateToProps = state => ({
    auth: state.user.email
});


const mapDispatchToProps = (dispatch) => ({
    getProfile: () => dispatch(getProfile()),
    logout: () => dispatch(logout())
});

const _RoutedApp = ({ getProfile, auth, logout }) => {
    const [firstLoad, setFirstLoad] = useState(false);

    const handleOnIdle = () => {
        if(auth) {
            logout();
        }
    }
    
    useIdleTimer({
        timeout: 1000 * 60 * 15,
        onIdle: handleOnIdle,
        debounce: 500
    })
    
    useEffect(() => {
        if (!firstLoad) {
            getProfile();
            setTimeout(() => { setFirstLoad(true); }, 500);
        };
        return () => setFirstLoad(true);
    }, [firstLoad, getProfile, setFirstLoad]);

    return (
        firstLoad
            ?
            <Router>
                <Main />
                <Footer />
                <Loader />
            </Router>
            :
            null
    );
};

const RoutedApp = connect(mapStateToProps, mapDispatchToProps)(_RoutedApp);

export default RoutedApp;