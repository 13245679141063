import React, { useState } from 'react';
import FileBase64 from 'react-file-base64';

import Geocoder from '../../elements/Geocoder';
import Todo from '../../elements/Forms/Todo';

import { states } from '../../../constants';

const System = (
    { 
        system, 
        handleChange, 
        handleSubmit, 
        saveButton = 'Salva', 
        cancelButton = null, 
        handleCancel, 
        isAddressOfOwner = false, 
        ownerGeodata = null, 
        lockMAC = false, 
        handlerGeodata = null, 
        address = ``, 
        permissions, 
        models,
        modelRequired,
        modelShow = null
    }
) => { 
    const [addressOfOwner, setAddressOfOwner] = useState(isAddressOfOwner);
    const [photoError, setPhotError] = useState(null);
    const maintainer_pic = permissions === 1
        ? system.maintainer_pic
            ? `${process.env.REACT_APP_API_URL}/download/${system.maintainer_pic}`
            : null
        : null
        ;
    const pic = maintainer_pic || (system.pic ? `${process.env.REACT_APP_API_URL}/download/${system.pic}` : null);
    const [photoURL, setPhotoURL] = useState(pic);
    const [hasremovedPic, setHasRemovedPic] = useState(false);

    const handleAddressOfOwner = () => {
        setAddressOfOwner(!addressOfOwner);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (addressOfOwner) {
            handleChange({
                target: { type: 'input', name: 'geodata', value: ownerGeodata }
            });
        }
        if (photoURL && photoURL != pic) {
            const base64 = (photoURL.split(','))[1];
            handleChange({
                target: { type: 'input', name: 'pic', value: base64 }
            });
        } else if (photoURL == null) {
            handleChange({
                target: { type: 'input', name: 'pic', value: null }
            });
            if(hasremovedPic) {
                handleChange({
                    target: { type: 'input', name: 'hasremovedpic', value: 1 }
                });    
            }
        }
        handleSubmit(e);
    }

    return (
        <div className="formSection">
            <form>
                <fieldset>
                    <h3>Dati</h3>
                    <div className="rack">
                        {!lockMAC &&
                            <div className="w33">
                                <label>ID univoco*</label>
                                <input type="text" name="password" defaultValue={``} onChange={handleChange} />
                                <small>Inserire codice formato da 16 caratteri, senza spazi e senza virgole</small>
                            </div>
                        }
                        <div className="w33">
                            <label>Nome impianto*</label>
                            <input type="text" name="name" defaultValue={system.name || ``} onChange={handleChange} disabled={(!(permissions & 2))} maxLength={30} />
                        </div>
                        {modelRequired && (
                            <div className="w33">
                                <label>Modello impianto*</label>
                                <select defaultValue={system.model || ""} name="model" onChange={handleChange} disabled={(!(permissions & 1))}>
                                    <option value="" disabled></option>
                                    {models.map((value, index) => <option key={index} value={value.id}>{value.code} {value.name}</option>)}
                                </select>
                            </div>
                        )}
                        {!!modelShow && (
                            <div className="w33">
                                <label>Modello impianto</label>
                                <p>{modelShow.code} {modelShow.name}</p>
                            </div>
                        )}
                    </div>
                    {handlerGeodata && (
                        <>
                            <h3>Indirizzo</h3>
                            <div className="rack">
                                {ownerGeodata && (
                                    <div className="w100">
                                        <input type="checkbox" id="address_of_owner" name="address_of_owner" defaultValue="0" defaultChecked={0} onChange={handleAddressOfOwner} disabled={(!(permissions & 1))} />
                                        <label htmlFor={`address_of_owner`}>
                                            <span />
                                            Utilizza residenza del proprietario
                                        </label>
                                    </div>
                                )}
                                {!addressOfOwner && (
                                    <div className="w100">
                                        <Geocoder
                                            handlerGeodata={handlerGeodata}
                                            initialAddress={address}
                                            disabled={(!(permissions & 2))}
                                        />
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                    {((permissions & 2) > 0) && (
                        <>
                            <h3>Opzioni di accesso</h3>
                            <div className="rack">
                                <div className="w50 switchButton">
                                    <input type="checkbox" className="switch" id="options" name="options" defaultValue="1" defaultChecked={((system.options & 1) > 0)} onChange={handleChange} />
                                    <label htmlFor={`options`}>
                                        <span>
                                            <span />
                                        </span>
                                        Disabilita accesso automatico manutentori
                                    </label>
                                    <p>
                                        <small><em>Ogni accesso di un manutentore dovrà essere espressamente autorizzato.</em></small>
                                    </p>
                                </div>
                                {lockMAC && 
                                    <div className="w50 switchButton">
                                        <input type="checkbox" className="switch" id="also_maintainer" name="also_maintainer" defaultValue="1" defaultChecked={((permissions & 1) > 0)} onChange={handleChange} />
                                        <label htmlFor={`also_maintainer`}>
                                            <span>
                                                <span />
                                            </span>
                                            Diventa manutentore
                                        </label>
                                    </div>
                                }
                            </div>
                        </>
                    )}
                    <h3>Informazioni aggiuntive</h3>
                    <div className="rack">
                        <div className="w50">
                            <label>Composizione impianto</label>
                            <textarea rows="5" name="description" defaultValue={system.description || ``} onChange={handleChange} disabled={(!(permissions & 1))} />
                        </div>
                        <div className="w50">
                            <label>Note</label>
                            <textarea rows="5" name="notes" defaultValue={system.notes || ``} onChange={handleChange} disabled={(!(permissions & 1))} />
                        </div>
                    </div>
                    <div className="rack">
                        <div className="w50">
                            <Todo list={system.todo} handleChange={handleChange} disabled={(!(permissions & 1))} />
                        </div>
                        <div className="w50">
                            <label>Stato impianto</label>
                            <div className="rack">
                                {
                                    states.map((value, index) => {
                                        return (
                                            <div className="w50" key={index}>
                                                <input id={`opt_${index}`} type="radio" name="state" value={value} onChange={handleChange} defaultChecked={system.state === value} disabled={(!(permissions & 1))} />
                                                <label className="inline" htmlFor={`opt_${index}`}>
                                                    <span />
                                                    {value}
                                                </label>
                                            </div>
                                        );
                                    })
                                }
                                {(!(permissions & 1)) &&
                                    <p>
                                        <small><em>Solo il manutentore può modificare lo stato impianto.</em></small>
                                    </p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="rack">
                        <h3>Carica foto</h3>
                        <div className="w75">
                            {(permissions & 3)
                                ? <>
                                    <label>File</label>
                                    <FileBase64 onDone={(img) => {
                                        if (img.type != "image/jpeg") {
                                            setPhotError(`Formato non valido`);
                                        } else if (parseInt(img.size) > 10240) {
                                            setPhotError(`Immagine troppo pesante`);
                                        } else {
                                            setPhotError(null);
                                            setPhotoURL(img.base64);
                                        }
                                    }} />
                                    <br /><small>caricare file in formato JPEG e non superiori a 10 MB</small>
                                </>
                                : <p>Non si dispone dei permessi necessari</p>
                            }
                        </div>
                        <div className="w25">
                            {photoError &&
                                <p style={{ color: 'red' }}><em>{photoError}</em></p>
                            }
                            {!!photoURL
                                ?
                                (
                                    <>
                                        <img className="preview" src={photoURL} alt="preview" />
                                        <p>
                                            <button className="ok auto" onClick={e => { e.preventDefault(); setPhotoURL(null); if(!!pic) { setHasRemovedPic(true); }  }}>Elimina</button>
                                        </p>
                                    </>
                                )
                                :
                                (
                                    <p><em>nessuna foto caricata</em></p>
                                )
                            }
                        </div>
                    </div>
                    <div className="rack buttonPanel">
                        <div className="w50 left">
                            <p>&nbsp;</p>
                            {
                                cancelButton && (<button className="ok auto" onClick={handleCancel}>{cancelButton}</button>)
                            }
                        </div>
                        <div className="w50 right">
                            {
                                saveButton && (
                                    <>
                                        <p><small>I campi contrassegnati con (*) sono obbligatori</small></p>
                                        <button className="ok auto" onClick={e => onSubmit(e)}>{saveButton}</button>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    );
};

export default System;