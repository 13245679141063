import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Modal from '../../../elements/Modal';
import Popup from '../../../elements/Modal/Popup';
import { b2jRadioStat } from '../../../../lib/goldParser/physicalMap';

import {
    setStore
} from '../../../../actions';
import { goldGetRadioAnalysis, goldSetPeripheral, sleep } from '../../../../api/Cloud';
import { bcd2int, bytes2int, hexstringToArrayInt } from '../../../../lib/bytesFunctions';

const mapStateToProps = state => ({
    reset_dev_stats: state.reset_dev_stats,
    system: state.system
});

const mapDispatchToProps = (dispatch) => ({
    setStore: data => dispatch(setStore(data))
});

const _StatoDispositiviRadio = ({ system, setStore, reset_dev_stats }) => {
    const hasPrograms = [0x03, 0x02, 0x12, 0x13, 0x22, 0x01];
    const [group, setGroup] = useState(0);
    const [dispositiviRadio, setDispositiviRadio] = useState([]);
    const [showAnalysis, setShowAnalysis] = useState(false);
    const [processed, setProcessed] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [error, setError] = useState(false);
    const [rf, setRf] = useState({
        rx: 0,
        tx: 0,
        errore: 0,
        rumore: 0
    });
    const [infoRadio, setInfoRadio] = useState(null);
    const [infoRadioPopup, setInfoRadioPopup] = useState(null);
    const [infoRadioLoading, setInfoRadioLoading] = useState(false);

    const init = useCallback(
        () => {
            let _dispositiviRadio = [];
            for (let i = 0; i < system.store.pm.radio.length; i++) {
                const _group = Math.floor(i / 16);
                if(system.store.pm.radio[i].num_tipo_periferica > 0) {
                    const __i = i - (_group * 16);
                    _dispositiviRadio.push({
                        idx: i,
                        group: _group,
                        stat: b2jRadioStat(system.store.dev_stats.radio[_group][__i], system.store.pm.radio[i]),
                        ...system.store.pm.radio[i]
                    });    
                }
            }
            setDispositiviRadio(_dispositiviRadio);
        },
        []
    );

    useEffect(
        () => {
            init();
            return () => null;
        },
        []
    );

    useEffect(
        () => {
            startPolling(group);
            return () => { stopPolling(false); }
        },
        [group]
    );

    useEffect(
        () => {
            init();
        },
        [system.store.dev_stats.radio, reset_dev_stats]
    );

    const startPolling = _group => {
        setStore({
            polling_requests: ['goldDevStat'],
            polling_payloads: [{ id_centrale: system.id_centrale, type: 'radio', group: _group }],
            polling_pause: false
        });
    };

    const stopPolling = fullStop => {
        setStore({
            polling_requests: [],
            polling_payloads: [],
            polling_pause: !!fullStop
        });
    };

    const getRadioAnalysis = async () => {
        setProcessed(true);
        stopPolling(true);
        await sleep(1500);
        const [_data, _code] = await goldGetRadioAnalysis({ id_centrale: system.id_centrale, type: 1 });
        if(_code === 200 && _data.status === 'OK') {
            const arr = hexstringToArrayInt(_data.data[0]);
            const results = [];    
            while (arr.length) {
                results.push(arr.splice(0, 4));
            }
            const _rf = {
                rx: bytes2int(...results[0]),
                tx: bytes2int(...results[1]),
                errore: bytes2int(...results[2]),
                rumore: bytes2int(...results[3]),
            };
            setRf(_rf);
            await sleep(1500);
            startPolling(group);
            setProcessed(false);
        } else {
            setProcessed(false);
            setError(`Si è verificato un imprevisto, riprovare`);
        }
    };

    const getInfoRadio = async n_disp => {
        setInfoRadioLoading(true);
        stopPolling(true);
        await sleep(1500);
        const [_data, _code] = await goldSetPeripheral({ id_centrale: system.id_centrale, n_disp, no_update: true });
        if(_code === 200 && _data.status === 'OK') {
            for(let i = 0; i<5; i++) {
                await sleep(500);
                const [__data, __code] = await goldGetRadioAnalysis({ id_centrale: system.id_centrale, type: 0 });
                if(__code === 200 && __data.status === 'OK') {
                    const arr = hexstringToArrayInt(__data.data[0]);
                    if(arr[2] === 0 && arr[3] === 0) {
                        if(i==4) {
                            setInfoRadioPopup(false);
                            setError(`Nessuna comunicazione con il dispositivo radio`);
                            break;    
                        }
                    } else {
                        setInfoRadio({
                            progress: Math.floor(((arr[1] > 130 ? 130 : arr[1]) / 130) * 100),
                            fw: bcd2int(arr[3])+'.'+bcd2int(arr[2]),
                            frequency: (arr[5] & 0x01) != 0 ? '9' : '8'
                        });
                        break;
                    }
                }    
            }
            startPolling(group);
        } else {
            setInfoRadioPopup(false);
            setError(`Si è verificato un imprevisto, riprovare`);
        }
        setInfoRadioLoading(false);
    };

    const deleteRadioAnalysis = async () => {
        setProcessed(true);
        const [_data, _code] = await goldGetRadioAnalysis({ id_centrale: system.id_centrale, type: 2 });
        if(_code === 200 && _data.status === 'OK') {
            await getRadioAnalysis();
        } else {
            setError(`Si è verificato un imprevisto, riprovare`);
            setProcessed(false);
        }
    };

    if (!!!system.store || !!!system.store.auth || !(system.store.auth & 6)) {
        return (
            <Modal
                title={`LinceCloud -> Impianto`}
                text={`Permessi insufficenti`}
                redirect={`/gold/${system.id}`}
            />
        );
    }

    return (
        <section>
            <div className="innerSection">
                <h1>Stato Dispositivi Radio</h1>
                {
                    confirm && (
                        <Modal
                            title={`Cancellazione`}
                            text={`Confermare?`}
                            onClose={() => { setConfirm(false);} }
                            onConfirm={() => {
                                deleteRadioAnalysis();
                                setConfirm(false);
                            }}
                        />
                    )
                }
                {
                    error && (
                        <Modal
                            title={`Errore`}
                            text={error}
                            onClose={() => { setError(false); } }
                        />
                    )
                }
                {
                    infoRadioPopup && (
                        <Popup
                            title={`Info Radio`}
                            onClose={() => { 
                                setInfoRadioPopup(null);
                                setError(false);
                                startPolling(group);
                            }}
                        >
                            {infoRadioLoading
                                ? <p className="loadingText" style={{ textAlign: "center" }}>In attesa del dispositivo radio</p>
                                : !!infoRadio
                                    ? (
                                        <div style={{ margin: '1em 0' }}>
                                            <p>Versione FW: {infoRadio.fw} - Frequenza 86 {infoRadio.frequency}</p>
                                            <p>Livello del segnale</p>
                                            <div className="bar">
                                                <div className="progress" style={{ width: `${infoRadio.progress}%` }}><span className="progress-perc">{infoRadio.progress}%</span></div>
                                            </div>
                                        </div>
                                    ) : (
                                        <p>Nessuna comunicazione con il dispositivo radio</p>
                                    )
                            }
                        </Popup>
                    )
                }
                <div className="rack">
                    <div className="w100 formSection">
                        <form>
                            <fieldset>
                                <div className="rack">
                                    <div className="w20">
                                        <label>Scelta gruppo</label>
                                        <select defaultValue={group} name="group" onChange={e => setGroup(parseInt(e.target.value))}>
                                            <option value={0}>Da 1 a 16</option>
                                            {dispositiviRadio.length > 16 && <option value={1}>Da 17 a 32</option>}
                                            {dispositiviRadio.length > 32 && <option value={2}>Da 33 a 48</option>}
                                            {dispositiviRadio.length > 48 && <option value={3}>Da 49 a 64</option>}
                                        </select>
                                    </div>
                                </div>    
                            </fieldset>
                            <fieldset>
                                <table className="controlPanelTable responsiveTable">
                                    <thead>
                                        <tr>
                                            <th>Nome Dispositivo</th>
                                            <th>Allarmi</th>
                                            <th>Memorie</th>
                                            <th>Stato</th>
                                            <th>Guasti</th>
                                            <th>Programmi</th>
                                            <th>Attributi</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ textAlign: "center" }}>
                                    {dispositiviRadio.length > 0 
                                        ? (
                                            <>
                                                {dispositiviRadio.map((item, index) => {
                                                    const nome = item.nome;
                                                    const allarmi = [];
                                                    !!item.stat.tamper && allarmi.push('24H');
                                                    !!item.stat.allarme && allarmi.push('A');
                                                    !!item.stat.allarme_reed && allarmi.push('A');
                                                    !!item.stat.allarme_am && allarmi.push('AM');
                                                    !!item.stat.stato_uscita && allarmi.push('U');
                                                    !!item.stat.allarme_aux && allarmi.push('X');
                                                    !!item.stat.allagamento && allarmi.push('AL');
                                                    !!item.stat.fumo && allarmi.push('FM');
                                                    const memorie = [];
                                                    !!item.stat.memoria_tamper && memorie.push('24H');
                                                    !!item.stat.memoria && memorie.push('A');
                                                    !!item.stat.memoria_reed && memorie.push('A');
                                                    !!item.stat.memoria_aux && memorie.push('A');
                                                    const stato = [];
                                                    !!item.stat.escluso_led && stato.push('E');
                                                    !!item.stat.supervisione_led && stato.push('S');
                                                    !!item.stat.dormiente && stato.push('D');                                                    const guasto = [];
                                                    !!item.stat.guasto && guasto.push('G');
                                                    item.stat.batteria_scarica === true && guasto.push('b');
                                                    item.stat.batteria_scarica === false && guasto.push('B');
                                                    const attributi = [];
                                                    !!item.ronda && attributi.push('r');
                                                    !!item.percorso && attributi.push('P');
                                                    !!item.silenzioso && attributi.push('S');
                                                    !!item.parzializzabile && attributi.push('p');
                                                    !!item.a && attributi.push('A');
                                                    !!item.fuoco && attributi.push('F');
                                                    !!item.elettroserratura && attributi.push('E');
                                                    !!item.ritardato && attributi.push('R');
                                                    !!item.escluso && attributi.push('e');
                                                    !!item.test && attributi.push('T');
                                                    !!item.sempre_attivo && attributi.push('24H');
                                                    !!item.k && attributi.push('K');
                                                    !!item.campanello && attributi.push('C');
                                                    !!item.supervisione && attributi.push('s');
                                                    !!item.led_on && attributi.push('L');
                                                    return (
                                                        item.group === group
                                                        ? (
                                                            <tr key={`row_${index}`}>
                                                                <td><span className="head">Nome Dispositivo</span>
                                                                    <Link to="#" onClick={e => {
                                                                        e.preventDefault();
                                                                        setInfoRadioPopup(true);
                                                                        setInfoRadio(null);
                                                                        getInfoRadio(index);
                                                                    }}>{nome}</Link>
                                                                </td>
                                                                <td><span className="head">Allarmi</span>{(allarmi.join(", "))}</td>
                                                                <td><span className="head">Memorie</span>{(memorie.join(", "))}</td>
                                                                <td><span className="head">Stato</span>{(stato.join(", "))}</td>
                                                                <td><span className="head">Guasti</span>{(guasto.join(", "))}</td>
                                                                {
                                                                    (!!item.num_tipo_periferica && (hasPrograms.includes(item.tipo_spec)))
                                                                    ? (
                                                                        <td>
                                                                            <span className="head">Programmi</span>
                                                                            <i className="fa fa-circle" style={{ color: item.g1 ? "red" : "gray" }} />
                                                                            <i className="fa fa-circle" style={{ color: item.g2 ? "yellow" : "gray" }} />
                                                                            <i className="fa fa-circle" style={{ color: item.g3 ? "green" : "gray" }} />
                                                                        </td>    
                                                                    ) : (
                                                                        <td /> 
                                                                    )
                                                                }
                                                                <td><span className="head">Attributi</span>{(attributi.join(", "))}</td>
                                                            </tr>
                                                        ) : (
                                                            <React.Fragment key={`row_${index}`} />
                                                        )
                                                    );
                                                })}
                                            </>
                                        ) : !!dispositiviRadio && dispositiviRadio.length === 0 
                                            ? (
                                                <tr>
                                                    <td colSpan={2}>nessun dispositivo</td>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    <td colSpan={2}>caricamento in corso...</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </fieldset>
                            <fieldset>
                                <div className="rack">
                                    <div className="w100">
                                        <button className="ok auto"disabled={showAnalysis} onClick={async e => { 
                                            e.preventDefault();
                                            setShowAnalysis(true);
                                            await getRadioAnalysis();
                                        }}>Analisi Campo RF</button>
                                    </div>
                                </div>
                                {showAnalysis && (
                                    <div className="rack">
                                        {processed
                                            ? (
                                                <div className="loadingText">richiesta in corso</div>
                                            ) : (
                                                <>
                                                    <div className="w50">
                                                        <p>
                                                            <strong>RX </strong>: <span>{rf.rx}</span> {` `}
                                                            <strong>TX </strong>: <span>{rf.tx}</span> {` `}
                                                            <strong>Errore </strong>: <span>{rf.errore}</span> {` `}
                                                            <strong>Rumore </strong>: <span>{rf.rumore}</span> {` `}
                                                        </p>
                                                    </div>
                                                    <div className="w50">
                                                        <p>
                                                            <button className="ok auto spaced" onClick={e => {
                                                                e.preventDefault();
                                                                setConfirm(true);
                                                            }}>Cancella</button>
                                                            <button className="ok auto spaced" onClick={() => { setShowAnalysis(false); }}>Chiudi</button>
                                                        </p>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                )}
                            </fieldset>
                            <fieldset>
                                <div className="rack">
                                    <div className="w50">
                                        <h3>Legenda</h3>
                                        <div className="rack">
                                            <div className="w50">
                                                <p>A = Allarme</p>
                                                <p>U = Uscite</p>
                                                <p>E = Escluso</p>
                                                <p>D = Dormiente</p>
                                                <p>B = Batteria Guasta</p>
                                                <p>AL = Allagamento</p>
                                                <p>FM = Fumo</p>
                                            </div>
                                            <div className="w50">
                                                <p>24H = Memoria Allarme 24 ore</p>
                                                <p>X = Ausiliario</p>
                                                <p>S = Supervisione</p>
                                                <p>G = Guasto</p>
                                                <p>b = Batteria Scarica</p>
                                                <p>AM = Allarme Antimask</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w50">
                                        <h3>Legenda Attributi</h3>
                                        <div className="rack">
                                            <div className="w50">
                                                <p>r = Ronda</p>
                                                <p>P = Percorso</p>
                                                <p>s = Silenzioso</p>
                                                <p>p = Parzializzabile</p>
                                                <p>A = Allarme A</p>
                                                <p>F = Fuoco</p>
                                                <p>E = Elettroserratura</p>
                                                <p>G = GSM</p>
                                            </div>
                                            <div className="w50">
                                                <p>R = Ritardato</p>
                                                <p>e = Escluso</p>
                                                <p>T = Test</p>
                                                <p>24h = Allarme 24 ore</p>
                                                <p>K = Allarme K</p>
                                                <p>C = Campanello</p>
                                                <p>S = Supervisione</p>
                                                <p>L = LED ON</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const StatoDispositiviRadio = connect(mapStateToProps, mapDispatchToProps)(_StatoDispositiviRadio);

export default StatoDispositiviRadio;