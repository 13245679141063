function bindec(binaryString) {
    binaryString = (binaryString + '').replace(/[^01]/gi, '')
    return parseInt(binaryString, 2);
}

export function bcd2str(bcd) {
    let high = bcd & 0xf0;
    high >>>= 4;
    high = high & 0x0f;
    let low = (bcd & 0x0f);
    return `${high}${low}`;
}

export function bcd2int(bcd) {
    let high = (bcd & 0xF0);
    high >>>= 4;	
    high = (high & 0x0F);
    return ((bcd & 0x0F) + (high * 10));
}

export function int2bcd(data) {
    let temp = (parseInt(data) % 10);
    let d_temp = (parseInt(data) / 10);
    temp |= d_temp << 4;
    return temp;  
}

export default function get_bits(n, p = 0, q = 1) {
    const arr = [];
    const nn = parseInt(n);
    const t = p + q;
    for (let i = p + 1; i <= t; i++) {
        arr.push((nn >> i - 1) & 1);
    }
    return bindec(arr.reverse().join(''));
}

export function stringToArrayInt(length, str, fill = 0x20) {
    return [...str.split('').map(x => x.charCodeAt(0)), ...new Array(length).fill(fill)].slice(0, length);
}

export function arrayIntToString(arr) {
    return !!arr.find(x => x > 0x7A)
        ? ''
        : arr.filter(x => x >= 0x20 && x <= 0x7A).map(x => String.fromCharCode(x)).join('').trim();
}

export function hexstringToArrayInt(hexstring, checkFF = false) {
    return hexstring.match(/.{1,2}/g).map(x => parseInt(Number(`0x${(checkFF && x.toLowerCase() === 'ff' ? '00' : x)}`), 10));
}

export function hexstringToBCD(hexstring) {
    let arr = hexstring.match(/.{1,2}/g);
    return `${(parseInt(Number(`0x${arr[0]}`), 10))}.${(parseInt(Number(`0x${arr[1]}`), 10))}`; 
}

export const bytes2int = (...args) => args.reduce((acc, cur, idx) => acc += (cur << (idx*8)), 0);

export const reverseBits = b => {
    b = (b & 0xF0) >> 4 | (b & 0x0F) << 4;
    b = (b & 0xCC) >> 2 | (b & 0x33) << 2;
    b = (b & 0xAA) >> 1 | (b & 0x55) << 1;
    return b;
 }