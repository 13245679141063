import React from 'react';
import ReactSlider from 'react-slider';

import { roles } from '../../../constants';

const NewSim = ({ association = null, systems, handleChange, handleSubmit, saveButton = 'Compra', cancelButton = null, handleCancel = null, piano = false }) => {

    return (
        <div className="formSection">
                    <div className="rack">
                        {
                            piano
                                ? (
                                    <div className="w75">
                                        <label>Piano Tariffario</label>
                                        <input type="text" disabled={true} defaultValue={piano} />
                                    </div>
                                )
                                : (
                                    <div className="w75">
                                        <label>Piano Tariffario*</label>
                                        <input type="text" defaultValue={``} name="associated_piano" onChange={handleChange} />
                                    </div>
                                )
                        }
                    </div>
                    <div className="rack">
                        <div className="w75">
                            <label>Seleziona impianto</label>
                            <select defaultValue={association != null ? association.system_id : 0} name="id_centrale" onChange={handleChange}>
                                <option value="0">Seleziona in seguito</option>
                                {
                                    systems && (
                                        systems
                                            .filter(el => el.profiles.split(','))
                                            .filter(el => el.valid === 1)
                                            .map((value, index) => {
                                                return <option key={index} value={value.id_centrale}>{value.name} - {value.profiles}</option>
                                            })
                                    )
                                }
                            </select>
                        </div>
                    </div>
                    <div className="rack">
                        <div className="w75">
                            <label>Numero Telefono Nuova SIM*</label>
                            <input type="text" defaultValue={``} name="msisdn" onChange={handleChange} maxLength="15" onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        } 
                                                    }}/>
                        </div>
                    </div>
                    <div className="rack buttonPanel">
                        <div className="w50 left">
                            <p>&nbsp;</p>
                            {
                                cancelButton && (<button className="ok auto" onClick={handleCancel}>{cancelButton}</button>)
                            }
                        </div>
                        <div className="w50 right">
                            {
                                saveButton && (
                                    <>
                                        <p><small>I campi contrassegnati con (*) sono obbligatori</small></p>
                                        <button className="ok auto" onClick={handleSubmit}>{saveButton}</button>
                                    </>
                                )
                            }
                        </div>
                    </div>
        </div>
    );
};

export default NewSim;