import React, { useCallback, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import Modal from '../../../elements/Modal';
import Popup from '../../../elements/Modal/Popup';
import { clone } from '../../../../lib/clone';
import { b2jBus, b2jCodice, b2jRadio } from '../../../../lib/goldParser/physicalMap';
import {
    goldDoRequest,
    setStore
} from '../../../../actions';
import { goldGetRadioAnalysis, goldTestRadio, sleep } from '../../../../api/Cloud';
import { hexstringToArrayInt } from '../../../../lib/bytesFunctions';
import { j2bRadio } from '../../../../lib/goldParser/converter';

const mapStateToProps = state => ({
    data: state.queue_data,
    errors: state.queue_errors,
    system: state.system
});

const mapDispatchToProps = (dispatch) => ({
    goldDoRequest: (requests, payloads) => dispatch(goldDoRequest(requests, payloads)),
    setStore: data => dispatch(setStore(data))
});

const _Comandi = ({ data, errors, system, goldDoRequest, setStore }) => {
    const codeOtpForm = useRef(null);
    const [serviceStatus, setServiceStatus] = useState(!!system.store.state.stato.servizio);
    const [confirm, setConfirm] = useState(false);
    const [otpBox, setOtpBox] = useState(false);
    const [processed, setProcessed] = useState(null);
    const [processedOTP, setProcessedOTP] = useState(null);
    const [success, setSuccess] = useState(null);
    const [redir, setRedir] = useState(null);
    const [testRadio, setTestRadio] = useState([]);
    const [testRadioPopup, setTestRadioPopup] = useState(null);
    const [error, setError] = useState(false);
    const [timer, setTimer] = useState(null);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const umount = useCallback(async () => {
        clearInterval(timer);
        if(testRadioPopup) {
            await goldTestRadio({ id_centrale: system.id_centrale, type: 0 }, true);
        }
    }, [timer, testRadioPopup]);

    useEffect(() => {
        return async () => {
            await umount();
            setTimer(null);
            await sleep(1500)
            startPolling();
            return true;
        }
    }, []);

    useEffect(() => {
        setServiceStatus(system.store.state.stato.servizio);
        return () => null;
    }, [system.store.state.stato.servizio]);

    const startPolling = () => {
        setStore({
            polling_pause: false
        });
    };

    const stopPolling = () => {
        setStore({
            polling_pause: true
        });
    };

    const pollTestRadio = async () => {
        const [__data, __code] = await goldGetRadioAnalysis({ id_centrale: system.id_centrale, type: 0 });
        if(__code === 200 && __data.status === 'OK') {
            const arr = hexstringToArrayInt(__data.data[0]);
            if(!(arr[2] === 0 && arr[3] === 0)) {
                const device = system.store.pm.radio[parseInt(arr[0])];
                if(!!!device) {
                    return;
                }
                const rssi = ((arr[1] & 0xFF) * 10) / 13;
                const str = `${device.nome} - Segnale: ${Math.floor(rssi > 100 ? 100 : rssi)}%, Frequenza: 86 ${(arr[5] & 0x01) != 0 ? 9 : 8 }`;
                setTestRadio(arr => [...arr, str]);
            }
        }
    };

    const startPollTestRadio = async () => {
        const interval = setInterval(
            async () => {
                await pollTestRadio();
            },
            10000
        );
        setTimer(interval);    
    };

    const startTestRadio = async () => {
        setTestRadio([]);
        setError(false);
        stopPolling();
        setButtonDisabled(true);
        setTimer(null);
        await sleep(3000);
        const [_data, _code] = await goldTestRadio({ id_centrale: system.id_centrale, type: 1 }, true);
        if(_code === 200 && _data.status === 'OK') {
            await startPollTestRadio();
            setTestRadioPopup(true);
        } else {
            setTestRadioPopup(false);
            startPolling();
            setError(`Si è verificato un imprevisto, riprovare`);    
        }
    };

    const stopTestRadio = async () => {
        setTestRadioPopup(false);
        clearInterval(timer);
        setTimer(null);
        await goldTestRadio({ id_centrale: system.id_centrale, type: 0 }, true);
        startPolling();    
        await sleep(3000);
        setButtonDisabled(false);
    };

    const download = async () => {
        await stopTestRadio();
        const element = document.createElement("a");
        const content = `
Lince Clouding
---------------------------------------------------
Test Radio del ${(new Date()).toLocaleDateString('it-IT', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' })}
Impianto ${system.name}
---------------------------------------------------
    `;
        const file = new Blob([`${content}\n${testRadio.join('\n')}`], { type: 'text/csv' });
        element.href = URL.createObjectURL(file);
        element.download = "TestRadio.csv";
        document.body.appendChild(element);
        element.click();
    };

    const sendCommand = (e, command) => {
        e.preventDefault();
        const _confirm = { text: null, command }
        switch(command) {
            case 'attivaServizio':
                _confirm.text = `Attivare la modalità servizio?`;
                break;
            case 'cancellaAllarmi':
                _confirm.text = `Le memorie di allarme verranno cancellate`;
                break;
            case 'cancellaBus':
                _confirm.text = `Tutti i dispositivi su BUS verranno cancellati`;
                break;
            case 'cancellaChiavi':
                _confirm.text = `Tutte le chiavi memorizzate verranno cancellate`;
                break;
            case 'cancellaRadio':
                _confirm.text = `Tutti i dispositivi Radio verranno cancellati`;
                break;
            case 'disattivaServizio':
                _confirm.text = `Disattivare la modalità servizio?`;
                break;
            case 'initSistema':
                _confirm.text = `Il sistema verrà ripristinato alle impostazioni di fabbrica`;
                break;    
            case 'spegnimentoRF':
                _confirm.text = `Tutti i dispositivi RF verranno disattivati`;
                break;
        }
        if(_confirm.text != null) {
            setConfirm(_confirm);
        }
    };

    const handleConfirm = command => {
        setConfirm(false);
        const requests = [];
        const payloads = [];
        let payload = null;
        switch(command) {
            case 'attivaServizio':
                payload = { id_centrale: system.id_centrale, value: 1 };
                goldDoRequest('goldSetService', payload);
                setStore({
                    polling_requests: ['goldSetService'],
                    polling_payloads: [payload]
                });
                setProcessed(command);
                break;
            case 'cancellaAllarmi':
                payload = { id_centrale: system.id_centrale, value: 0 };
                goldDoRequest('goldClearMemory', payload);
                setProcessed(command);
                break;
            case 'cancellaBus':
                system.store.pm.bus.map((el, idx) => {
                    if(parseInt(el.num_tipo_periferica) > 0) {
                        payloads.push({ 
                            id_centrale: system.id_centrale,
                            type: 'bus',
                            n_disp: idx
                        });
                        requests.push('goldDelPeripheral');
                    }
                })
                break;    
            case 'cancellaChiavi':
                system.store.pm.codici.map((el, idx) => {
                    if(parseInt(el.tipo_codice) === 128) {
                        payloads.push({ 
                            id_centrale: system.id_centrale,
                            type: 'chiave',
                            n_disp: idx
                        });
                        requests.push('goldDelPeripheral');
                    }
                })
                break;
            case 'cancellaRadio':
                system.store.pm.radio.map((el, idx) => {
                    if(parseInt(el.num_tipo_periferica) > 0) {
                        const payload = clone(el);
                        payload.indirizzo_periferica = 0xFFFF;
                        payload.num_tipo_periferica = 0xFF;
                        payload.num_spec_periferica = 0;
                        requests.push('goldDelPeripheral');
                        payloads.push({ 
                            id_centrale: system.id_centrale,
                            type: 'radio',
                            n_disp: idx
                        });
                        requests.push('goldModOutRadio');
                        payloads.push(           {
                            id_centrale: system.id_centrale,
                            idx: idx,
                            edata: j2bRadio(payload).map(x => isNaN(x) ? 0 : x)
                        });
                    }
                })
                break;
            case 'disattivaServizio':
                payload = { id_centrale: system.id_centrale, value: 0 };
                goldDoRequest('goldSetService', payload);
                setStore({
                    polling_requests: [],
                    polling_payloads: []
                });  
                setProcessed(command);
                break;
            case 'initSistema':
                payload = { id_centrale: system.id_centrale };
                goldDoRequest('goldSetOtpSystem', payload);
                setProcessedOTP(true);
                break;
            case 'spegnimentoRF':
                payload = { id_centrale: system.id_centrale, value: 2 };
                goldDoRequest('goldSetService', payload);
                setProcessed(command);
                break;
        }
        if(payloads.length > 0) {
            goldDoRequest(requests, payloads);
            setProcessed(command);
        }
    }

    const handleOtp = () => {
        const form = codeOtpForm.current;
        const payload = { id_centrale: system.id_centrale, value: 2, otp: form['codice'].value };
        goldDoRequest('goldClearMemory', payload);
        setProcessed('initSistema');
    }

    if(data && processed) {
        let _success = !!data && !errors && (!!data[0] ? data[0].status === "OK" : data.status === "OK"); 
        switch(processed) {
            case 'attivaServizio':
                _success && setServiceStatus(true);
                break;
            case 'cancellaAllarmi':
            case 'spegnimentoRF':
                if(_success) _success = 3;
                break;
            case 'cancellaBus':
                if(_success) _success = 1;
                for(let i=0; i < data.length; i++) {
                    let pm = clone(system.store.pm);
                    pm.bus[data[i].n_disp] = b2jBus(['000000']); 
                    setStore({
                        pm
                    });
                }
                break;
            case 'cancellaChiavi':
                if(_success) _success = 1;
                for(let i=0; i < data.length; i++) {
                    let pm = clone(system.store.pm);
                    pm.codici[data[i].n_disp] = b2jCodice(['00']); 
                    setStore({
                        pm
                    });
                }
                break;
            case 'cancellaRadio':
                if(_success) _success = 1;
                for(let i=0; i < data.length; i++) {
                    let pm = clone(system.store.pm);
                    pm.radio[data[i].n_disp] = b2jRadio(['000000000000000000000000']); 
                    setStore({
                        pm
                    });
                }
                break;    
            case 'disattivaServizio':
                _success && setServiceStatus(false);
                break;
            case 'initSistema':
                if(_success) {
                    _success = 2;
                } else if(!!data.error) {
                    _success = data.error;
                }
                break;
        }
        setSuccess(_success);
        setProcessed(null);
    }

    if(data && processedOTP) {
        if(!!data && !errors && !!data[0] && data[0].status === 'OK') {
            setOtpBox(true);
        } else {
            setSuccess(false);
        }
        setProcessedOTP(false);
    }

    if (redir) {
        return <Redirect to={redir} />
    }

    return (
        <section>
            {success === 1 && <Modal title={`Impianto -> LinceCloud`} text={`Comando inviato con successo`} onClose={() => { setSuccess(null); }}/> }
            {success === 2 && <Modal title={`Impianto -> LinceCloud`} text={`Inizializzazione avviata, verrà effettuato il logout dall'impianto`} onClose={() => { setSuccess(null); setRedir('/'); }}/> }
            {success === 3 && <Modal title={`Impianto -> LinceCloud`} text={`Comando inviato con successo.`} onClose={() => { setSuccess(null); }}/> }
            {success === false && <Modal title={`Impianto -> LinceCloud`} text={`Si é verificato un errore`} onClose={() => { setSuccess(null); }}/> }
            {typeof success === 'string' && <Modal title={`Impianto -> LinceCloud`} text={success} onClose={() => { setSuccess(null); }}/> }
            {error && (
                <Modal
                    title={`Errore`}
                    text={error}
                    onClose={() => { setError(false); stopTestRadio(); } }
                />
            )}
            {
                otpBox && (
                    <Popup
                        title={`Inserisci OTP`}
                        onClose={() => { 
                            setOtpBox(false);
                        }}
                        onConfirm={() => { 
                            setOtpBox(false);
                            handleOtp();
                        }}
                    >
                        <div className="rack">
                            <div className="w100 formSection">
                                <form ref={codeOtpForm}>
                                    <fieldset>
                                        <div className="rack">
                                            <p style={{ textAlign: 'left' }}>E' stato inviato via email un codice OTP al proprietario. Inserire il codice per proseguire.</p>
                                        </div>
                                        <div className="rack">
                                            <div className="w25">
                                                <label>Codice OTP</label>
                                                <input
                                                    type="password"
                                                    maxLength={6}
                                                    autoComplete={`off`}
                                                    placeholder="Inserisci Codice OTP"
                                                    name="codice"
                                                    defaultValue={``}
                                                />
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </Popup>
                )
            }
            {testRadioPopup && (
                <Popup
                    title={`Test Radio`}
                    text={``}
                    onConfirmLabel={'Esporta'}
                    onConfirm={testRadio.length > 0 ? () => { download() } : null}
                    onClose={() => { stopTestRadio(); }}
                >
                    <p className="loadingText">in attesa dei dispositivi radio</p>
                    {testRadio.length > 0 &&
                        <div style={{ background: '#fcfcfc', width: '100%', height: '150px', overflowY: 'scroll' }}>
                            {testRadio.map((el, idx) => (
                                <p key={idx} style={{ textAlign: "left", margin: '.75em 0' }}>{el}</p>
                            ))}
                        </div>
                    }
                </Popup>
            )}
            <div className="innerSection formSection">
                {confirm && (
                    <Modal
                        title={`Sicuro di voler procedere?`}
                        text={confirm.text}
                        onClose={() => { setConfirm(false); }}
                        onConfirm={() => { handleConfirm(confirm.command); }}
                    />
                )}
                <h1>Comandi di Centrale</h1>
                <div className="rack">
                    <h4>Setup</h4><br />
                    <div className="w50">
                        <div className="padp">
                            <button className="superButton" disabled={!!!system.store.auth || system.store.auth !== 2} onClick={e => { sendCommand(e, 'initSistema'); }}>Inizializza Sistema</button>
                        </div>
                    </div>
                    <div className="w50">
                        <div className="padp">
                            {!!serviceStatus
                                ? <button className="superButton" onClick={e => { sendCommand(e, 'disattivaServizio'); }}>Disattiva Servizio</button>
                                : <button className="superButton" onClick={e => { sendCommand(e, 'attivaServizio'); }}>Attiva Servizio</button>
                            }                            
                        </div>
                    </div>
                </div>
                <div className="rack bottomBorder">
                    <div className="w50">
                        <div className="padp">
                            <button className="superButton" disabled={buttonDisabled} onClick={e => { e.preventDefault(); startTestRadio(); }}><span className={(buttonDisabled ? 'loadingText' : '')}>Test Radio</span></button>
                        </div>
                    </div>
                    <div className="w50">
                        <div className="padp">
                            <button className="superButton" disabled={!!!system.store.auth || system.store.auth !== 2} onClick={e => { sendCommand(e, "spegnimentoRF"); }}>Spegnimento RF</button>
                        </div>
                    </div>
                </div>
                <div className="rack">
                    <h4>Setup dispositivi</h4><br />
                    <div className="w50"> 
                        <div className="padp">
                            {!!!system.store || !!!system.store.auth || system.store.auth !== 2
                                ? <button className="superButton" disabled={true} onClick={e => { e.preventDefault(); }}>Memorizza Dispositivi BUS</button>
                                : <Link className="superButton" to={`/gold/${system.id}/new_bus`}>Memorizza Dispositivi BUS</Link>
                            }
                        </div>
                    </div>
                    <div className="w50">
                        <div className="padp">
                            {!!!system.store || !!!system.store.auth || system.store.auth !== 2
                                ? <button className="superButton" disabled={true} onClick={e => { e.preventDefault(); }}>Memorizza Dispositivi Radio</button>
                                : <Link className="superButton" to={`/gold/${system.id}/rf`}>Memorizza Dispositivi Radio</Link>
                            }
                        </div>
                    </div>
                </div>
                <div className="rack bottomBorder">
                    <div className="w50">
                        <div className="padp">
                            {!!!system.store || !!!system.store.auth || system.store.auth !== 4
                                ? <button className="superButton" disabled={true} onClick={e => { e.preventDefault(); }}>Memorizza Chiavi</button>
                                : <Link className="superButton" disabled={true} to={`/gold/${system.id}/key`}>Memorizza Chiavi</Link>
                            }
                        </div>
                    </div>
                    <div className="w50">
                        <div className="padp">
                            {!!!system.store || !!!system.store.auth || system.store.auth !== 4
                                ? <button className="superButton" disabled={true} onClick={e => { e.preventDefault(); }}>Memorizza Codici</button>
                                : <Link className="superButton" to={`/gold/${system.id}/new_code`}>Memorizza Codici</Link>
                            }
                        </div>
                    </div>
                </div>
                <div className="rack">
                    <h4>Elimina</h4><br />
                    <div className="w50">
                        <div className="padp">
                            <button className="superButton" disabled={!!!system.store.auth || system.store.auth !== 2} onClick={e => { sendCommand(e, 'cancellaBus'); }}>Cancella Dispositivi BUS</button>
                        </div>
                    </div>
                    <div className="w50">
                        <div className="padp">
                            <button className="superButton" disabled={!!!system.store.auth || system.store.auth !== 2} onClick={e => { sendCommand(e, 'cancellaRadio'); }}>Cancella Dispositivi Radio</button>
                        </div>
                    </div>
                </div>
                <div className="rack bottomBorder">
                    <div className="w50">
                        <div className="padp">
                            <button className="superButton" style={{}} onClick={e => { sendCommand(e, 'cancellaAllarmi'); }}>Cancella Memoria Allarmi</button>
                        </div>
                    </div>
                    <div className="w50">
                        <div className="padp">
                            <button className="superButton" disabled={!!!system.store.auth || system.store.auth !== 4} onClick={e => { sendCommand(e, 'cancellaChiavi'); }}>Cancella Chiavi</button>
                        </div>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const Comandi = connect(mapStateToProps, mapDispatchToProps)(_Comandi);

export default Comandi;