import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
    doRequest
} from '../../../../actions';

import Outcome from '../../../elements/Outcome';

const mapStateToProps = state => ({
    data: state.data,
    system: state.system,
});

const mapDispatchToProps = (dispatch) => ({
    editSystem: (payload, id) => dispatch(doRequest('patchSystem', { payload, id })),
});

const _PersonalizzaUscite = ({ system, editSystem, data }) => {
    const codeForm = useRef(null);
    const [errors, setErrors] = useState("");
    const [processed, setProcessed] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setSuccess(false);
        setProcessed(true);
        const form = codeForm.current;
        let payload = {
            out1: form['out1'].value || null,
            out2: form['out2'].value || null,
            out3: form['out3'].value || null,
            out4: form['out4'].value || null
        };
        editSystem(payload, system.id);
    };

    if (data && processed) {
        setErrors("");
        setProcessed(false);
        setSuccess(true);
    }

    if (!!!system) {
        return (<></>);
    }
    return (
        <section>
            <div className="innerSection">
                {
                    success
                        ? (
                            <Outcome text="Operazione effettuata con successo" type="ok" onClose={setSuccess} />
                        )
                        : !!errors && errors.length
                            ? <Outcome text={errors} type="ko" onClose={setErrors} />
                            : <></>
                }
                <div className="rack">
                    <div className="w100 formSection">
                        <form ref={codeForm}>
                            <h1>Personalizza Nomi Uscite</h1>
                            <fieldset>
                                <br />
                                <div className="rack">
                                    <div className="w100 customRows">
                                        <label>OUT 1</label>
                                        <input type="text" maxLength={15} name="out1" defaultValue={system.out1 || ``} />
                                    </div>
                                </div>
                                <div className="rack">
                                    <div className="w100 customRows">
                                        <label>OUT 2</label>
                                        <input type="text" maxLength={15} name="out2" defaultValue={system.out2 || ``} />
                                    </div>
                                </div>
                                <div className="rack">
                                    <div className="w100 customRows">
                                        <label>OUT 3</label>
                                        <input type="text" maxLength={15} name="out3" defaultValue={system.out3 || ``} />
                                    </div>
                                </div>
                                <div className="rack">
                                    <div className="w100 customRows">
                                        <label>OUT 4</label>
                                        <input type="text" maxLength={15} name="out4" defaultValue={system.out4 || ``} />
                                    </div>
                                </div>
                                <div className="rack">
                                    <div className="w100 right">
                                        <button className="ok auto spaced" onClick={handleSubmit}>Salva</button>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section >
    );
};

const PersonalizzaUscite = connect(mapStateToProps, mapDispatchToProps)(_PersonalizzaUscite);

export default PersonalizzaUscite;