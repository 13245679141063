import React, { useState } from 'react';
import ReactSlider from 'react-slider';

const Nebbiogeno = ({ current, auth, setMw, setTempoIngresso, system }) => {
    const ingressi = [];
    const _espansioni = [];
    for (let i = 0; i < system.store.pm.bus.length; i++) {
        if(system.store.pm.bus[i].num_tipo_periferica === 8) {
            _espansioni.push(system.store.pm.bus[i]);
        }
    }
    for (let i = 0; i < system.store.pm.filari.length; i++) {
        const offset = system.model < 5 ? 2 : 5;
        const _i = i-offset;
        const expansion = i < offset ? false : (_espansioni[Math.floor(_i / 5)] || null);
        const line = expansion ? (_i % 5) + 1 : null;
        ingressi.push({
            idx: i,
            group: Math.floor(i / 16),
            expansion,
            line,
            ...system.store.pm.filari[i],
            nome: expansion === null ? 'Non Disponibile' : system.store.pm.filari[i].nome 
        });
    }
    const [abilita, setAbilita] = useState(!!current.abilita_ingressi);
    const [andMode, setAndMode] = useState(!!current.and);
    const [gsmMode, setGsmMode] = useState(!!current.percorso);
    return (
        <>
            <div className="rack borderBottom">
                <div className="w33">
                    <p>Tempo di Sparo</p>
                    <ReactSlider
                        defaultValue={current.tempo_ingresso}
                        onChange={value => { setTempoIngresso(value); }}
                        disabled={auth !== 2}
                        max={20}
                        min={1}
                        step={1}
                        className="horizontal-slider"
                        thumbClassName="slider-thumb"
                        trackClassName="slider-track"
                        renderThumb={(props, state) => {
                            return (<div {...props}>{`${state.valueNow} second${parseInt(state.valueNow)===1?'o':'i'}`}</div>);
                        }}
                    />
                </div>
            </div>
            <div className="rack bottomBorder">
                <div className="w25">
                    <label>Associazione a Ingresso</label>
                    <select defaultValue={current.mw} disabled={abilita} onChange={e => { setMw(e.target.value) }}>
                    {ingressi.map((x,i) => {
                            return x.expansion !== null
                            ? <option key={`filari_${i}`} value={i}>{`Filare ${i+1}: ${x.nome}`}</option>
                            : <React.Fragment key={`filari_${i}`} />
                        })}
                        {system.store.pm.radio.map((x,i) => {
                            if(!!x.num_tipo_periferica && (x.num_tipo_periferica === 2 || x.num_tipo_periferica === 3)) {
                                return <option key={`radio_${i}`} value={(i | 0x80)}>{`Radio ${i+1}: ${x.nome}`}</option>
                            } else {
                                <React.Fragment key={`fragment_${i}`} />
                            }
                        })}
                    </select>
                </div>
                <div className="w50">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="abilita" name="abilita_ingressi" defaultValue="0" checked={abilita} onChange={e => { setAbilita(!!e.target.checked); if(!!e.target.checked) { setMw(0); } else { setAndMode(false); } }} />
                        <label htmlFor={`abilita`}>
                            <span>
                                <span />
                            </span>
                            Abilita per tutti gli ingressi
                            </label>
                    </div>
                </div>
            </div>
            <div className="rack bottomBorder">
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="supervisione" name="supervisione" defaultValue="0" defaultChecked={current.supervisione} disabled={auth !== 2} />
                        <label htmlFor={`supervisione`}>
                            <span>
                                <span />
                            </span>
                            Supervisione
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="led_on" name="led_on" defaultValue="0" defaultChecked={current.led_on} disabled={auth !== 2} />
                        <label htmlFor={`led_on`}>
                            <span>
                                <span />
                            </span>
                            LED ON
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="and" name="and" defaultValue="0" checked={andMode} onChange={e => { setAndMode(!!e.target.checked); }} disabled={!abilita || gsmMode || auth !== 2} />
                        <label htmlFor={`and`}>
                            <span>
                                <span />
                            </span>
                            AND
                        </label>
                    </div>
                </div>    
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="antipanico" name="antipanico" defaultValue="0" defaultChecked={current.antipanico} disabled={auth !== 2} />
                        <label htmlFor={`antipanico`}>
                            <span>
                                <span />
                            </span>
                            Antipanico
                        </label>
                    </div>
                </div>
            </div>
            {abilita
                ? (
                    <div className="rack bottomBorder">
                        <div className="w25">
                            <div className="switchButton">
                                <input type="checkbox" className="switch" id="a" name="g1" defaultValue="0" defaultChecked={current.g1} disabled={gsmMode || auth !== 2}
                                />
                                <label htmlFor={`a`}>
                                    <span>
                                        <span />
                                    </span>
                                    A
                                </label>
                            </div>
                        </div>
                        <div className="w25">
                            <div className="switchButton">
                                <input type="checkbox" className="switch" id="k" name="ritardato" defaultValue="0" defaultChecked={current.ritardato} disabled={gsmMode || auth !== 2} />
                                <label htmlFor={`k`}>
                                    <span>
                                        <span />
                                    </span>
                                    K
                                </label>
                            </div>
                        </div>
                        <div className="w25">
                            <div className="switchButton">
                                <input type="checkbox" className="switch" id="fuoco" name="g2" defaultValue="0" defaultChecked={current.g2} disabled={gsmMode || auth !== 2} />
                                <label htmlFor={`fuoco`}>
                                    <span>
                                        <span />
                                    </span>
                                    Fuoco
                                </label>
                            </div>
                        </div>
                        <div className="w25">
                            <div className="switchButton">
                                <input type="checkbox" className="switch" id="campanello" name="a" defaultValue="0" defaultChecked={current.a} disabled={gsmMode || auth !== 2} />
                                <label htmlFor={`campanello`}>
                                    <span>
                                        <span />
                                    </span>
                                    Campanello
                                </label>
                            </div>
                        </div>
                        <div className="w33">
                            <div className="switchButton">
                                <input type="checkbox" className="switch" id="elettroserratura" name="k" defaultValue="0" defaultChecked={current.k} disabled={gsmMode || auth !== 2} />
                                <label htmlFor={`elettroserratura`}>
                                    <span>
                                        <span />
                                    </span>
                                    Elettroserratura
                                </label>
                            </div>
                        </div>
                        <div className="w25">
                            <div className="switchButton">
                                <input type="checkbox" className="switch" id="silenzioso" name="g3" defaultValue="0" defaultChecked={current.g3} disabled={gsmMode || auth !== 2} />
                                <label htmlFor={`silenzioso`}>
                                    <span>
                                        <span />
                                    </span>
                                    Silenzioso
                                </label>
                            </div>
                        </div>
                        <div className="w33">
                            <div className="switchButton">
                                <input type="checkbox" className="switch" id="gsm" name="percorso" defaultValue="0" checked={gsmMode} onChange={e => { setGsmMode(!!e.target.checked); }} disabled={auth !== 2} />
                                <label htmlFor={`gsm`}>
                                    <span>
                                        <span />
                                    </span>
                                    GSM
                                </label>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="rack bottomBorder">
                        <div className="w25">
                            <div className="switchButton">
                                <input type="checkbox" className="switch" id="a" name="g1" defaultValue="0" checked={0} disabled={true} />
                                <label htmlFor={`a`}>
                                    <span>
                                        <span />
                                    </span>
                                    A
                                </label>
                            </div>
                        </div>
                        <div className="w25">
                            <div className="switchButton">
                                <input type="checkbox" className="switch" id="k" name="ritardato" defaultValue="0" checked={0} disabled={true} />
                                <label htmlFor={`k`}>
                                    <span>
                                        <span />
                                    </span>
                                    K
                                </label>
                            </div>
                        </div>
                        <div className="w25">
                            <div className="switchButton">
                                <input type="checkbox" className="switch" id="fuoco" name="g2" checked={0} disabled={true} />
                                <label htmlFor={`fuoco`}>
                                    <span>
                                        <span />
                                    </span>
                                    Fuoco
                                </label>
                            </div>
                        </div>
                        <div className="w25">
                            <div className="switchButton">
                                <input type="checkbox" className="switch" id="campanello" name="a" defaultValue="0" checked={0} disabled={true} />
                                <label htmlFor={`campanello`}>
                                    <span>
                                        <span />
                                    </span>
                                    Campanello
                                </label>
                            </div>
                        </div>
                        <div className="w33">
                            <div className="switchButton">
                                <input type="checkbox" className="switch" id="elettroserratura" name="k" defaultValue="0" checked={0} disabled={true} />
                                <label htmlFor={`elettroserratura`}>
                                    <span>
                                        <span />
                                    </span>
                                    Elettroserratura
                                </label>
                            </div>
                        </div>
                        <div className="w25">
                            <div className="switchButton">
                                <input type="checkbox" className="switch" id="silenzioso" name="g3" checked={0} disabled={true} />
                                <label htmlFor={`silenzioso`}>
                                    <span>
                                        <span />
                                    </span>
                                    Silenzioso
                                </label>
                            </div>
                        </div>
                        <div className="w33">
                            <div className="switchButton">
                                <input type="checkbox" className="switch" id="gsm" name="percorso" defaultValue="0" checked={0} disabled={true} />
                                <label htmlFor={`gsm`}>
                                    <span>
                                        <span />
                                    </span>
                                    GSM
                                </label>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default Nebbiogeno;