import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
    doRequest
} from '../../../../actions';

import Outcome from '../../../elements/Outcome';

const mapStateToProps = state => ({
    data: state.data,
    system: state.system,
});

const mapDispatchToProps = (dispatch) => ({
    editSystem: (payload, id) => dispatch(doRequest('patchSystem', { payload, id })),
});

const _Personalizza = ({ system, editSystem, data }) => {
    const codeForm = useRef(null);
    const gExtEnabled = system.brand === 'lince-europlus';
    const [errors, setErrors] = useState("");
    const [processed, setProcessed] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setSuccess(false);
        setProcessed(true);
        const form = codeForm.current;
        let payload = {
            g1: form['g1'].value,
            g2: form['g2'].value,
            g3: form['g3'].value
        };
        if(gExtEnabled) {
            payload.gext = form['gext'].value;
        }
        editSystem(payload, system.id);
    };

    if (data && processed) {
        setErrors("");
        setProcessed(false);
        setSuccess(true);
    }

    if (!!!system) {
        return (<></>);
    }
    return (
        <section>
            <div className="innerSection">
                {
                    success
                        ? (
                            <Outcome text="Operazione effettuata con successo" type="ok" onClose={setSuccess} />
                        )
                        : !!errors && errors.length
                            ? <Outcome text={errors} type="ko" onClose={setErrors} />
                            : <></>
                }
                <div className="rack">
                    <div className="w100 formSection">
                        <form ref={codeForm}>
                            <h1>Personalizza Nome Programmi</h1>
                            <fieldset>
                                <br />
                                <div className="rack">
                                    <div className="w100 customRows">
                                        <blockquote className="programLabel red">G1</blockquote>
                                        <input type="text" maxLength={15} name="g1" defaultValue={system.g1 || ``} />
                                    </div>
                                </div>
                                <div className="rack">
                                    <div className="w100 customRows">
                                        <blockquote className="programLabel yellow">G2</blockquote>
                                        <input type="text" maxLength={15} name="g2" defaultValue={system.g2 || ``} />
                                    </div>
                                </div>
                                <div className="rack">
                                    <div className="w100 customRows">
                                        <blockquote className="programLabel green">G3</blockquote>
                                        <input type="text" maxLength={15} name="g3" defaultValue={system.g3 || ``} />
                                    </div>
                                </div>
                                {gExtEnabled && (
                                    <div className="rack">
                                        <div className="w100 customRows">
                                            <blockquote className="programLabel blue">GExt</blockquote>
                                            <input type="text" maxLength={15} name="gext" defaultValue={system.gext || ``} />
                                        </div>
                                    </div>
                                )}
                                <div className="rack">
                                    <div className="w100 right">
                                        <button className="ok auto spaced" onClick={handleSubmit}>Salva</button>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section >
    );
};

const Personalizza = connect(mapStateToProps, mapDispatchToProps)(_Personalizza);

export default Personalizza;