import React from 'react';
import { Provider } from "react-redux";
import { configureUrlQuery } from 'react-url-query';

import store from "./store/index";
import RoutedApp from './RoutedApp';
import CacheBuster from './components/elements/CacheBuster';

const history = require("history").createBrowserHistory();
configureUrlQuery({ history });

const App = () => (
    <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
            if (loading) {
                return null;
            }
            if (!loading && !isLatestVersion) {
                refreshCacheAndReload();
            }
            return (
                <Provider store={store}>
                    <RoutedApp />
                </Provider>    
            );
        }}
    </CacheBuster>
);

export default App;