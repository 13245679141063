import React from 'react';

const Radiocomando = ({ current, auth }) => {
    return (
        <>
            <div className="rack bottomBorder">
                <div className="w33">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="elettroserratura" name="k" defaultValue="0" defaultChecked={current.k} disabled={auth !== 2} />
                        <label htmlFor={`elettroserratura`}>
                            <span>
                                <span />
                            </span>
                            Elettroserratura
                        </label>
                    </div>
                </div>
                <div className="w33">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="antipanico" name="sempre_attivo" defaultValue="0" defaultChecked={current.sempre_attivo} disabled={auth !== 2} />
                        <label htmlFor={`antipanico`}>
                            <span>
                                <span />
                            </span>
                            Antipanico
                        </label>
                    </div>
                </div>
                <div className="w33">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="ronda" name="ritardato" defaultValue="0" defaultChecked={current.ritardato} disabled={auth !== 2} />
                        <label htmlFor={`ronda`}>
                            <span>
                                <span />
                            </span>
                            Ronda
                        </label>
                    </div>
                </div>
                <div className="w33">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="silenzioso" name="percorso" defaultValue="0" defaultChecked={current.percorso} disabled={auth !== 2} />
                        <label htmlFor={`silenzioso`}>
                            <span>
                                <span />
                            </span>
                            Silenzioso
                        </label>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Radiocomando;