import React from 'react';

const StatoRadioComando = ({ stat }) => {
    return (
        <>
            <div className="rack">
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.batteria_scarica ? "red" : (stat.batteria_scarica === false ? "yellow" : "gray") }} /> Batteria Scarica</p>
                </div>
            </div>
        </>
    );
};

export default StatoRadioComando;