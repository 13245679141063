import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';

import get_bits, { arrayIntToString, stringToArrayInt } from '../../../../lib/bytesFunctions';

import {
    doSocketRequest,
} from '../../../../actions';

import Modal from '../../../elements/Modal';

const mapStateToProps = state => ({
    system: state.system,
    socket: state.socket,
    data_socket: state.data_socket
});

const mapDispatchToProps = (dispatch) => ({
    doSocketVariation: (socket, trama, payload, noSuccess = false, noError = false) => dispatch(doSocketRequest(socket, trama, payload, true, noSuccess, noError)),
});

const _SuperTasti = ({ system, socket, doSocketVariation, data_socket }) => {
    const codeForm = useRef(null);
    const trama = 0x00E5;
    const [firstLoad, setFirstLoad] = useState(true);
    const [current, setCurrent] = useState(null);
    const [processed, setProcessed] = useState(false);
    const [description, setDescription] = useState(``);
    const status = !!system && !!socket ? system.store.status : null;

    useEffect(
        () => {
            if (firstLoad && !!system.store.status) {
                if (!processed) {
                    doSocketVariation(socket, (trama + 0x0100), [], true, true);
                    setProcessed(true);
                }
                setFirstLoad(false);
            }
            return () => null;
        },
        [firstLoad, setFirstLoad, system, trama, current, doSocketVariation, processed, setProcessed, socket]
    );

    if (data_socket && processed) {
        setProcessed(false);
        if (parseInt(data_socket.type) === parseInt(trama)) {
            let _x = data_socket.payload.split(',').map(x => parseInt(x));
            let _current = {
                trama,
                payload: _x
            };
            setCurrent(_current);
            setDescription(arrayIntToString(_x.slice(2, 34)));
        }
    }

    var teknox = 0;
    if (!status || !system.store.connected) {
        return (
            <Modal
                title={`LinceCloud -> Impianto`}
                text={`Impianto non connesso`}
                redirect={`/europlus/${system.id}`}
            />
        );
    } else {
        if (!!status.structs) {
            teknox = status.structs.isTeknoxAuthorized.auth_level;
            if (teknox != 3) {
                return (
                    <Modal
                        title={`LinceCloud -> Impianto`}
                        text={`Necessari permessi di Installatore`}
                        redirect={`/europlus/${system.id}`}
                    />
                );
            }
        } else {
            return (<section></section>);
        }
    }

    const makePayload = () => {
        const form = codeForm.current;
        const payload = [];
        payload.push((
            (form['st0'].checked ? 1 : 0) +
            (form['st1'].checked ? 2 : 0) +
            (form['st2'].checked ? 4 : 0) +
            (form['st3'].checked ? 8 : 0) +
            (form['st4'].checked ? 16 : 0) +
            (form['st5'].checked ? 32 : 0)
        ));
        payload.push((
            parseInt(form['conf'].value)
        ));
        stringToArrayInt(32, form['desc'].value, 0x20).map(x => { payload.push(x); return null; });
        return payload;
    }

    const handleSubmit = e => {
        e.preventDefault();
        const payload = makePayload();
        doSocketVariation(socket, current.trama, payload);
    }

    if (!!!current || firstLoad) {
        return <>caricamento in corso...</>;
    }

    return (
        <section>
            <div className="innerSection">
                <div className="rack">
                    <div className="w100 formSection">
                        <form ref={codeForm}>
                            {current && (
                                <>
                                    <h1>SuperTasti</h1>
                                    <fieldset className="outputsRack">
                                        <div className="rack borderBottom">
                                            <div className="w33">
                                                <p>
                                                    <input type="checkbox" id={`st0`} name={`st0`} defaultValue="0" defaultChecked={get_bits(current.payload[0], 0)} onChange={() => null} />
                                                    <label htmlFor={`st0`}>
                                                        SuperTasto 0 <span />
                                                    </label>
                                                </p>
                                            </div>
                                            <div className="w33">
                                                <select defaultValue={get_bits(current.payload[1], 0, 4)} name="conf">
                                                    <option value={1}>+N +A</option>
                                                    <option value={2}>+NC +NA</option>
                                                    <option value={4}>OUT</option>
                                                    <option value={8}>AT</option>
                                                </select>
                                            </div>
                                            <div className="w33">
                                                <input type="text" name="desc" defaultValue={description || ``} maxLength={32} />
                                            </div>
                                        </div>
                                        {
                                            (
                                                [
                                                    null,
                                                    `Attiva Relè Uscita dell'Allarme`,
                                                    `Attiva Uscita AT`,
                                                    `Attiva Relè Uscita dell'Allarme + AT`,
                                                    `Inserisce in modo totale l'Impianto`,
                                                    `Visualizza possibili guasti tramite LED centrale`,
                                                ].map((txt, index) => (
                                                    index
                                                        ? (
                                                            <div className="rack borderBottom" key={`st_${index}`}>
                                                                <div className="w33">
                                                                    <p>
                                                                        <input type="checkbox" id={`st${index}`} name={`st${index}`} defaultValue="0" defaultChecked={get_bits(current.payload[0], index)} onChange={() => null} />
                                                                        <label htmlFor={`st${index}`}>
                                                                            SuperTasto {index} <span />
                                                                        </label>
                                                                    </p>
                                                                </div>
                                                                <div className="w33">&nbsp;</div>
                                                                <div className="w33">
                                                                    <p>
                                                                        {txt}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        ) : <p key={`st_0`}></p>
                                                ))
                                            )
                                        }
                                    </fieldset>
                                    <fieldset>
                                        <div className="rack" style={{ marginTop: "4em" }}>
                                            <div className="w100 right">
                                                <button className="ok auto spaced" onClick={e => { e.preventDefault(); setFirstLoad(true); }}>Annulla</button>
                                                <button className="ok auto spaced" onClick={handleSubmit}>Applica</button>
                                            </div>
                                        </div>
                                    </fieldset>
                                </>
                            )}
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const SuperTasti = connect(mapStateToProps, mapDispatchToProps)(_SuperTasti);

export default SuperTasti;