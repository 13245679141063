import React from 'react';

import { version } from '../../../../package.json';
import './Footer.scss';

const Footer = () => {
    const year = (new Date()).getFullYear();
    return (
        <footer>
            <p>
                Copyright © {year} Lince Italia S.p.A. P.I. 01911871000. All Rights Reserved. <a href={`https://www.lince.net`}>www.lince.net</a>
            </p>
            <p>
                v.{version}
            </p>
        </footer>
    );
};

export default Footer;