import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
    doSocketRequest,
} from '../../../../actions';

import Modal from '../../../elements/Modal';

const mapStateToProps = state => ({
    system: state.system,
    socket: state.socket
});

const mapDispatchToProps = (dispatch) => ({
    doSocketCommand: (socket, trama, noSuccess = false) => dispatch(doSocketRequest(socket, trama, [], true, noSuccess)),
});

const _Comandi = ({ system, doSocketCommand, socket }) => {
    const status = !!system && !!system.store && !!socket ? system.store.status : null;
    if (!status) {
        return (<section></section>);
    }

    var teknox = 0;
    var comandicentrale = null;
    if (!status || !system.store.connected) {
        return (
            <Modal
                title={`LinceCloud -> Impianto`}
                text={`Impianto non connesso`}
                redirect={`/europlus/${system.id}`}
            />
        );
    } else {
        if (!!status.structs) {
            teknox = status.structs.isTeknoxAuthorized.auth_level;
            if (teknox < 2) {
                return (
                    <Modal
                        title={`LinceCloud -> Impianto`}
                        text={`Permessi non sufficenti`}
                        redirect={`/europlus/${system.id}`}
                    />
                );
            }
            comandicentrale = status.structs.comandicentrale;
        } else {
            return (<section></section>);
        }
    }

    const sendCommand = (e, trama) => {
        e.preventDefault();
        doSocketCommand(socket, trama);
    }

    return (
        <section>
            <div className="innerSection">
                <h1>Comandi di Centrale</h1>
                <div className="rack bottomBorder">
                    <div className="w50">
                        <div className="padp">
                            <button className="superButton" style={(!!comandicentrale.ccsi ? { background: "red" } : {})} onClick={e => { sendCommand(e, 0x00F2); }}>Stato di Servizio</button>
                        </div>
                    </div>
                    <div className="w50">
                        <div className="padp">
                            <Link className="superButton" to={`/europlus/${system.id}/rf`} disabled={teknox != 3}>Memorizzazione RF</Link>
                        </div>
                    </div>
                </div>
                <div className="rack bottomBorder">
                    <div className="w50">
                        <div className="padp">
                            <button className="superButton" onClick={e => { sendCommand(e, 0x00F3); }}>Reset delle Memorie</button>
                        </div>
                    </div>
                    <div className="w50">
                        <div className="padp">
                            <button className="superButton" style={(!!comandicentrale.ccvt ? { background: "green" } : {})} onClick={e => { sendCommand(e, 0x00F6); }} disabled={teknox == 2}>Visualizzazione Tamper</button>
                        </div>
                    </div>
                </div>
                <div className="rack bottomBorder">
                    <div className="w50">
                        <div className="padp">
                            <button className="superButton" style={(!!comandicentrale.ccmc ? { background: "green" } : {})} onClick={e => { sendCommand(e, 0x00F4); }}>Memorizzazione Chiavi</button>
                        </div>
                    </div>
                    <div className="w50">
                        <div className="padp">
                            <Link className="superButton" to={`/europlus/${system.id}/new_code`}>Generazione Codice</Link>
                        </div>
                    </div>
                </div>
                <div className="rack bottomBorder">
                    <div className="w50">
                        <div className="padp">
                            <button className="superButton" onClick={e => { sendCommand(e, 0x00F5); }} disabled={teknox == 2}>Acquisizione Periferiche</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rack bottomBorder">
                <div className="w50">
                    <div className="padp">
                        <button className="superButton" onClick={e => { sendCommand(e, 0x00F7); }} disabled={teknox == 2}>Sincronizazzione Centrale - Euronet</button>
                    </div>
                </div>
                <div className="w50">
                    <div className="padp">
                        <button className="superButton" onClick={e => { e.preventDefault(); socket.emit('resetChecksum'); }}>Sincronizazzione Euronet - Cloud</button>
                    </div>
                </div>
            </div>
            {
                /*
                <div className="rack bottomBorder">
                    <div className="w50">
                        <div className="padp">
                            <button className="superButton" onClick={e => { sendCommand(e, 0x00FC); }} disabled={teknox == 2}>Cancellazione totale dispositivi Radio</button>
                        </div>
                    </div>
                    <div className="w50">
                        <div className="padp">
                            <button className="superButton" onClick={e => { sendCommand(e, 0x00FD); }} disabled={teknox == 2}>Cancellazione totale Codici</button>
                        </div>
                    </div>
                </div>
                */
            }
            <p>&nbsp;</p>
        </section>
    );
};

const Comandi = connect(mapStateToProps, mapDispatchToProps)(_Comandi);

export default Comandi;