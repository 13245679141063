import React from 'react';

const StatoFumo = ({ stat }) => {
    return (
        <>
            <div className="rack">
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.supervisione_led ? "red" : "gray" }} /> Supervisione</p>
                </div>
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.fumo ? "red" : "gray" }} /> Fumo</p>
                </div>
            </div>
            <div className="rack">
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.batteria_scarica ? "red" : (stat.batteria_scarica === false ? "yellow" : "gray") }} /> Batteria Scarica</p>
                </div>
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.dormiente ? "red" : "gray" }} /> Dormiente</p>
                </div>
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.guasto ? "red" : "gray" }} /> Guasto</p>
                </div>
            </div>
        </>
    );
};

export default StatoFumo;