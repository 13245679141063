export function stateParser(state) {
    return {
        stato: {
            sabotaggio_centrale: !!(state.stato & 1), 
            sabotaggio_as_esterno: !!(state.stato & 2), 
            memoria_sabotaggio: !!(state.stato & 4),
            memoria_sabotaggio_as: !!(state.stato & 8),
            memoria_allarme_ingressi: !!(state.stato & 16),
            memoria_sabotaggio_ingresso: !!(state.stato & 32),
            allarme_inserito: !!(state.stato & 64),
            servizio: !!(state.stato & 128)
        },
        alim: {
            rete_220_vca: !!(state.alim & 1),
            stato_batteria_interna: !!(state.alim & 2),
            fusibile: !!(state.alim & 4),
            stato_batteria_esterna: !!(state.alim & 8),
            presenza_batteria_interna: !!(state.alim & 16),
            allarme_a: !!(state.alim & 32),
            allarme_k: !!(state.alim & 64),
            allarme_tecnologico: !!(state.alim & 128)
        },
        uscite: {},
        wifi: {},
        conn_type: parseInt(state.gprs) === 2 ? 'signal' : 'wifi',
        vbatt: (parseInt(state.vbatt) / 10).toFixed(1),
        corrente: parseFloat((((state.electricAC_H << 8) & 0xFF00) + (state.electricAC_L & 0xFF)) / 1000.0).toFixed(1),
        prog: {
            g1: !!(state.prog & 1),
            g2: !!(state.prog & 2),
            g3: !!(state.prog & 4)
        },
        prog_active: !!(state.prog & 7),
        programs: state.prog,
        ingr: {
            supervisione_ingressi: !!(state.ingr & 0x10),
            guasto_ingressi_radio: !!(state.ingr & 0x20),
            sabotaggio_ingressi: !!(state.ingr & 0x40)
        },
        bus: {
            tamper_bus: !!(state.bus & 0x08),
            dispositivo_bus_intruso: !!(state.bus & 0x10),
            sabotaggio_hw_bus: !!(state.bus & 0xB0)
        },
        sync: state.sync,
        connesso: state.connesso,
        fw_ver: state.fw_ver.replace(/^0+/, ''),
        raw: state.raw
    };
}

export function checkStatoImpianto(state) {
    return !!(state.alim & 0x7A) || !!(state.stato & 0x3F) || !!(state.wifi & 0x04) || !!(state.bus & 0xB8);
}

export function checkZoneAperte(state, activation_prog = null) {
    var status = false;
    var block = false;
    var string_impedimenti = [];
    var programs = activation_prog !== null ? activation_prog : state.programs;
    if(!!!state.raw) {
        return [true, "Problema di comunicazione con il Cloud", false];
    }
    if ((state.raw[4] & 0x01) != 0) {
      status = true;
      string_impedimenti.push("Rete 220 Vca");
    }
    if ((state.raw[4] & 0x04) != 0) {
        status = true;
        string_impedimenti.push("Fusibile");
    }
    if ((state.raw[9] & 0x01) != 0) {
        if (!!(programs & 0x01)) {
            status = true;
            string_impedimenti.push("G1 aperto");
        }
    }
    if ((state.raw[9] & 0x02) != 0) {
        if (!!(programs & 0x02)) {
            status = true;
            string_impedimenti.push("G2 aperto");
        }
    }
    if ((state.raw[9] & 0x04) != 0) {
        if (!!(programs & 0x04)) {
            status = true;
            string_impedimenti.push("G3 aperto");
        }
    }
    if ((state.raw[9] & 0x10) != 0) {
        status = true;
        block = true;
        string_impedimenti.push("Supervisione ingressi");
    }
    if ((state.raw[9] & 0x20) != 0) {
        status = true;
        string_impedimenti.push("Guasto ingressi");
    }
    if ((state.raw[9] & 0x40) != 0) {
        status = true;
        string_impedimenti.push("Sabotaggio ingressi");
    }
    if ((state.raw[10] & 0x10) != 0) {
        status = true;
        string_impedimenti.push("Dispositivo BUS intruso");
    }
    if ((state.raw[10] & 0x20) != 0) {
        status = true;
        string_impedimenti.push("Sabotaggio HW BUS");
    }
    if ((state.raw[10] & 0x40) != 0) {
        status = true;
        string_impedimenti.push("Tamper BUS");
    }

    return [status, string_impedimenti.join(", "), block];
}