import React from 'react';
import { Link } from 'react-router-dom';

import { checkStatoImpianto } from '../../../lib/goldParser/stateParser';

const { compose, withProps, withHandlers, withStateHandlers, lifecycle } = require("recompose");
const {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    InfoWindow
} = require("react-google-maps");
const { MarkerClusterer } = require("react-google-maps/lib/components/addons/MarkerClusterer");
const { MarkerWithLabel } = require("react-google-maps/lib/components/addons/MarkerWithLabel");


const Map = compose(
    withStateHandlers(() => ({
        isOpen: false,
        selectedMarker: []
    }), {
        onToggleOpen: ({ isOpen }) => () => ({
            isOpen: !isOpen,
        }),
        onSelectedMarker: () => marker => {
            const markers = Array.isArray(marker) ? marker : [marker];
            return ({
                selectedMarker: markers
            });
        }
    }),
    withProps({
        googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_MAP}&v=3.exp&libraries=geometry,drawing,places`,
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    withHandlers({
        onMarkerClustererClick: props => markerClusterer => {
            const currentZoom = markerClusterer.getMap().getZoom();
            if(currentZoom === 22) {
                props.onSelectedMarker(markerClusterer.getMarkers().map(x => props.markers[parseInt(x.getTitle())]));
                props.onToggleOpen(true);
            }        
        },
    }),
    lifecycle({
        componentWillMount() {
            this.setState({    
                zoomToMarkers: map => {
                    if(!!map && !!map.props) {
                        const bounds = new window.google.maps.LatLngBounds();
                        map.props.xmarkers.map(marker => bounds.extend(new window.google.maps.LatLng(marker.latitude, marker.longitude)));
                        map.fitBounds(bounds);
                    }
                }
            });
        }
    }),
    withScriptjs,
    withGoogleMap
)(props => {
    return <GoogleMap
        ref={props.zoomToMarkers}
        defaultZoom={5}
        defaultCenter={{ lat: 41.0391667, lng: 12.525 }}
        xmarkers={props.markers}
    >
        <MarkerClusterer
            onClick={props.onMarkerClustererClick}
            averageCenter
            enableRetinaIcons
            gridSize={60}
        >
            {props.isOpen &&
                <InfoWindow onCloseClick={props.onToggleOpen}
                    position={{ lat: props.selectedMarker[0].latitude, lng: props.selectedMarker[0].longitude }}
                >
                    <div>
                        {props.selectedMarker.map((selMarker, idx) => (
                            <div className="infowindow rack" key={`infowindow_${idx}`}>
                                <h3>
                                    {selMarker.name} {` `}
                                    {!!selMarker.goldstate ? (
                                        <>
                                            <i className="fa fa-cloud" style={{ color: (selMarker.connesso ? '#50D926' : '#F00') }} />{` `}
                                            <i className={`fa ${(selMarker.goldstate.gprs === 2 ? `fa-signal` : `fa-wifi`)}`} title={selMarker.connesso ? `connesso ${(selMarker.goldstate.gprs === 2 ? `GPRS` : `WiFi`)}` : "non connesso" } style={{ color: (selMarker.connesso ? '#50D926' : '#F00') }} />{` `}
                                            {!!(selMarker.goldstate.prog & 0x80)
                                                ? <img src={`/static/images/icons/lock.png`} className="icon xs-icon" title={`autenticazione`} />
                                                : <></>
                                            }
                                            {` `}
                                            {!!selMarker.state
                                                ? <img src={`/static/images/icons/${selMarker.state}.png`} className="icon xs-icon" title={selMarker.state} />
                                                : <></>
                                            }
                                            {` `}
                                            {selMarker.profiles.includes('proprietario')
                                                ? !!(selMarker.goldstate.prog & 0x07)
                                                    ? <i className={`fa fa-lock`} style={{ color: '#F00' }} title="impianto inserito" />
                                                    : <i className={`fa fa-unlock-alt`} style={{ color: '#50D926' }} title="impianto disinserito" />
                                            : <></>
                                        }
                                        </>
                                    ) : (
                                        <>
                                            <i className="fa fa-cloud" style={{ color: (selMarker.connesso ? '#50D926' : '#F00') }} />{` `}
                                        </>
                                    )}
                                </h3>
                                <div className="w33">
                                    <img alt="Foto impianto" src={(selMarker.pic ? `${process.env.REACT_APP_API_URL}/download/${selMarker.pic}` : `/static/images/model/${selMarker.modello}.png`)} />
                                </div>
                                <div className="w66">
                                    <div className="rack">
                                        <p>{selMarker.address}</p>
                                        <p>Modello: {selMarker.model || <em>in attesa collegamento</em>}</p>
                                        <p>Stato: {selMarker.state || <em>non disponibile</em>}</p>
                                        <p>Ruolo: {selMarker.profiles}</p>
                                    </div>
                                    <div className="rack iconBar">
                                        {selMarker.valid === 1 &&
                                            <>
                                                <Link title={`Pannello di controllo`} onClick={e => { if(!selMarker.connesso) { e.preventDefault(); } }} to={`/${(selMarker.brand === 'lince-europlus' ? 'europlus' : 'gold')}/${selMarker.id}`}>
                                                    <img src='/static/images/icons/control.png' className="icon xs-icon" style={{ opacity: (selMarker.connesso ? '1' : '.3' )}} />
                                                </Link>
                                                {
                                                    (selMarker.profiles.includes('proprietario') || selMarker.profiles.includes('manutentore')) && (
                                                        <Link title={`Modifica impianto`} to={`/edit-system/${selMarker.id}`}>
                                                            <i className="fa fa-pencil" />
                                                        </Link>
                                                    )
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>                    
                        ))}
                    </div>
                </InfoWindow>
            }
            {props.markers.map((marker, index) => {
                return (
                    <MarkerWithLabel
                        key={index}
                        title={`${index}`}
                        icon={`/static/images/marker-${marker.brand || 'europlus'}.png`}
                        position={{ lat: marker.latitude, lng: marker.longitude }}
                        labelAnchor={new window.google.maps.Point(40, 0)}
                        labelClass={`marker-label`}
                        onClick={() => { props.onSelectedMarker(marker); props.onToggleOpen(true); }}
                    >
                        <div data-marker={index}>
                            <i className={`fa fa-circle`} style={{ color: (marker.connesso ? '#50D926' : '#F00') }} /> {marker.name}
                        </div>
                    </MarkerWithLabel>
                )
            })}
        </MarkerClusterer>
    </GoogleMap>
});

export default Map;