import React, { useState } from 'react';

import './Outcome.scss';

const Outcome = ({ type, text = '', onClose = null }) => {
  const [close, setClose] = useState(false);

  const renderClose = () => {
    return onClose
      ? (
        <span className="close"
          onClick={() => {
            setClose(true);
            onClose(null);
          }}
        >
          <i className="fa fa-close" />
        </span>
      ) : <></>
  };

  return (
    close
      ? <></>
      : text.length === 0
        ? <div className={`outcome`}>{renderClose()}</div>
        : <div className={`outcome ${type}`}>{renderClose()}{text}</div>
  );
};

export default Outcome;
