import React from 'react';
import ReactSlider from 'react-slider';

const Sirena = ({ current, auth, setTempoIngresso }) => {
    return (
        <>
            <div className="rack bottomBorder">
                <div className="w25">
                    <p>Volume</p>
                    <ReactSlider
                        defaultValue={current.tempo_ingresso}
                        onChange={value => { setTempoIngresso(value); }}
                        disabled={auth !== 2}
                        max={100}
                        min={25}
                        step={5}
                        className="horizontal-slider"
                        thumbClassName="slider-thumb"
                        trackClassName="slider-track"
                        renderThumb={(props, state) => {
                            return (<div {...props}>{`${state.valueNow}%`}</div>);
                        }}
                    />
                </div>
                <div className="w25">
                    <label>Tipo Suono</label>
                    <select defaultValue={current.tipo_suono} name="tipo_suono" disabled={auth !== 2}>
                        <option value={0}>B</option>
                        <option value={0x40}>A</option>
                    </select>
                </div>
            </div>
            <div className="rack bottomBorder">
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="supervisione" name="supervisione" defaultValue="0" defaultChecked={current.supervisione} disabled={auth !== 2} />
                        <label htmlFor={`supervisione`}>
                            <span>
                                <span />
                            </span>
                            Supervisione
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="led_on" name="led_on" defaultValue="0" defaultChecked={current.led_on} disabled={auth !== 2} />
                        <label htmlFor={`led_on`}>
                            <span>
                                <span />
                            </span>
                            LED ON
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="a" name="allarme_a" defaultValue="0" defaultChecked={current.allarme_a} disabled={auth !== 2} />
                        <label htmlFor={`a`}>
                            <span>
                                <span />
                            </span>
                            A
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="k" name="allarme_k" defaultValue="0" defaultChecked={current.allarme_k} disabled={auth !== 2} />
                        <label htmlFor={`k`}>
                            <span>
                                <span />
                            </span>
                            K
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="tamper_locale" name="tamper" defaultValue="0" defaultChecked={current.tamper} disabled={auth !== 2} />
                        <label htmlFor={`tamper_locale`}>
                            <span>
                                <span />
                            </span>
                            Tamper Locale
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="funzioni_avanzate" name="sabotaggio" defaultValue="0" defaultChecked={current.sabotaggio} disabled={auth !== 2} />
                        <label htmlFor={`funzioni_avanzate`}>
                            <span>
                                <span />
                            </span>
                            Funzioni Avanzate
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="beep" name="ring" defaultValue="0" defaultChecked={current.ring} disabled={auth !== 2} />
                        <label htmlFor={`beep`}>
                            <span>
                                <span />
                            </span>
                            Beep
                        </label>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Sirena;