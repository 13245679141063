import React from 'react';

import './Modal.scss';

const Popup = ({ title, onClose, onConfirm = null, onConfirmLabel = 'OK', children }) => {
    return (
        <div className="dialog">
            <div className="content wide">
                <h3>{title}</h3>
                {children}
                <form>
                    <fieldset>
                        {!!onConfirm && (
                            <button className="ok" onClick={e => { e.preventDefault(); onConfirm(); }}>{onConfirmLabel}</button>
                        )}
                        {onClose && (
                            <button className="ok" onClick={e => { e.preventDefault(); onClose(); }}>{!!onConfirm ? `Annulla` : `Chiudi`}</button>
                        )}
                    </fieldset>
                </form>
            </div>
        </div>
    );
};

export default Popup;
