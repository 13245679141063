import React, { useEffect, useState } from 'react';
import ReactSlider from 'react-slider';
import { USCITE } from '../../../../../constants/gold';

const UscitaRadio = ({ current, auth, setMw, setTempoIngresso, setTempoUscita, setAntintrusione, system }) => {
    const _commutazione = current.tempo_uscita + (current.tempo_ingresso << 8);
    const ingressi = [];
    const _espansioni = [];
    for (let i = 0; i < system.store.pm.bus.length; i++) {
        if(system.store.pm.bus[i].num_tipo_periferica === 8) {
            _espansioni.push(system.store.pm.bus[i]);
        }
    }
    for (let i = 0; i < system.store.pm.filari.length; i++) {
        const offset = system.model < 5 ? 2 : 5;
        const _i = i-offset;
        const expansion = i < offset ? false : (_espansioni[Math.floor(_i / 5)] || null);
        const line = expansion ? (_i % 5) + 1 : null;
        ingressi.push({
            idx: i,
            group: Math.floor(i / 16),
            expansion,
            line,
            ...system.store.pm.filari[i],
            nome: expansion === null ? 'Non Disponibile' : system.store.pm.filari[i].nome 
        });
    }

    const [abilita, setAbilita] = useState(current.abilita_ingressi && !current.attributi);
    const [attributi, setAttributi] = useState(current.attributi);

    const [commutazione, setCommutazione] = useState(_commutazione >= 0xFFFF ? 0 :  _commutazione);
    const [impulsivo, setImpulsivo] = useState(commutazione === 0);  

    useEffect(() => {
        if(impulsivo) {
            setTempoIngresso(0);
            setTempoUscita(0);
            setCommutazione(0);
        } else {
            setTempoUscita(commutazione & 0xFF);
            setTempoIngresso((commutazione >> 8) & 0x0F);
        }
        return () => null;
    }, [impulsivo]);

    return (
        <>
            <div className="rack">
                <div className="w25">
                    <p>Durata Commutazione</p>
                    <ReactSlider
                        value={commutazione}
                        disabled={impulsivo || auth !== 2}
                        onChange={value => { 
                            setCommutazione(parseInt(value))
                        }}
                        max={4095}
                        min={0}
                        className="horizontal-slider"
                        thumbClassName="slider-thumb"
                        trackClassName="slider-track"
                        renderThumb={(props, state) => {
                            const [hrs, min] = [
                                Math.floor(parseInt(state.valueNow) / 60),
                                Math.floor((parseInt(state.valueNow) % 60)),
                            ];
                            return (<div {...props}>{`${hrs} ore ${min} min`}</div>);
                        }}
                    />
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="impulsivo" name="impulsivo" disabled={auth !== 2} defaultValue="0" defaultChecked={impulsivo} onChange={e => { setImpulsivo(!!e.target.checked); }} />
                        <label htmlFor={`impulsivo`}>
                            <span>
                                <span />
                            </span>
                            Impulsivo
                            </label>
                    </div>
                </div>
            </div>
            <div className="rack bottomBorder">
            <div className="w25">
                    <label>Associazione a Ingresso</label>
                    <select defaultValue={current.mw} disabled={abilita || attributi} onChange={e => { setMw(e.target.value) }}>
                        {ingressi.map((x,i) => {
                            return x.expansion !== null
                            ? <option key={`filari_${i}`} value={i}>{`Filare ${i+1}: ${x.nome}`}</option>
                            : <React.Fragment key={`filari_${i}`} />
                        })}
                        {system.store.pm.radio.map((x,i) => {
                            if(!!x.num_tipo_periferica && (x.num_tipo_periferica === 2 || x.num_tipo_periferica === 3)) {
                                return <option key={`radio_${i}`} value={(i | 0x80)}>{`Radio ${i+1}: ${x.nome}`}</option>
                            } else {
                                <React.Fragment key={`fragment_${i}`} />
                            }
                        })}
                    </select>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="attributi" name="attributi" disabled={auth !== 2} defaultValue="0" defaultChecked={current.attributi}
                        onChange={
                            e => { 
                                setAttributi(!!e.target.checked); 
                                if(!!e.target.checked) { 
                                    setMw(0);
                                    setAntintrusione(current.antintrusione);
                                    setAbilita(false);
                                }
                            }
                        } />
                        <label htmlFor={`attributi`}>
                            <span>
                                <span />
                            </span>
                            Attributi
                            </label>
                    </div>
                </div>
                <div className="w50">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="abilita" name="abilita" defaultValue="0" checked={abilita && !attributi} 
                            disabled={attributi}
                            onChange={
                                e => { 
                                    setAbilita(e.target.checked);
                                    if(!!e.target.checked) { 
                                        setMw(0); 
                                    } else {
                                        setMw(current.mw);
                                    }
                                }
                            } 
                        />
                        <label htmlFor={`abilita`}>
                            <span>
                                <span />
                            </span>
                            Abilita per tutti gli ingressi
                            </label>
                    </div>
                </div>
            </div>
            <div className="rack bottomBorder">
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="supervisione" name="supervisione" defaultValue="0" defaultChecked={current.supervisione} disabled={auth !== 2} />
                        <label htmlFor={`supervisione`}>
                            <span>
                                <span />
                            </span>
                            Supervisione
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="led_on" name="led_on" defaultValue="0" defaultChecked={current.led_on} disabled={auth !== 2} />
                        <label htmlFor={`led_on`}>
                            <span>
                                <span />
                            </span>
                            LED ON
                        </label>
                    </div>
                </div>
                <div className="w50">
                    {attributi
                        ? (
                            <div className="switchButton">
                                <input type="checkbox" className="switch" id="abilita2" name="abilita_impianto_attivo" defaultValue="0" checked={0} disabled={true} />
                                <label htmlFor={`abilita2`}>
                                    <span>
                                        <span />
                                    </span>
                                    Abilita a impianto attivato
                                </label>
                            </div>    
                        ) : (
                            <div className="switchButton">
                                <input type="checkbox" className="switch" id="abilita2" name="abilita_impianto_attivo" defaultValue="0" defaultChecked={current.abilita_impianto_attivo} />
                                <label htmlFor={`abilita2`}>
                                    <span>
                                        <span />
                                    </span>
                                    Abilita a impianto attivato
                                </label>
                            </div>    
                        )                    
                    }
                </div>
                {abilita && !attributi
                    ? (
                        <div className="w25">
                            <div className="switchButton">
                                <input type="checkbox" className="switch" id="and" name="abilita_and_mode" defaultValue="0" defaultChecked={current.abilita_and_mode} disabled={auth !== 2} />
                                <label htmlFor={`and`}>
                                    <span>
                                        <span />
                                    </span>
                                    AND
                                </label>
                            </div>
                        </div>    
                    ) 
                    : (
                        <div className="w25">
                            <div className="switchButton">
                                <input type="checkbox" className="switch" id="and" name="abilita_and_mode" defaultValue="0" checked={false} disabled={true} />
                                <label htmlFor={`and`}>
                                    <span>
                                        <span />
                                    </span>
                                    AND
                                </label>
                            </div>
                        </div>    
                    )
                }
                <div className="w25">
                    <label>Logica</label>
                    <select name="nc" defaultValue={current.nc} disabled={auth !== 2}>
                        <option value={0}>NA</option>
                        <option value={32}>NC</option>
                    </select>
                </div>
            </div>
            <div className="rack borderBottom">
                {abilita
                ? (
                    <>
                        <div className="w25">
                            <div className="switchButton">
                                <input type="checkbox" className="switch" id="a" name="g1" defaultValue="0" defaultChecked={current.g1} disabled={auth !== 2}
                                />
                                <label htmlFor={`a`}>
                                    <span>
                                        <span />
                                    </span>
                                    A
                                </label>
                            </div>
                        </div>
                        <div className="w25">
                            <div className="switchButton">
                                <input type="checkbox" className="switch" id="k" name="ritardato" defaultValue="0" defaultChecked={current.ritardato} disabled={auth !== 2} />
                                <label htmlFor={`k`}>
                                    <span>
                                        <span />
                                    </span>
                                    K
                                </label>
                            </div>
                        </div>
                        <div className="w25">
                            <div className="switchButton">
                                <input type="checkbox" className="switch" id="fuoco" name="g2" defaultValue="0" defaultChecked={current.g2} disabled={auth !== 2} />
                                <label htmlFor={`fuoco`}>
                                    <span>
                                        <span />
                                    </span>
                                    Fuoco
                                </label>
                            </div>
                        </div>
                        <div className="w25">
                            <div className="switchButton">
                                <input type="checkbox" className="switch" id="campanello" name="a" defaultValue="0" defaultChecked={current.a} disabled={auth !== 2} />
                                <label htmlFor={`campanello`}>
                                    <span>
                                        <span />
                                    </span>
                                    Campanello
                                </label>
                            </div>
                        </div>
                        <div className="w33">
                            <div className="switchButton">
                                <input type="checkbox" className="switch" id="elettroserratura" name="k" defaultValue="0" defaultChecked={current.k} disabled={auth !== 2} />
                                <label htmlFor={`elettroserratura`}>
                                    <span>
                                        <span />
                                    </span>
                                    Elettroserratura
                                </label>
                            </div>
                        </div>
                        <div className="w25">
                            <div className="switchButton">
                                <input type="checkbox" className="switch" id="silenzioso" name="g3" defaultValue="0" defaultChecked={current.g3} disabled={auth !== 2} />
                                <label htmlFor={`silenzioso`}>
                                    <span>
                                        <span />
                                    </span>
                                    Silenzioso
                                </label>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="w25">
                            <div className="switchButton">
                                <input type="checkbox" className="switch" id="a" name="g1" defaultValue="0" checked={0} disabled={true} />
                                <label htmlFor={`a`}>
                                    <span>
                                        <span />
                                    </span>
                                    A
                                </label>
                            </div>
                        </div>
                        <div className="w25">
                            <div className="switchButton">
                                <input type="checkbox" className="switch" id="k" name="ritardato" defaultValue="0" checked={0} disabled={true} />
                                <label htmlFor={`k`}>
                                    <span>
                                        <span />
                                    </span>
                                    K
                                </label>
                            </div>
                        </div>
                        <div className="w25">
                            <div className="switchButton">
                                <input type="checkbox" className="switch" id="fuoco" name="g2" checked={0} disabled={true} />
                                <label htmlFor={`fuoco`}>
                                    <span>
                                        <span />
                                    </span>
                                    Fuoco
                                </label>
                            </div>
                        </div>
                        <div className="w25">
                            <div className="switchButton">
                                <input type="checkbox" className="switch" id="campanello" name="a" defaultValue="0" checked={0} disabled={true} />
                                <label htmlFor={`campanello`}>
                                    <span>
                                        <span />
                                    </span>
                                    Campanello
                                </label>
                            </div>
                        </div>
                        <div className="w33">
                            <div className="switchButton">
                                <input type="checkbox" className="switch" id="elettroserratura" name="k" defaultValue="0" checked={0} disabled={true} />
                                <label htmlFor={`elettroserratura`}>
                                    <span>
                                        <span />
                                    </span>
                                    Elettroserratura
                                </label>
                            </div>
                        </div>
                        <div className="w33">
                            <div className="switchButton">
                                <input type="checkbox" className="switch" id="silenzioso" name="g3" defaultValue="0" checked={0} disabled={true} />
                                <label htmlFor={`silenzioso`}>
                                    <span>
                                        <span />
                                    </span>
                                    Silenzioso
                                </label>
                            </div>
                        </div>
                    </>
                )}
                {attributi 
                    ? (
                        <div className="w25">
                            <label>Attributi</label>
                            <select defaultValue={current.antintrusione & 0x7F} onChange={e => { setAntintrusione(parseInt(e.target.value)); }}>
                                {USCITE.map((el,idx) => <option key={`attributiOUT_${idx}`} value={idx}>{el}</option> )}
                            </select>
                        </div>    
                    ) : (
                        <div className="w25">
                            <label>Attributi</label>
                            <select value={0} disabled={true}>
                                <option value={0}>Nessuno</option>
                            </select>
                        </div>    
                    )
                }
            </div>
        </>
    );
};

export default UscitaRadio;