import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';

import Modal from '../../../elements/Modal';
import { clone } from '../../../../lib/clone';
import { j2bSupertasti } from '../../../../lib/goldParser/converter';

import {
    goldDoRequest
} from '../../../../actions';

const mapStateToProps = state => ({
    data: state.data,
    system: state.system
});

const mapDispatchToProps = (dispatch) => ({
    goldWriteSuperButtons: payload => dispatch(goldDoRequest('goldWriteSuperButtons', payload))
});

const _SuperTasti = ({ data, system, goldWriteSuperButtons }) => {
    const _system = clone(system);
    const codeForm = useRef(null);
    const [current, setCurrent] = useState(!!_system.store ? _system.store.pm.supertasti : null);
    const [processed, setProcessed] = useState(null);
    const [success, setSuccess] = useState(null);

    if (!!!system.store || !!!system.store.auth || !(system.store.auth & 6)) {
        return (
            <Modal
                title={`LinceCloud -> Impianto`}
                text={`Permessi insufficenti`}
                redirect={`/gold/${system.id}`}
            />
        );
    }

    if (!!!current) {
        return <>caricamento in corso...</>;
    }

    const makePayload = () => {
        const form = codeForm.current;
        return {
            supertasto1: form.supertasto1.checked,
            supertasto2: form.supertasto2.checked,
            supertasto3: form.supertasto3.checked,
            supertasto4: form.supertasto4.checked
        };
    };

    const handleReset = e => { 
        e.preventDefault();
        setCurrent(null);
        setTimeout(() => setCurrent(_system.store.pm.supertasti), 100); 
    };

    const handleSubmit = e => {
        e.preventDefault();
        const payload = makePayload();
        goldWriteSuperButtons({
            id_centrale: system.id_centrale,
            edata: j2bSupertasti(payload)
        });
        setProcessed(true);
    };

    if(data && processed) {
        setProcessed(null);
        setSuccess(!!(data.status === 'OK'));
    }

    return (
        <section>
            {success === true && <Modal title={`Impianto -> LinceCloud`} text={`Comando inviato con successo`} onClose={() => { setSuccess(null); }}/> }
            {success === false && <Modal title={`Impianto -> LinceCloud`} text={`Si é verificato un errore`} onClose={() => { setSuccess(null); }}/> }
            <div className="innerSection">
                <h1>Supertasti</h1>
                <div className="rack">
                    <div className="w100 formSection">
                        <form ref={codeForm}>
                            <fieldset>
                                <div className="rack">
                                    <div className="w50">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="supertasto1" name="supertasto1" defaultValue="0" defaultChecked={current.supertasto1} onChange={() => null} />
                                            <label htmlFor={`supertasto1`}>
                                                <span>
                                                    <span />
                                                </span>
                                                Supertasto 1 - Attiva uscita relè
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w50">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="supertasto2" name="supertasto2" defaultValue="0" defaultChecked={current.supertasto2} onChange={() => null} />
                                            <label htmlFor={`supertasto2`}>
                                                <span>
                                                    <span />
                                                </span>
                                                Supertasto 2 - Attiva uscita OUT 1
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w50">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="supertasto3" name="supertasto3" defaultValue="0" defaultChecked={current.supertasto3} onChange={() => null} />
                                            <label htmlFor={`supertasto3`}>
                                                <span>
                                                    <span />
                                                </span>
                                                Supertasto 3 - Attiva uscita relè + uscita OUT 1
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w50">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="supertasto4" name="supertasto4" defaultValue="0" defaultChecked={current.supertasto4} onChange={() => null} />
                                            <label htmlFor={`supertasto4`}>
                                                <span>
                                                    <span />
                                                </span>
                                                Supertasto 4 - Inserimento totale impianto
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset>
                                <div className="rack" style={{ marginTop: "4em" }}>
                                    <div className="w100 right">
                                        <button className="ok auto spaced" onClick={handleReset}>Annulla</button>
                                        <button className="ok auto spaced" onClick={handleSubmit} disabled={(system.store.auth === 2 && system.store.state.prog_active)}>Applica</button>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const SuperTasti = connect(mapStateToProps, mapDispatchToProps)(_SuperTasti);

export default SuperTasti;