import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import Modal from '../../../elements/Modal';
import LoaderTimed from '../../../elements/Loaders/timed';
import { clone } from '../../../../lib/clone';
import { j2bChiave } from '../../../../lib/goldParser/converter';
import { b2jChiave } from '../../../../lib/goldParser/physicalMap';

import {
    goldDoRequest,
    setStore
} from '../../../../actions';
import { goldExitSystem, sleep } from '../../../../api/Cloud';

const mapStateToProps = state => ({
    system: state.system,
    socket: state.socket,
    data: state.queue_data,
    errors: state.queue_errors
});

const mapDispatchToProps = (dispatch) => ({
    goldAcquirePeripheral: payload => dispatch(goldDoRequest('goldAcquirePeripheral', payload)),
    goldGetCode: (requests, payloads) => dispatch(goldDoRequest(requests, payloads, false)),
    goldWriteCode: (requests, payloads) => dispatch(goldDoRequest(requests, payloads, true)),
    setStore: data => dispatch(setStore(data)),
});

const states = [
    "In attesa dell'acquisizione della chiave",
    'Chiave esistente',
    'Acquisizione chiave in corso',
    "In attesa dell'acquisizione della chiave",
];

const _Chiave = ({ system, socket, data, errors, goldAcquirePeripheral, goldGetCode, goldWriteCode, setStore }) => {
    const busForm = useRef(null);
    const codeForm = useRef(null);
    const _system = clone(system);
    const [on, setOn] = useState(false);
    const [rfState, setRfState] = useState(null);
    const [ndisp, setNdisp] = useState(null);
    const [processed2, setProcessed2] = useState(false);
    const [processed3, setProcessed3] = useState(false);
    const [processed4, setProcessed4] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [success, setSuccess] = useState(null);
    const [current, setCurrent] = useState(null);

    const umount = useCallback(async () => {
        await goldExitSystem({id_centrale: system.id_centrale }, true);
        socket.off('onGoldRfState');
        await sleep(1500);
        setStore({ polling_pause: false });
    }, []);

    useEffect(
        () => {
            socket.on('onGoldRfState', response => {
                setRfState(response.state);
                if(response.state == 2) {
                    setNdisp(parseInt(response.data));
                } else if(response.state < 0) {
                    response.state = 3;
                    setOn(false);
                }
            });
            return async () => {
                await umount();
                return true;
            }
        },
        [umount]
    );

    useEffect(() => {
        if(ndisp != null) {
            let requests = [
                'goldGetCode'
            ];
            let payloads = [
                {
                    id_centrale: system.id_centrale,   
                    idx: ndisp
                }
            ];
            goldGetCode(requests, payloads);
            setProcessed3(true);    
        }
    }, [ndisp])

    const start = e => {
        e.preventDefault();
        setButtonDisabled(true);
        setOn(true);
        setNdisp(null);
        setCurrent(null);
        setStore({ polling_pause: true });
        setTimeout(() => {
            goldAcquirePeripheral({
                id_centrale: system.id_centrale,
                type: 'chiave'
            });
            setButtonDisabled(false);
        }, 6000);
    }

    const exit = async () => {
        await goldExitSystem({id_centrale: system.id_centrale }, true);
        setStore({ polling_pause: false });
    }

    const stop = async e => {
        e.preventDefault();
        setButtonDisabled(true);
        await exit();
        setOn(false);
        setRfState(null);
        setProcessed2(null);
        setButtonDisabled(false);
    }

    if (!!!system.store || !!!system.store.auth || system.store.auth !== 4) {
        return (
            <Modal
                title={`LinceCloud -> Impianto`}
                text={`Permessi insufficenti`}
                redirect={`/gold/${system.id}`}
            />
        );
    }

    const makePayload = () => {
        const form = codeForm.current;
        return {
            tipo_codice: 128,
            codice: form.codice.value,
            nome: form.nome.value,
            g1: form.g1.checked,
            g2: form.g2.checked,
            g3: form.g3.checked,
            elettroserratura: form.elettroserratura.checked,
            ronda: form.ronda.checked,
            silenzioso: form.silenzioso.checked,
            antipanico: form.antipanico.checked,
            tipo_utente: 1
        };
    };

    const handleSubmit = e => {
        e.preventDefault();
        const payload = makePayload();
        const requests = [
            'goldWriteCode',
        ];
        const payloads = [
            {
                id_centrale: system.id_centrale,
                idx: ndisp,
                edata: j2bChiave(payload),
                code: true
            },
        ];
        setCurrent(payload);
        goldWriteCode(requests, payloads); 
        setProcessed4(true);
    }

    if(data && processed2) {
        setProcessed2(null);
        setSuccess(!!data && !errors);
    }

    if(data && processed3) {
        setProcessed3(null);
        const _current = b2jChiave(data[0].data);
        setCurrent(_current);
    }

    if(data && processed4) {
        setProcessed4(null);
        const _success = (!!data && !errors) ? 2 : false;
        if(_success === 2) {
            let pm = clone(_system.store.pm);
            pm.codici[ndisp] = { ...pm.codici[ndisp], ...current }; 
            setStore({
                pm
            });
        }
        setSuccess(_success);
    }

    return (
        <section>
            {success === true && <Modal title={`Impianto -> LinceCloud`} text={`Comando inviato con successo`} onClose={() => { setSuccess(null); }}/> }
            {success === 2 && <Modal title={`Impianto -> LinceCloud`} text={`Chiave acquisita con successo`} onClose={() => { setSuccess(3); }}/> }
            {success === 3 && <LoaderTimed url={`/gold/${system.id}`} time={25000} />}
            {success === false && <Modal title={`Impianto -> LinceCloud`} text={`Si é verificato un errore`} onClose={() => { setSuccess(null); exit(); }}/> }
            <div className="innerSection">
                <div className="rack">
                    <div className="w100 formSection">
                        {current
                            ? (
                                <form ref={codeForm} className="stackableW33">
                                    <h1>Parametri</h1>
                                    <fieldset>
                                        <input type="hidden" name="codice" defaultValue={current.codice} />
                                        <div className="rack">
                                            <div className="w25">
                                                <label>Nome</label>
                                                <input type="text" name="nome" defaultValue={current.nome} maxLength={16} />
                                            </div>
                                            <div className="w25">
                                                <label>Privilegi</label>
                                                <select defaultValue={1} name="tipo_utente">
                                                    <option value={1}>Utente</option>
                                                </select>
                                            </div>
                                            <div className="w25">
                                                <label>Tipologia</label>
                                                <select defaultValue={128} name="tipo_codice" disabled={true}>
                                                    <option value={128}>Chiave</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="rack">
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="elettroserratura" name="elettroserratura" defaultChecked={current.elettroserratura} defaultValue="0" />
                                                    <label htmlFor={`elettroserratura`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        Elettroserratura
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="antipanico" name="antipanico" defaultChecked={current.antipanico} defaultValue="0" />
                                                    <label htmlFor={`antipanico`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        Antipanico
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="ronda" name="ronda" defaultChecked={current.ronda} defaultValue="0" />
                                                    <label htmlFor={`ronda`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        Ronda
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="silenzioso" name="silenzioso" defaultChecked={current.silenzioso} defaultValue="0" />
                                                    <label htmlFor={`silenzioso`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        Silenzioso
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <h1>Associazioni a programmi</h1>
                                    <fieldset>
                                        <div className="rack">
                                            <h4>Programmi</h4>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="program_label_g1" name="g1" defaultChecked={current.g1} defaultValue="0" />
                                                    <label htmlFor={`program_label_g1`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        {system.g1}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="program_label_g2" name="g2" defaultChecked={current.g2} defaultValue="0" />
                                                    <label htmlFor={`program_label_g2`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        {system.g2}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="program_label_g3" name="g3" defaultChecked={current.g3} defaultValue="0" />
                                                    <label htmlFor={`program_label_g3`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        {system.g3}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="rack" style={{ marginTop: "4em" }}>
                                            <div className="w100 right">
                                                <button className="ok auto spaced" onClick={handleSubmit}>Applica</button>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            ) : (
                                <form ref={busForm}>
                                    <h1>Memorizzazione Chiave</h1>
                                    <fieldset>
                                        <br />
                                        <div className="rack">
                                            <div className="w50">
                                                {on
                                                    ? (
                                                        <button className="yellowButton" style={{ margin: '1.5em', width: '120px' }} disabled={buttonDisabled} onClick={stop}>Interrompi</button>
                                                    )
                                                    : (
                                                        <button className="yellowButton" style={{ margin: '1.5em', width: '120px' }} disabled={buttonDisabled} onClick={start}>Avvia</button>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="rack">
                                            <div className="w100">
                                                {on && (<p className="loadingText">Ricerca dispositivo in corso</p>)}
                                            </div>
                                            <div className="w100">
                                                {rfState != null && (<p>{(states[rfState] || ``)}</p>)}
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            )
                        }
                        </div>
                    </div>
                </div>
            <p>&nbsp;</p>
        </section >
    );
};

const Chiave = connect(mapStateToProps, mapDispatchToProps)(_Chiave);

export default Chiave;