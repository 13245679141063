import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { addUrlProps, UrlQueryParamTypes } from 'react-url-query';

import { doRequest } from '../../../actions';
import { errorRead } from '../../../actions';
import Error from '../../elements/Error';
import SchemaValidator from '../../../lib/SchemaValidator';

const urlPropsQueryConfig = {
    t: { type: UrlQueryParamTypes.string },
}

const mapDispatchToProps = (dispatch) => ({
    doRequest: payload => dispatch(doRequest('passwordReset', payload)),
    errorRead: () => dispatch(errorRead()),
    getPasswordToken: token => dispatch(doRequest('getPasswordToken', token))
});

const mapStateToProps = state => ({
    data: state.data,
    stateError: state.error
});

const _PasswordReset = ({ data, errorRead, doRequest, getPasswordToken, stateError, t }) => {
    const [canReset, setCanReset] = useState(false);
    const [firstLoad, setFirstLoad] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [loadedData, setLoadedData] = useState(false);

    const [processed, setProcessed] = useState(false);
    const [success, setSuccess] = useState(false);

    const [payload, setPayload] = useState(
        {
            password: "",
        }
    );

    const [errors, setErrors] = useState("");

    useEffect(
        () => {
            if (t) {
                if (!loadedData && !firstLoad) {
                    getPasswordToken(t);
                    setTimeout(() => setLoadedData(true), 500);
                    setFirstLoad(true);
                } else {
                    setFirstLoad(true);
                    setLoaded(true);
                }
            }
            if (!t || loaded) {
                if (stateError) {
                    setErrors(stateError);
                    errorRead();
                }
            }
            return () => null;
        },
        [errorRead, errors, firstLoad, getPasswordToken, loaded, loadedData, setErrors, setFirstLoad, setLoadedData, stateError, t],
    );

    const handleChange = (e) => {
        let _payload = payload;
        _payload[e.target.name] = e.target.value;
        setPayload({
            ...payload,
            ..._payload,
        });
    };

    const handlePasswordReset = (e) => {
        e.preventDefault();
        setErrors("");
        let v = SchemaValidator(payload, 'passwordreset');
        if (payload.password !== payload.confirm_password) {
            setErrors(`Le password non coincidono`);
        } else if (v.errors.length > 0) {
            setErrors(v.errors.map(el => el.schema.message).join("\n"));
        } else {
            if (canReset) {
                payload.token = t;
            }
            setProcessed(true);
            doRequest(payload);
        }
    }

    if (data && loadedData) {
        setCanReset(true);
        setLoaded(true);
        setLoadedData(false);
    }

    if (data && processed) {
        setProcessed(false);
        setSuccess(true);
    }

    return (
        <section>
            <div>
                <img className="mainLogo" src="/static/images/testalince.png" alt="Lince" title="Lince Clouding" />
            </div>
            <div>
                <form name="passwordreset">
                    {
                        success
                            ?
                            <div style={{ textAlign: "left" }}>
                                <p>
                                    <strong>Password cambiata con successo!</strong>
                                </p>
                                <p>
                                    La password é stata cambiata, procedere al <Link to={'/'}>Login</Link>.
                                </p>
                            </div>
                            :
                            canReset
                                ?
                                <div style={{ textAlign: "left" }}>
                                    <fieldset>
                                        <p>
                                            <strong>Inserire una nuova password</strong>
                                        </p>
                                        <p>
                                            <input type="password" placeholder="Nuova password" name="password" required onChange={handleChange} />
                                            <br />
                                            <small>minimo 8 caratteri di cui almeno 1 speciale (!@#$%^&*)</small>
                                        </p>
                                        <p>
                                            <input type="password" placeholder="Confermare password" name="confirm_password" onChange={handleChange} />
                                        </p>
                                        <br />
                                        <p>
                                            <button className="ok" onClick={handlePasswordReset}>Reimposta</button>
                                        </p>
                                    </fieldset>
                                    <Error text={errors} />
                                </div>
                                :
                                <p>Pagina non disponibile</p>
                    }
                </form>
            </div>
        </section>
    );
};

const PasswordReset = addUrlProps({ urlPropsQueryConfig })(connect(mapStateToProps, mapDispatchToProps)(_PasswordReset));

export default PasswordReset;