import React, { useState, useEffect } from 'react';
import FileBase64 from 'react-file-base64';
import DatePicker, { registerLocale } from "react-datepicker";
import it from 'date-fns/locale/it';
import "react-datepicker/dist/react-datepicker.css";

import Geocoder from '../../elements/Geocoder';

registerLocale('it', it);

const User = ({ user, handleChange, handleSubmit, showNewsletter = true, lockEmail = true, saveButton = 'Salva', cancelButton = null, handleCancel, handlerGeodata = null, address = ``, picture = false }) => {

    const [photoError, setPhotError] = useState(null);
    const pic = user.pic ? `${process.env.REACT_APP_API_URL}/download/${user.pic}` : null;
    const [photoURL, setPhotoURL] = useState(pic);
    const [selectedDate, setSelectedDate] = useState(null);

    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 14);
    var startDate = maxDate;

    useEffect(
        () => {
            if (!!user.birth_date) {
                let [year, month, day] = user.birth_date.split("-");
                setSelectedDate(new Date(year, month - 1, day));
            }
            return () => null;
        },
        [user, setSelectedDate]
    );


    const onSubmit = (e) => {
        e.preventDefault();
        if (picture) {
            if (photoURL && photoURL != pic) {
                const base64 = (photoURL.split(','))[1];
                handleChange({
                    target: { type: 'input', name: 'pic', value: base64 }
                });
            } else if (photoURL == null) {
                handleChange({
                    target: { type: 'input', name: 'pic', value: null }
                });
            }
        }
        handleSubmit(e);
    }

    return (
        <div className="formSection">
            <form>
                <fieldset>
                    <h3>Dati</h3>
                    <div className="rack">
                        {
                            lockEmail
                                ? (
                                    <div className="w33">
                                        <label>Email</label>
                                        <input type="email" disabled={true} defaultValue={user.email} />
                                    </div>

                                ) :
                                (
                                    <div className="w33">
                                        <label>Email*</label>
                                        <input type="email" defaultValue={user.email || ``} onChange={handleChange} />
                                    </div>
                                )
                        }
                        <div className="w33">
                            <label>Nome*</label>
                            <input type="text" name="name" defaultValue={user.name || ``} onChange={handleChange} maxLength={30} />
                        </div>
                        <div className="w33">
                            <label>Cognome*</label>
                            <input type="text" name="lastname" defaultValue={user.lastname || ``} onChange={handleChange} maxLength={30} />
                        </div>
                    </div>
                    <div className="rack">
                        <div className="w33">
                            <label>Data di nascita*</label>
                            <DatePicker
                                dateFormat={`dd/MM/yyyy`}
                                locale="it"
                                maxDate={maxDate}
                                startDate={startDate}
                                onChange={e => { handleChange(e); }}
                                onSelect={e => { handleChange(e); setSelectedDate(e); }}
                                selected={selectedDate}
                                showYearDropdown={true}
                                placeholderText={`inserire la data di nascita, formato gg/mm/aaaa`}
                            />
                        </div>
                        <div className="w33">
                            <label>Codice Fiscale*</label>
                            <input type="text" name="fiscal_code" defaultValue={user.fiscal_code || ``} onChange={handleChange} />
                        </div>
                        <div className="w33">
                            <label>Partita IVA</label>
                            <input type="text" name="vat" defaultValue={user.vat || ``} onChange={handleChange} />
                        </div>
                        <div className="rack">
                            <div className="w33">
                                <label>Azienda di appartenenza</label>
                                <input type="text" name="company" defaultValue={user.company || ``} onChange={handleChange} maxLength={30} />
                            </div>
                        </div>
                    </div>
                    {handlerGeodata && (
                        <>
                            <h3>Indirizzo</h3>
                            <div className="rack">
                                <div className="w100">
                                    <Geocoder
                                        handlerGeodata={handlerGeodata}
                                        initialAddress={address}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    <h3>Recapiti</h3>
                    <div className="rack">
                        <div className="w33">
                            <label>Telefono</label>
                            <input type="text" name="phone" defaultValue={user.phone || ``} onChange={handleChange} maxLength={16} />
                            <small>Inserire un numero massimo di 16 cifre</small>
                        </div>
                        <div className="w33">
                            <label>Cellulare</label>
                            <input type="text" name="mobile" defaultValue={user.mobile || ``} onChange={handleChange} maxLength={16} />
                            <small>Inserire un numero massimo di 16 cifre</small>
                        </div>
                        <div className="w33">
                            <label>Fax</label>
                            <input type="text" name="fax" defaultValue={user.fax || ``} onChange={handleChange} maxLength={16} />
                            <small>Inserire un numero massimo di 16 cifre</small>
                        </div>
                        <div className="w33">
                            <label>Email secondaria</label>
                            <input type="text" name="email2" defaultValue={user.email2 || ``} onChange={handleChange} />
                        </div>
                        {showNewsletter && (
                            <div className="w33">
                                <br />
                                <input type="checkbox" id="newsletter" name="newsletter" defaultValue="1" defaultChecked={user.newsletter || 0} onChange={handleChange} />
                                <label htmlFor={`newsletter`}>
                                    <span />
                                    Accettazione newsletter
                                </label>
                            </div>
                        )}
                    </div>
                    <div className="rack">
                        <h3>Carica foto</h3>
                        <div className="w75">
                            <label>File</label>
                            <FileBase64 onDone={(img) => {
                                if (img.type != "image/jpeg") {
                                    setPhotError(`Formato non valido`);
                                } else if (parseInt(img.size) > 10240) {
                                    setPhotError(`Immagine troppo pesante`);
                                } else {
                                    setPhotError(null);
                                    setPhotoURL(img.base64);
                                }
                            }} />
                            <br /><small>caricare file in formato JPEG e non superiori a 10 MB</small>
                        </div>
                        <div className="w25">
                            {photoError &&
                                <p style={{ color: 'red' }}><em>{photoError}</em></p>
                            }
                            {!!photoURL
                                ?
                                (
                                    <>
                                        <img className="preview" src={photoURL} alt="preview" />
                                        <p>
                                            <button className="ok auto" onClick={e => { e.preventDefault(); setPhotoURL(null); }}>Elimina</button>
                                        </p>
                                    </>
                                )
                                :
                                (
                                    <p><em>nessuna foto caricata</em></p>
                                )
                            }
                        </div>
                    </div>
                    <div className="rack buttonPanel">
                        <div className="w50 left">
                            <p>&nbsp;</p>
                            {
                                cancelButton && (<button className="ok auto" onClick={handleCancel}>{cancelButton}</button>)
                            }
                        </div>
                        <div className="w50 right">
                            {
                                saveButton && (
                                    <>
                                        <p><small>I campi contrassegnati con (*) sono obbligatori</small></p>
                                        <button className="ok auto" onClick={onSubmit}>{saveButton}</button>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    );
};

export default User;