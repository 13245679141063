import { int2bcd, stringToArrayInt } from '../../lib/bytesFunctions';

export const j2bAutoIns = obj => {
    return [
        int2bcd(Math.floor(parseInt(obj.totale.tempo) / 60)) | (obj.totale.abilitato ? 0x80 : 0),
        int2bcd(parseInt(obj.totale.tempo) % 60),
        int2bcd(Math.floor(parseInt(obj.g1.tempo) / 60)) | (obj.g1.abilitato ? 0x80 : 0),
        int2bcd(parseInt(obj.g1.tempo) % 60),
        int2bcd(Math.floor(parseInt(obj.g2.tempo) / 60)) | (obj.g2.abilitato ? 0x80 : 0),
        int2bcd(parseInt(obj.g2.tempo) % 60),
        int2bcd(Math.floor(parseInt(obj.g3.tempo) / 60)) | (obj.g3.abilitato ? 0x80 : 0),
        int2bcd(parseInt(obj.g3.tempo) % 60),
    ];
};

export const j2bBus = (obj, edit) => {
    const arr = [];
    if(!edit) {
        arr.push((obj.identificativo & 0xFF));        
        arr.push(((obj.identificativo >> 8) & 0xFF));
        arr.push(parseInt(obj.num_tipo_periferica));        
        arr.push(parseInt(obj.espansione));        
    }
    return [
        ...arr,
        ...stringToArrayInt(16, obj.nome)
    ];
};

export const j2bCodice = (obj, code = null) => {
    const arr = [];
    if(code) {
        const key = code.split('').map(x => x == 0 ? 0x0A : parseInt(x));
        arr.push(key[0] | (key[1] << 4));
        arr.push(key[2] | (key[3] << 4));
        arr.push(key[4] | (key[5] << 4));
    }
    let int = 0;
    int |= !!obj.g1 ? 1 : 0;
    int |= !!obj.g2 ? 2 : 0;
    int |= !!obj.g3 ? 4 : 0;
    int |= !!obj.elettroserratura ? 16 : 0;
    int |= !!obj.ronda ? 32 : 0;
    int |= !!obj.silenzioso ? 64 : 0;
    int |= !!obj.antipanico ? 128 : 0;
    arr.push(int);
    int = 0;
    int |= parseInt(obj.tipo_utente);
    int |= !!obj.tipo_codice ? 128 : 0;
    arr.push(int);
    return [...arr, 0, 0, 0, ...stringToArrayInt(16, obj.nome)];
}

export const j2bChiave = (obj) => {
    const codice = parseInt(obj.codice);
    const arr = [
        codice & 0xFF,
        (codice >> 8) & 0xFF,
        (codice >> 16) & 0xFF
    ];
    let int = 0;
    int |= !!obj.g1 ? 1 : 0;
    int |= !!obj.g2 ? 2 : 0;
    int |= !!obj.g3 ? 4 : 0;
    int |= !!obj.elettroserratura ? 16 : 0;
    int |= !!obj.ronda ? 32 : 0;
    int |= !!obj.silenzioso ? 64 : 0;
    int |= !!obj.antipanico ? 128 : 0;
    arr.push(int);
    int = 0;
    int |= parseInt(obj.tipo_utente);
    int |= !!obj.tipo_codice ? 128 : 0;
    arr.push(int);
    return [...arr, 0, 0, 0, ...stringToArrayInt(16, obj.nome)];
}

export const j2bFilare = obj => {
    const arr = [];
    let int = 0;
    int |= !!obj.test ? 1 : 0;
    int |= !!obj.escluso ? 2 : 0;
    int |= !!obj.ronda ? 4 : 0;
    int |= !!obj.fuoco ? 8 : 0;
    int |= !!obj.campanello ? 16 : 0;
    int |= !!obj.silenzioso ? 32 : 0;
    int |= !!obj.elettroserratura ? 64 : 0;
    int |= !!obj.parzializzabile ? 128 : 0;
    arr.push(int);
    int = 0;
    int |= !!obj.g1 ? 1 : 0;
    int |= !!obj.g2 ? 2 : 0;
    int |= !!obj.g3 ? 4 : 0;
    int |= !!obj.a ? 8 : 0;
    int |= !!obj.k ? 16 : 0;
    int |= !!obj.ritardato ? 32 : 0;
    int |= !!obj.percorso ? 64 : 0;
    int |= !!obj.sempre_attivo ? 128 : 0;
    arr.push(int);
    int = 0;
    int |= obj.tempo_trigger;
    int |= obj.tipologia_ingresso;
    int |= !!obj.doppio_impulso ? 8 : 0;
    arr.push(int);
    arr.push(parseInt(obj.tempo_ingresso));
    arr.push(parseInt(obj.tempo_uscita));
    arr.push(parseInt(obj.cicli_autoesclusione));
    return [...arr, 0, 0, ...stringToArrayInt(16, obj.nome)]
}

export const j2bGsm = obj => {
    let int = 0;
    int |= !!obj.opzioni.accesso_telegestione ? 1 : 0;
    int |= !!obj.opzioni.visualizzazione_chiamate ? 2 : 0;
    int |= !!obj.opzioni.on ? 4 : 0;
    int |= !!obj.opzioni.gestione_credito ? 16 : 0;
    int |= !!obj.opzioni.gestione_disturbo ? 32 : 0;
  
    const [day1, hrs1, min1] = [
        int2bcd(Math.floor(parseInt(obj.esistenza_in_vita) / (24*60))),
        int2bcd(Math.floor((parseInt(obj.esistenza_in_vita) % (24*60)) / 60)),
        int2bcd((parseInt(obj.esistenza_in_vita) % (24*60)) % 60)
    ];

    return [
        int,
        parseInt(obj.numero_tentativi_chiamate),
        parseInt(obj.numero_squilli_risposta),
        hrs1,
        min1,
        day1,
        int2bcd(obj.scadenza_sim.getDate()),
        int2bcd((obj.scadenza_sim.getMonth() + 1)),
        int2bcd(obj.scadenza_sim.getFullYear() - 2000),
        parseInt(obj.giorni_scadenza_sim),
        parseInt(obj.giorni_credito_minimo),
        parseInt(obj.credito_minimo),
    ];
};

export const j2bOpzioni = obj => {
    const arr = [];
    let int = 0;
    int |= !!obj.toni_ins ? 1 : 0;
    int |= !!obj.toni_ingr ? 2 : 0;
    int |= !!obj.led_on ? 4 : 0;
    int |= !!obj.autoreset ? 8 : 0;
    int |= !!obj.rit_no_rete ? 16 : 0;
    int |= !!obj.all_falsa_chiave ? 32 : 0;
    int |= !!obj.chiave_base ? 64 : 0;
    int |= !!obj.buzzer ? 128 : 0;
    arr.push(int);
    int = 0;
    int |= !!obj.abil_campanello ? 1 : 0;
    int |= !!obj.abil_asterisco ? 2 : 0;
    int |= !!obj.des ? 4 : 0;
    int |= !!obj.inversione ? 8 : 0;
    int |= !!obj.antimask ? 16 : 0;
    int |= !!obj.supervisione ? 32 : 0;
    arr.push(int);
    return arr;
};

export const j2bSupertasti = obj => {
    let int = 0;
    int |= !!obj.supertasto1 ? 2 : 0; 
    int |= !!obj.supertasto2 ? 4 : 0; 
    int |= !!obj.supertasto3 ? 8 : 0; 
    int |= !!obj.supertasto4 ? 16 : 0;
    return [int]; 
};

export const j2bTel = obj => {
    const numero = stringToArrayInt(16, obj.numero);
    const nome = stringToArrayInt(16, obj.nome);
    const opzioni = []
    let int = 0;
    int |= parseInt(obj.ripetizioni) & 15;
    int |= !!obj.sms_credito_scadenza ? 16 : 0;
    int |= !!obj.abilitazione ? 32 : 0; 
    opzioni.push(int);
    int = 0;
    int |= !!obj.vocale_a ? 1 : 0;    
    int |= !!obj.vocale_k ? 2 : 0;    
    int |= !!obj.vocale_sabotaggio ? 4 : 0;    
    int |= !!obj.vocale_silenzioso ? 8 : 0;    
    int |= !!obj.sms_a ? 16 : 0;    
    int |= !!obj.sms_k ? 32 : 0;    
    int |= !!obj.sms_sabotaggio ? 64 : 0;    
    int |= !!obj.sms_silenzioso ? 128 : 0;    
    opzioni.push(int);
    int = 0;
    int |= !!obj.sms_batteria_centrale_carica ? 1 : 0;    
    int |= !!obj.sms_batteria_radio_carica ? 2 : 0;    
    int |= !!obj.sms_rete_elettrica_assente ? 4 : 0;    
    int |= !!obj.sms_rete_elettrica_ripristinata ? 8 : 0;    
    int |= !!obj.sms_variazione_programmi ? 16 : 0;    
    int |= !!obj.sms_accesso_sistema ? 32 : 0;    
    opzioni.push(int);
    int = 0;
    int |= !!obj.squillo_esistenza_vita ? 1 : 0;    
    int |= !!obj.sms_esistenza_vita ? 2 : 0;    
    int |= !!obj.squillo_conferma_uscite ? 4 : 0;    
    int |= !!obj.sms_conferma_uscite ? 8 : 0;    
    int |= !!obj.vocale_conferma_uscite ? 16 : 0;    
    int |= !!obj.apri_cancello_na ? 32 : 0;    
    int |= !!obj.apri_cancello_out1 ? 64 : 0;    
    int |= !!obj.impulsato ? 128 : 0;    
    opzioni.push(int);
    opzioni.push(parseInt(obj.durata_impulso));
    return [...numero, ...opzioni, ...nome];
};

export const j2bTempi = obj => {
    return [
        ((parseInt(obj.allarme) >> 8) & 0xFF),
        (parseInt(obj.allarme) & 0xFF), 
        parseInt(obj.fuoco),
        parseInt(obj.silenzioso) * 2,
        parseInt(obj.campanello) * 2,
        parseInt(obj.elettroserratura) * 2,
        ((parseInt(obj.ronda) >> 8) & 0xFF),
        (parseInt(obj.ronda) & 0xFF),
    ];
};

export const j2bUscite = data => {
    const j2bUscita = obj => parseInt(obj.attributo) + parseInt((obj.polarita === 'normale' ? 0 : 128));
    return [
        j2bUscita(data.uscita4),
        j2bUscita(data.uscita0),
        j2bUscita(data.uscita1),
        j2bUscita(data.uscita2),
        j2bUscita(data.uscita3)
    ];
};

export const j2bRadio = obj => {
    const arr = [];
    let int = 0;
    int |= !!obj.test ? 1 : 0;
    int |= !!obj.escluso ? 2 : 0;
    int |= !!obj.ronda ? 4 : 0;
    int |= !!obj.fuoco ? 8 : 0;
    int |= !!obj.campanello ? 16 : 0;
    int |= !!obj.silenzioso ? 32 : 0;
    int |= !!obj.elettroserratura ? 64 : 0;
    int |= !!obj.parzializzabile ? 128 : 0;
    arr.push(int);
    int = 0;
    if(obj.num_tipo_periferica == 5 && obj.antintrusione != null) { // uscita
        int = parseInt(obj.antintrusione) & 0x7F;
    } else {
        int |= !!obj.g1 ? 1 : 0;
        int |= !!obj.g2 ? 2 : 0;
        int |= !!obj.g3 ? 4 : 0;
        int |= !!obj.a ? 8 : 0;
        int |= !!obj.k ? 16 : 0;
        int |= !!obj.ritardato ? 32 : 0;
        int |= !!obj.percorso ? 64 : 0;
        int |= !!obj.sempre_attivo ? 128 : 0;    
    }
    arr.push(int);
    int = 0;
    switch(obj.num_tipo_periferica) {
        case 1: //'radiocomando'
            break;
        case 2:  // 'movimento':
            int |= !!obj.supervisione ? 1 : 0;
            int |= !!obj.led_on ? 2 : 0;
            int |= parseInt(obj.logica) == 0 ? 0 : parseInt(obj.cd_cs);
            int |= parseInt(obj.logica);  
            int |= parseInt(obj.conf);  
            break;
        case 3: //'contatto':
            if(obj.num_spec_periferica === 0) { // contatto
                int |= !!obj.supervisione ? 1 : 0;
                int |= !!obj.led_on ? 2 : 0;
                int |= parseInt(obj.reed) === 0 ? 4 : 0;
                int |= ((parseInt(obj.associazione) << 4) & 0x30);
                let aux = parseInt(obj.aux);
                int |= aux >= 2 ? ((aux-1)*64) : aux === 0 ? 8 : 0;    
            } else if(obj.num_spec_periferica === 1) { // tapparella
                int |= !!obj.supervisione ? 1 : 0;
                int |= !!obj.led_on ? 2 : 0;
                int |= parseInt(obj.impulsi);
            }
            break;
        case 4: //'sirena':
            int |= !!obj.supervisione ? 1 : 0;
            int |= !!obj.led_on ? 2 : 0;
            int |= !!obj.allarme_a ? 4 : 0;
            int |= !!obj.ring ? 8 : 0;
            int |= !!obj.sabotaggio ? 16 : 0;
            int |= !!obj.tamper ? 32 : 0;
            int |= parseInt(obj.tipo_suono);
            int |= !!obj.allarme_k ? 128 : 0;
            break;
        case 5: // uscita
            int |= !!obj.supervisione ? 1 : 0;
            int |= !!obj.led_on ? 2 : 0;
            int |= !!obj.abilita_impianto_attivo ? 4 : 0;
            int |= !!obj.abilita_ingressi ? 8 : 0;
            int |= !!obj.abilita_and_mode ? 16 : 0;
            int |= (parseInt(obj.nc) === 32) ? 32 : 0;
            int |= !!obj.attributi ? 128 : 0;    
            break;
        case 6: // 'tecnologico'
            if(parseInt(obj.num_spec_periferica) & 3 !== 0) {
                int |= !!obj.supervisione ? 1 : 0;
                int |= !!obj.led_on ? 2 : 0;
            }
            break;
        case 7: //'ripetitore'
            break;
        case 8: // nebbiogeno
            int |= !!obj.supervisione ? 1 : 0;
            int |= !!obj.led_on ? 2 : 0;
            int |= !!obj.abilita_ingressi ? 8 : 0;
            int |= !!obj.and ? 16 : 0;
            int |= !!obj.antipanico ? 32 : 0;
            break;
    }
    arr.push(int);
    return [
        ...arr,
        parseInt(obj.tempo_ingresso),
        parseInt(obj.tempo_uscita),
        parseInt(obj.cicli_autoesclusione),
        (parseInt(obj.indirizzo_periferica) & 0xFF),
        (parseInt(obj.indirizzo_periferica) >> 8),
        parseInt(obj.num_tipo_periferica),
        parseInt(obj.num_spec_periferica),
        parseInt(obj.mw),
        parseInt(obj.pir1),
        parseInt(obj.pir2),
        parseInt(obj.am),
        ...stringToArrayInt(16, obj.nome)
    ]
}