import React, { useState } from 'react';

const Clock = () => {
    const now = () => (new Date()).toLocaleDateString('it-IT', { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' });
    const [clock, setClock] = useState(now());
    setTimeout(() => { setClock(now()); }, 1000);
    return <div id="clock">{clock}</div>;
};

export default Clock;
