import get_bits from './bytesFunctions';

export default function europlusParser(payload_string) {
    const keys = [
        "modello_centrale",
        "generali_1",
        "generali_2",
        "generali_3",
        "pag0_impedimenti_1",
        "pag0_impedimenti_2",
        "generali_4",
        "generali_5",
        "vbatt_L",
        "vbatt_H",
        "filari_esclusioni_0",
        "filari_esclusioni_1",
        "filari_esclusioni_2",
        "filari_esclusioni_3",
        "filari_esclusioni_4",
        "filari_oi_0",
        "filari_oi_1",
        "filari_oi_2",
        "filari_oi_3",
        "filari_oi_4",
        "filari_memorie_0",
        "filari_memorie_1",
        "filari_memorie_2",
        "filari_memorie_3",
        "filari_memorie_4",
        "filari_oi24_0",
        "filari_oi24_1",
        "filari_oi24_2",
        "filari_oi24_3",
        "filari_oi24_4",
        "filari_memorie24_0",
        "filari_memorie24_1",
        "filari_memorie24_2",
        "filari_memorie24_3",
        "filari_memorie24_4",
        "minuti",
        "ore",
        "giorno",
        "mese",
        "anno",
        "secondi",
        "nome_giorno",
        "idtastiera_L",
        "idtastiera_H",
        "pag9_impedimento_1",
        "pag9_impedimento_2",
        "pag9_impedimento_3",
        "pag9_impedimento_4",
        "pag9_impedimento_5",
        "firmware_ver_L",
        "firmware_ver_H",
        "pag10_impedimento1_1",
        "pag10_impedimento2_2",
        "pag10_impedimento3_3",
        "pag10_impedimento4_4",
        "pag10_impedimento5_5",
        "pag11_impedimento11_1",
        "pag11_impedimento11_2",
        "pag11_impedimento11_3",
        "pag11_impedimento11_4",
        "pag11_impedimento11_5",
        "attivazione",
        "oi_radio_0",
        "oi_radio_1",
        "oi_radio_2",
        "oi_radio_3",
        "oi_radio_4",
        "oi_radio_5",
        "oi_radio_6",
        "oi_radio_7",
        "as_radio_0",
        "as_radio_1",
        "as_radio_2",
        "as_radio_3",
        "as_radio_4",
        "as_radio_5",
        "as_radio_6",
        "as_radio_7",
        "mem_oi_radio_0",
        "mem_oi_radio_1",
        "mem_oi_radio_2",
        "mem_oi_radio_3",
        "mem_oi_radio_4",
        "mem_oi_radio_5",
        "mem_oi_radio_6",
        "mem_oi_radio_7",
        "mem_as_radio_0",
        "mem_as_radio_1",
        "mem_as_radio_2",
        "mem_as_radio_3",
        "mem_as_radio_4",
        "mem_as_radio_5",
        "mem_as_radio_6",
        "mem_as_radio_7",
        "lo_batt_radio_0",
        "lo_batt_radio_1",
        "lo_batt_radio_2",
        "lo_batt_radio_3",
        "lo_batt_radio_4",
        "lo_batt_radio_5",
        "lo_batt_radio_6",
        "lo_batt_radio_7",
        "supervisioni_radio_0",
        "supervisioni_radio_1",
        "supervisioni_radio_2",
        "supervisioni_radio_3",
        "supervisioni_radio_4",
        "supervisioni_radio_5",
        "supervisioni_radio_6",
        "supervisioni_radio_7",
        "isTeknoxAuthorized",
        "comandicentrale",
        "celsius_H",
        "celsius_L",
        "vbus_H",
        "vbus_L",
        "checksum1",
        "checksum2",
        "espansioni",
        "submodellocentrale",
        "nchiavi",
        "nradio"
    ];

    const data = {};
    const structs = {};

    if (!!!payload_string) {
        for (let i = 0; i < keys.length; i++) {
            data[keys[i]] = 0;
        }
    } else {
        const payload = payload_string.split(',').map(x => parseInt(x));
        for (let i in payload) {
            if (keys[i] != null && payload[i] != null) {
                data[keys[i]] = payload[i];
            }
        }
    }
    data['celsius'] = (((((data['celsius_H'] << 8) + data['celsius_L']) - 2000) / 12)).toFixed(0);
    data['vbus'] = ((((data['vbus_H'] << 8) + data['vbus_L']) / 183)).toFixed(2);
    data['vbatt'] = ((((data['vbatt_H'] << 8) + data['vbatt_L']) / 46.4)).toFixed(1);

    for (let key in data) {
        let byte = data[key];
        switch (key) {
            case 'generali_1':
                structs[key] = {};
                structs[key]['rete'] = get_bits(byte); // rete 220V
                structs[key]['batteria_in'] = get_bits(byte, 1);
                structs[key]['allarme'] = get_bits(byte, 2);
                structs[key]['servizio'] = get_bits(byte, 3);
                structs[key]['guasto'] = get_bits(byte, 4);
                structs[key]['batteria_ex'] = get_bits(byte, 5); // batteria esterna
                structs[key]['as24_in'] = get_bits(byte, 6); // tamper_int Sabotaggio Centrale
                structs[key]['as'] = get_bits(byte, 7); // Sabotaggio Allarme Ingresso
                break;
            case 'generali_2':
                structs[key] = {};
                structs[key]['mem_as24_in'] = get_bits(byte); // Memoria Sabotaggio Centrale
                structs[key]['mem_as_in'] = get_bits(byte, 1); // Memoria Sabotaggio Allarme Ingresso
                structs[key]['mem_24_inseritori'] = get_bits(byte, 2); // Memoria Sabotaggio Dispositivi su BUS
                structs[key]['mem_bus'] = get_bits(byte, 3); // Memoria Allarme integrità BUS
                structs[key]['status'] = get_bits(byte, 4, 4);
                break;
            case 'generali_3':
                structs[key] = {};
                structs[key]['attivo_g1'] = get_bits(byte);
                structs[key]['attivo_g2'] = get_bits(byte, 1);
                structs[key]['attivo_g3'] = get_bits(byte, 2);
                structs[key]['attivo_gext'] = get_bits(byte, 3);
                structs[key]['as24_remoto'] = get_bits(byte, 4); //  Sabotaggio Dispositivi su BUS
                structs[key]['bus'] = get_bits(byte, 5); // Allarme Integrità BUS
                structs[key]['mem_chiavefalsa'] = get_bits(byte, 6);
                structs[key]['mem_24_attivazione'] = get_bits(byte, 7);
                break;
            case 'generali_4':
                structs[key] = {};
                structs[key]['ingressi_esclusi'] = get_bits(byte); // Ingressi Esclusi
                structs[key]['ingressi_aperti'] = get_bits(byte, 1); // Ingressi Aperti
                structs[key]['as24'] = get_bits(byte, 2); //  Sabotaggio Ingressi
                structs[key]['silenzioso'] = get_bits(byte, 3);
                structs[key]['tempo_in_g1g2g3'] = get_bits(byte, 4);
                structs[key]['led_on'] = get_bits(byte, 5);
                structs[key]['tempo_out_g1g2g3'] = get_bits(byte, 6);
                structs[key]['mem_as24_allarme'] = get_bits(byte, 7); // Memoria Sabotaggio Ingressi
                break;
            case 'generali_5':
                structs[key] = {};
                structs[key]['chiave_base'] = get_bits(byte);
                structs[key]['red_on'] = get_bits(byte, 1);
                structs[key]['at_on'] = get_bits(byte, 2);
                structs[key]['pronto'] = get_bits(byte, 3);
                structs[key]['fusibile_vsen'] = get_bits(byte, 4);
                structs[key]['pin_servizio'] = get_bits(byte, 5);
                structs[key]['tempo_in_gext'] = get_bits(byte, 6);
                structs[key]['tempo_out_gext'] = get_bits(byte, 7);
                break;
            case 'pag0_impedimenti_1':
                structs[key] = {};
                structs[key]['g1'] = get_bits(byte);
                structs[key]['g2'] = get_bits(byte, 1);
                structs[key]['g3'] = get_bits(byte, 2);
                structs[key]['gext'] = get_bits(byte, 3);
                structs[key]['g1_esclusi'] = get_bits(byte, 4);
                structs[key]['g2_esclusi'] = get_bits(byte, 5);
                structs[key]['g3_esclusi'] = get_bits(byte, 6);
                structs[key]['gext_esclusi'] = get_bits(byte, 7);
                break;
            case 'pag0_impedimenti_2':
                structs[key] = {};
                structs[key]['blocco_g1'] = get_bits(byte);
                structs[key]['blocco_g2'] = get_bits(byte, 1);
                structs[key]['blocco_g3'] = get_bits(byte, 2);
                structs[key]['blocco_gext'] = get_bits(byte, 3);
                structs[key]['stato_na'] = get_bits(byte, 4);
                structs[key]['eeprom_radio_ok'] = get_bits(byte, 5);
                structs[key]['manomissione'] = get_bits(byte, 6);
                break;
            case 'espansioni':
                structs[key] = {};
                structs[key]['presente0'] = get_bits(byte);
                structs[key]['presente1'] = get_bits(byte, 1);
                structs[key]['presente2'] = get_bits(byte, 2);
                structs[key]['presente3'] = get_bits(byte, 3);
                structs[key]['presente4'] = get_bits(byte, 4);
                structs[key]['tastiera_radio'] = get_bits(byte, 5);
                structs[key]['conflitto'] = get_bits(byte, 6);
                if (structs[key]['tastiera_radio'] === 1) {
                    structs[key]['presente4'] = 0; // se Espansione radio é on mettere a off Espansione 5
                }
                break;
            case 'attivazione':
                structs[key] = {};
                structs[key]['go1'] = get_bits(byte);
                structs[key]['go2'] = get_bits(byte, 1);
                structs[key]['go3'] = get_bits(byte, 2);
                structs[key]['goext'] = get_bits(byte, 3);
                break;
            case 'isTeknoxAuthorized':
                structs[key] = {};
                structs[key]['auth_level'] = get_bits(byte, 0, 2);
                structs[key]['g1'] = get_bits(byte, 2);
                structs[key]['g2'] = get_bits(byte, 3);
                structs[key]['g3'] = get_bits(byte, 4);
                structs[key]['gext'] = get_bits(byte, 5);
                structs[key]['authorized'] = get_bits(byte, 7);
                break;
            case 'comandicentrale':
                structs[key] = {};
                structs[key]['ccsi'] = get_bits(byte); // stato impianto
                structs[key]['ccvt'] = get_bits(byte, 1); // visualizzazione tamper
                structs[key]['ccmc'] = get_bits(byte, 2); // memorizzazione chiavi
                structs[key]['sync_euronet_cloud'] = get_bits(byte, 3); // sync centrale -> cloud vBuffer[123] i |= 8, aggiunta nell'ultimo fw
                break;
            default:
        }
    }
    data.structs = structs;
    return data;
}