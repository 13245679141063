import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import { stringToArrayInt } from '../../../../lib/bytesFunctions';

import Modal from '../../../elements/Modal';
import Outcome from '../../../elements/Outcome';

import {
    doSocketRequest,
    setStore
} from '../../../../actions';

const mapStateToProps = state => ({
    system: state.system,
    socket: state.socket,
});

const mapDispatchToProps = (dispatch) => ({
    doSocketVariation: (socket, trama, payload, noSuccess = false, noError = false, redirect = null) => dispatch(doSocketRequest(socket, trama, payload, true, noSuccess, noError, redirect)),
    setStore: data => dispatch(setStore(data))
});

const _Codice = ({ system, socket, doSocketVariation, setStore }) => {
    const codeForm = useRef(null);
    const [error, setError] = useState(null);
    const [firstAvailable, setFirstAvailable] = useState(null);
    const [firstLoad, setFirstLoad] = useState(true);
    const [redir, setRedir] = useState(null);
    const [codeTeknox, setCodeTeknox] = useState(0);
    const status = !!system && !!socket ? system.store.status : null;

    useEffect(
        () => {
            if (firstLoad && !!system.store) {
                setFirstLoad(false);
                for (let i = 0x0063; i <= 0x00E2; i++) {
                    let _x = system.store[i].split(',').map(x => parseInt(x));
                    let _sum = _x.reduce((sum, x) => sum + x)
                    if (!_sum || _x[4] == 0xFF) {
                        setFirstAvailable(i);
                        return () => null;
                    }
                }
                setRedir(true);
            }
            return () => null;
        },
        [firstLoad, setFirstLoad, system, setFirstAvailable, setRedir]
    );

    var teknox = 0;
    if (!status || !system.store.connected) {
        return (
            <Modal
                title={`LinceCloud -> Impianto`}
                text={`Impianto non connesso`}
                redirect={`/europlus/${system.id}`}
            />
        );
    } else {
        if (!!status.structs) {
            teknox = status.structs.isTeknoxAuthorized.auth_level;
            if (teknox < 2) {
                return (
                    <Modal
                        title={`LinceCloud -> Impianto`}
                        text={`Permessi non sufficenti`}
                        redirect={`/europlus/${system.id}`}
                    />
                );
            }
        } else {
            return (<section></section>);
        }
    }

    if (redir) {
        return (
            <Modal
                title={`Errore`}
                text={`Massimo numero di codici raggiunto`}
                redirect={`/europlus/${system.id}`}
            />
        );
    }

    const makePayload = () => {
        const form = codeForm.current;
        const payload = [];
        const codice = ([...form['codice'].value.split('').map(x => x == '0' ? 10 : parseInt(x)), ...new Array(6).fill(0)].slice(0, 6));
        payload.push((codice[1] << 4) + codice[0]);
        payload.push((codice[3] << 4) + codice[2]);
        payload.push((codice[5] << 4) + codice[4]);
        payload.push((
            (form['g1'].checked ? 1 : 0) +
            (form['g2'].checked ? 2 : 0) +
            (form['g3'].checked ? 4 : 0) +
            (form['gext'].checked ? 8 : 0) +
            (form['elettroserratura'].checked ? 16 : 0) +
            (form['ronda'].checked ? 32 : 0) +
            (form['silenzioso'].checked ? 64 : 0) +
            (form['antipanico'].checked ? 128 : 0)
        ));
        payload.push((
            parseInt(form['privilegi'].value)
        ));
        stringToArrayInt(16, form["nome"].value, 0x20).map(x => { payload.push(x); return null; });
        return payload;
    }

    const handleSubmit = e => {
        e.preventDefault();
        const form = codeForm.current;
        if (!!!form["nome"].value) {
            setError("Inserire un nome");
        } else if (!!!form["codice"].value) {
            setError("Inserire un codice");
        } else if (form["codice"].value != form["codice2"].value) {
            setError("La conferma del codice non corrisponde");
        } else {
            let already = false;
            let codice = form["codice"].value.split('').map(x => x == '0' ? 10 : parseInt(x)).join('');
            for (let i = 0x0063; i <= 0x00E2; i++) {
                let _s = system.store[i]
                    .split(',')
                    .map(x => parseInt(x));
                if (_s[4] & 128) {
                    continue;
                }
                let _x = _s.slice(0, 3)
                    .reduce((acc, cur) => { acc.push(cur & 0xF); acc.push(cur >> 4); return acc; }, [])
                    .filter(x => x != 0)
                    .join('');
                if (_x == codice) {
                    already = true;
                    break;
                }
            }
            if (already) {
                setError("Codice esistente");
            } else {
                setError(null);
                const payload = makePayload();
                let store = { [firstAvailable]: payload.join(',') };
                setStore(store);
                doSocketVariation(socket, firstAvailable, payload, false, false, `/europlus/${system.id}/code/${((firstAvailable - 0x0063) + 1)}`);
            }
        }
    }

    const handleProgramChange = e => {
        const form = codeForm.current;
        if (e.target.checked) {
            if (e.target.name === "gext") {
                for (let i = 1; i < 4; i++) {
                    form[`g${i}`].checked = false;
                }
            } else {
                form[`gext`].checked = !e.target.checked;
            }
        }
    }

    const handleChangeTeknox = e => {
        setCodeTeknox(parseInt(e.target.value));
        if (e.target.value == 2) {
            const form = codeForm.current;
            for (let i = 1; i < 4; i++) {
                form[`g${i}`].checked = false;
            }
            form[`gext`].checked = false;
        }
    };

    return (
        <section>
            <div className="innerSection">
                <div className="rack">
                    <div className="w100 formSection">
                        <h1>Nuovo Codice</h1>
                        {
                            error && (
                                <Outcome text={error} type="ko" onClose={setError} />
                            )
                        }
                        <form ref={codeForm}>
                            <>
                                <h1>Parametri</h1>
                                <fieldset>
                                    <div className="rack">
                                        <div className="w25">
                                            <label>Nome</label>
                                            <input type="text" name="nome" defaultValue={``} maxLength={16} />
                                        </div>
                                        <div className="w25">
                                            <label>Nuovo Codice</label>
                                            <input type="password" name="codice" defaultValue={``} maxLength={6} onKeyPress={e => { if (e.which < 48 || e.which > 57) { e.preventDefault(); } }} />
                                        </div>
                                        <div className="w25">
                                            <label>Conferma Codice</label>
                                            <input type="password" name="codice2" defaultValue={``} maxLength={6} onKeyPress={e => { if (e.which < 48 || e.which > 57) { e.preventDefault(); } }} />
                                        </div>
                                        <div className="w25">
                                            <label>Privilegi</label>
                                            <select defaultValue={1} name="privilegi" onChange={handleChangeTeknox}>
                                                <option value={1}>Utente</option>
                                                <option value={2}>Installatore</option>
                                                <option value={4}>Amministratore</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="rack">
                                        <div className="w25">
                                            <div className="switchButton">
                                                <input type="checkbox" className="switch" id="elettroserratura" name="elettroserratura" defaultValue="0" onChange={() => null} />
                                                <label htmlFor={`elettroserratura`}>
                                                    <span>
                                                        <span />
                                                    </span>
                                                    Elettroserratura
                                                    </label>
                                            </div>
                                        </div>
                                        <div className="w25">
                                            <div className="switchButton">
                                                <input type="checkbox" className="switch" id="ronda" name="ronda" defaultValue="0" onChange={() => null} />
                                                <label htmlFor={`ronda`}>
                                                    <span>
                                                        <span />
                                                    </span>
                                                    Ronda
                                                    </label>
                                            </div>
                                        </div>
                                        <div className="w25">
                                            <div className="switchButton">
                                                <input type="checkbox" className="switch" id="silenzioso" name="silenzioso" defaultValue="0" onChange={() => null} />
                                                <label htmlFor={`silenzioso`}>
                                                    <span>
                                                        <span />
                                                    </span>
                                                    Silenzioso
                                                    </label>
                                            </div>
                                        </div>
                                        <div className="w25">
                                            <div className="switchButton">
                                                <input type="checkbox" className="switch" id="antipanico" name="antipanico" defaultValue="0" onChange={() => null} />
                                                <label htmlFor={`antipanico`}>
                                                    <span>
                                                        <span />
                                                    </span>
                                                    Antipanico
                                                    </label>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <h1>Associazioni a programmi</h1>
                                <fieldset>
                                    <div className="rack">
                                        <h4>Programmi</h4>
                                        <div className="w25">
                                            <div className="switchButton">
                                                <input type="checkbox" className="switch" id="program_label_g1" name="g1" defaultValue="0" onChange={handleProgramChange} disabled={(codeTeknox == 2)} />
                                                <label htmlFor={`program_label_g1`}>
                                                    <span>
                                                        <span />
                                                    </span>
                                                    {system.g1}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="w25">
                                            <div className="switchButton">
                                                <input type="checkbox" className="switch" id="program_label_g2" name="g2" defaultValue="0" onChange={handleProgramChange} disabled={(codeTeknox == 2)} />
                                                <label htmlFor={`program_label_g2`}>
                                                    <span>
                                                        <span />
                                                    </span>
                                                    {system.g2}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="w25">
                                            <div className="switchButton">
                                                <input type="checkbox" className="switch" id="program_label_g3" name="g3" defaultValue="0" onChange={handleProgramChange} disabled={(codeTeknox == 2)} />
                                                <label htmlFor={`program_label_g3`}>
                                                    <span>
                                                        <span />
                                                    </span>
                                                    {system.g3}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="w25">
                                            <div className="switchButton">
                                                <input type="checkbox" className="switch" id="program_label_gext" name="gext" defaultValue="0" onChange={handleProgramChange} disabled={(codeTeknox == 2)} />
                                                <label htmlFor={`program_label_gext`}>
                                                    <span>
                                                        <span />
                                                    </span>
                                                    {system.gext}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rack" style={{ marginTop: "4em" }}>
                                        <div className="w100 right">
                                            <Link className="blackButton ok auto spaced" to={`/europlus/${system.id}`}>Annulla</Link>
                                            <button className="ok auto spaced" onClick={handleSubmit}>Applica</button>
                                        </div>
                                    </div>
                                </fieldset>
                            </>
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const Codice = connect(mapStateToProps, mapDispatchToProps)(_Codice);

export default Codice;