import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import {
    errorRead,
    getProfile,
    login
} from '../../../actions';
import Error from '../../elements/Error';
import SchemaValidator from '../../../lib/SchemaValidator';

const mapDispatchToProps = (dispatch) => ({
    errorRead: () => dispatch(errorRead()),
    getProfile: () => dispatch(getProfile()),
    login: payload => dispatch(login(payload))
});

const mapStateToProps = state => ({
    data: state.data,
    stateError: state.error,
    user: state.user
});

const _Login = ({ data, errorRead, login, stateError }) => {
    const initialPayload = {
        email: "",
        password: "",
    };

    const [errors, setErrors] = useState("");
    const [processed, setProcessed] = useState(false);
    const [payload, setPayload] = useState(initialPayload);

    useEffect(
        () => {
            if (stateError) {
                setErrors(stateError);
                errorRead();
            }
            return () => null;
        },
        [errorRead, errors, setErrors, stateError]
    );

    const handleChange = (e) => {
        let _payload = payload;
        _payload[e.target.name] = e.target.value;
        setPayload({
            ...payload,
            ..._payload,
        });
    };

    const isDisabled = () => !(payload.email.length > 0 && payload.password.length > 0);

    const handleLogin = (e) => {
        e.preventDefault();
        let v = SchemaValidator(payload, 'login');
        if (v.errors.length > 0) {
            setErrors(v.errors.map(el => el.schema.message).join("\n"));
        } else {
            login(payload);
        }
    }

    if (data && processed) {
        setErrors("");
        setProcessed(false);
        getProfile();
    }

    return (
        <section>
            <div>
                <img className="mainLogo" src="/static/images/testalince.png" alt="Lince" title="Lince Clouding" />
            </div>
            <div>
                <form name="login">
                    <fieldset>
                        <p>
                            <input type="text" placeholder="Email" name="email" onChange={handleChange} />
                        </p>
                        <p>
                            <input type="password" placeholder="Password" name="password" onChange={handleChange} />
                        </p>
                        <Error text={errors} />
                        <p>
                            <button disabled={isDisabled()} className="ok" onClick={handleLogin}>Login</button>
                        </p>
                        <p className="sameLine">
                            <small>
                                <span>
                                    <Link to={'/password-recovery'}>Recupera password</Link>
                                </span>
                                <span>
                                    <Link to={'/signup'}>Registrati</Link>
                                </span>
                            </small>
                        </p>
                    </fieldset>
                </form>
            </div>
        </section>
    );
};

const Login = connect(mapStateToProps, mapDispatchToProps)(_Login);

export default Login;