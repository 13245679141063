import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Modal from '../../../elements/Modal';
import Popup from '../../../elements/Modal/Popup';
import { clone } from '../../../../lib/clone';
import { j2bCodice } from '../../../../lib/goldParser/converter';
import { b2jCodice } from '../../../../lib/goldParser/physicalMap';

import {
    goldDoRequest,
    setStore
} from '../../../../actions';

const mapStateToProps = state => ({
    data: state.queue_data,
    errors: state.queue_errors,
    system: state.system
});

const mapDispatchToProps = (dispatch) => ({
    goldDelCode: payload => dispatch(goldDoRequest('goldDelCode', payload)),
    goldDelPeripheral: payloads => dispatch(goldDoRequest(['goldDelPeripheral', 'goldWriteCode', 'goldCheckSync'], payloads)),
    goldWriteCode: payload => dispatch(goldDoRequest('goldWriteCode', payload)),
    setStore: data => dispatch(setStore(data))
});

const _ChiaviCodici = ({ match, data, errors, system, goldDelCode, goldDelPeripheral, goldWriteCode, setStore }) => {
    const codeForm = useRef(null);
    const codeEditForm = useRef(null);
    const _system = clone(system);
    const [sub, setSub] = useState(null);
    const [firstLoad, setFirstLoad] = useState(false);
    const [redir, setRedir] = useState(null);
    const [current, setCurrent] = useState(null);
    const [codici, setCodici] = useState([]);
    const [processed, setProcessed] = useState(false);
    const [processed2, setProcessed2] = useState(false);
    const [processedEdit, setProcessedEdit] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [success, setSuccess] = useState(null);
    const [showEdit, setShowEdit] = useState(false);
    const [errorEdit, setErrorEdit] = useState(false);
    const [codeAuth, setCodeAuth] = useState(1);

    useEffect(
        () => {
            setFirstLoad(true);
            setSub(match.params.sub > 0 && match.params.sub <= 32 ? parseInt(match.params.sub) : 1);
            return () => null;
        },
        [match.params.sub]
    );

    useEffect(
        () => {
            if (firstLoad) {
                setRedir(null);
                setCurrent(null);
                setTimeout(() => {
                    let _codici = [];
                    for (let i = 0; i < _system.store.pm.codici.length; i++) {
                        _codici.push({
                            idx: i,
                            ..._system.store.pm.codici[i]
                        });
                    }
                    setCodici(_codici);
                    setFirstLoad(false);
                    setCurrent(_codici[(sub - 1)]);
                    setCodeAuth(_codici[(sub - 1)].tipo_utente)
                }, 100);
            }
            return () => null;
        },
        [firstLoad]
    );

    if (!!!system.store || !!!system.store.auth || system.store.auth !== 4) {
        return (
            <Modal
                title={`LinceCloud -> Impianto`}
                text={`Permessi insufficenti`}
                redirect={`/gold/${system.id}`}
            />
        );
    }

    const makePayload = () => {
        const form = codeForm.current;
        const payload = {
            tipo_codice: current.tipo_codice,
            nome: form.nome.value,
            g1: form.g1.checked,
            g2: form.g2.checked,
            g3: form.g3.checked,
            elettroserratura: form.elettroserratura.checked,
            ronda: form.ronda.checked,
            silenzioso: form.silenzioso.checked,
            antipanico: form.antipanico.checked,
            tipo_utente: parseInt(form.tipo_utente.value)
        };
        if(codeAuth === 2) {
            payload.elettroserratura = false;
            payload.antipanico = false;
            payload.ronda = false;
            payload.silenzioso = false;
            payload.g1 = false;
            payload.g2 = false;
            payload.g3 = false;
        }
        return payload;
    };

    const handleSelectChange = e => {
        const value = parseInt(e.target.value) + 1;
        setRedir(`/gold/${system.id}/code/${value}`);
    };

    const confirmDelete = e => {
        e.preventDefault();
        setConfirm(false);
        if(parseInt(current.tipo_codice) === 128) {
            goldDelPeripheral([
                {
                    id_centrale: system.id_centrale,
                    type: 'chiave',
                    n_disp: current.idx
                },
                {
                    id_centrale: system.id_centrale,
                    idx: current.idx,
                    edata: [0,0,0,0x07,0xFF,0,0,0, ...(Array(16).fill(0x20))],
                    code: true
                },
                {
                    id_centrale: system.id_centrale
                }
            ]);
        } else {
            goldDelCode({ 
                id_centrale: system.id_centrale,
                idx: current.idx
            });    
        }
        setProcessed2(true);
    };

    const handleSubmit = e => {
        e.preventDefault();
        const payload = makePayload();
        goldWriteCode({ 
            id_centrale: system.id_centrale,
            idx: current.idx,
            edata: j2bCodice(payload),
            code: false
        });
        setProcessed(true);
    };

    const handleEdit = e => {
        e.preventDefault();
        setShowEdit(true);
    };

    const handleEditCode = () => {
        const form = codeEditForm.current;
        if (form["codice"].value.length === 0) {
            setErrorEdit("Inserire un codice di almeno una cifra");
        } else if (form["codice"].value != form["codice2"].value) {
            setErrorEdit("La conferma del codice non corrisponde");
        } else {
            const payload = {
                ...current
            };
            goldWriteCode({ 
                id_centrale: system.id_centrale,
                idx: current.idx,
                edata: j2bCodice(payload, form["codice"].value),
                code: true
            });
            setProcessedEdit(true);
        }
    };

    const handleReset = e => { 
        e.preventDefault();
        const idx = current.idx;
        setCurrent(null);
        setTimeout(() => setCurrent(codici[idx]), 100);
    };

    if (redir) {
        const _redir = redir;
        return <Redirect to={_redir} />;
    }

    if (!!!current) {
        return <>caricamento in corso....</>;
    }

    if(data && processed) {
        setProcessed(null);
        if(!!data && !errors) {
            const form = codeForm.current;
            const nome = form.nome.value;
            const _codici = clone(codici);
            _codici[current.idx].nome = nome;
            setCodici(_codici); 
        }
        setSuccess(!!data && !errors);
    }

    if(data && processed2) {
        setProcessed2(null);
        let _success = (!!data && !errors) ? 2 : false;
        if(!!errors && errors.length > 0 && errors[0] == 'goldCheckSync') {
            _success = 3; 
        }
        if(_success === 2 || _success === 3) {
            let pm = clone(_system.store.pm);
            pm.codici[current.idx] = b2jCodice(['000000FF']); 
            setStore({
                pm
            });
        }
        setSuccess(_success);
    }

    if(data && processedEdit) {
        setProcessedEdit(null);
        if(!!data && !errors && current.idx === system.store.code_index) {
            const form = codeEditForm.current;
            const code = form["codice"].value;
            sessionStorage.setItem('systemCode', code);
            setStore({
                code
            });
        }
        setShowEdit(false);
        setSuccess(!!data && !errors);
    }

    return (
        <section>
            {success === true && <Modal title={`Impianto -> LinceCloud`} text={`Comando inviato con successo`} onClose={() => { setSuccess(null); }}/> }
            {success === 2 && <Modal title={`Impianto -> LinceCloud`} text={`${parseInt(current.tipo_codice) === 128 ? "Chiave eliminata" : "Codice eliminato" } con successo`} onClose={() => { setSuccess(null); setRedir(`/gold/${system.id}`) }}/> }
            {success === 3 && <Modal title={`Impianto -> LinceCloud`} text={`${parseInt(current.tipo_codice) === 128 ? "Chiave eliminata" : "Codice eliminato" } con successo`} redirectText={`Sincronizza`} onClose={() => { setSuccess(null); setRedir(`/gold/${system.id}`) }}/> }
            {success === false && <Modal title={`Impianto -> LinceCloud`} text={`Si é verificato un errore`} onClose={() => { setSuccess(null); }}/> }
            <div className="innerSection">
                {
                    showEdit && (
                        <Popup
                            title={`Modifica codice numerico`}
                            onClose={processedEdit ? false : () => { 
                                setErrorEdit(false);
                                setShowEdit(false);
                            }}
                            onConfirm={processedEdit ? false : () => { 
                                setErrorEdit(false);
                                handleEditCode();
                            }}
                        >
                            <div className="rack">
                                <div className="w100 formSection">
                                    <form ref={codeEditForm}>
                                        <fieldset>
                                            <div className="rack">
                                                <div className="w25">
                                                    <label>Codice</label>
                                                    <input
                                                        type="password"
                                                        onKeyPress={e => {
                                                            if (e.which < 48 || e.which > 57) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        maxLength={6}
                                                        autoComplete={`off`}
                                                        placeholder="Inserisci Codice"
                                                        name="codice"
                                                        defaultValue={``}
                                                    />
                                                </div>
                                                <div className="w25">
                                                    <label>Ripeti Codice</label>
                                                    <input
                                                        type="password"
                                                        onKeyPress={e => {
                                                            if (e.which < 48 || e.which > 57) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        maxLength={6}
                                                        autoComplete={`off`}
                                                        placeholder=""
                                                        name="codice2"
                                                        defaultValue={``}
                                                    />
                                                </div>
                                            </div>
                                            {
                                                errorEdit && (
                                                    <p style={{ textAlign: 'left' }}>{errorEdit}</p>
                                                )
                                            }
                                            {
                                                processedEdit && (
                                                    <p style={{ textAlign: 'left' }}>Modifica in corso...</p>
                                                )
                                            }
                                        </fieldset>
                                    </form>
                                </div>
                            </div>
                        </Popup>
                    )
                }
                <div className="rack">
                    <div className="w100 formSection">
                        {
                            confirm && (
                                <>
                                    <Modal
                                        title={`Cancellazione codice`}
                                        text={`Confermare l'eliminazione definitiva?`}
                                        onClose={() => { setConfirm(false); }}
                                        onConfirm={confirmDelete}
                                    />
                                </>
                            )
                        }
                        <form ref={codeForm}>
                            {current && (
                                <>
                                    <h1>Parametri</h1>
                                    <fieldset>
                                        <div className="rack">
                                            <div className="w25">
                                                <label>Numero</label>
                                                <select value={current.idx} name="numero" onChange={handleSelectChange}>
                                                    {
                                                        codici.map((value, index) => (
                                                            !value.available || index === (sub - 1)
                                                            ? <option key={`code_${index}`} value={index}>{`${index + 1} - ${value.nome} `}</option>
                                                            : <React.Fragment key={`code_${index}`} />
                                                        )
                                                    )}
                                                </select>
                                            </div>
                                            <div className="w25">
                                                <label>Nome</label>
                                                <input type="text" name="nome" defaultValue={current.nome} maxLength={16} />
                                            </div>
                                            <div className="w25">
                                                <label>Privilegi</label>
                                                <select defaultValue={current.tipo_utente} name="tipo_utente" onChange={e => { setCodeAuth(parseInt(e.target.value)); }}>
                                                    <option value={1}>Utente</option>
                                                    <option value={2} disabled={current.tipo_codice === 128}>Installatore</option>
                                                    <option value={4} disabled={current.tipo_codice === 128}>Amministratore</option>
                                                </select>
                                            </div>
                                            <div className="w25">
                                                <label>Tipologia</label>
                                                <select defaultValue={current.tipo_codice} name="tipo_codice" disabled={true}>
                                                    <option value={0}>Codice</option>
                                                    <option value={128}>Chiave</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="rack">
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="elettroserratura" name="elettroserratura" defaultChecked={current.elettroserratura} defaultValue="0" disabled={codeAuth === 2} />
                                                    <label htmlFor={`elettroserratura`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        Elettroserratura
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="antipanico" name="antipanico" defaultChecked={current.antipanico} defaultValue="0" disabled={codeAuth === 2} />
                                                    <label htmlFor={`antipanico`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        Antipanico
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="ronda" name="ronda" defaultChecked={current.ronda} defaultValue="0" disabled={codeAuth === 2} />
                                                    <label htmlFor={`ronda`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        Ronda
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="silenzioso" name="silenzioso" defaultChecked={current.silenzioso} defaultValue="0" disabled={codeAuth === 2} />
                                                    <label htmlFor={`silenzioso`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        Silenzioso
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <h1>Associazioni a programmi</h1>
                                    <fieldset>
                                        <div className="rack">
                                            <h4>Programmi</h4>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="program_label_g1" name="g1" defaultChecked={current.g1} defaultValue="0" disabled={codeAuth === 2} />
                                                    <label htmlFor={`program_label_g1`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        {system.g1}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="program_label_g2" name="g2" defaultChecked={current.g2} defaultValue="0" disabled={codeAuth === 2} />
                                                    <label htmlFor={`program_label_g2`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        {system.g2}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="program_label_g3" name="g3" defaultChecked={current.g3} defaultValue="0" disabled={codeAuth === 2} />
                                                    <label htmlFor={`program_label_g3`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        {system.g3}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="rack" style={{ marginTop: "4em" }}>
                                            <div className="w100 right">
                                                <button className="ok auto spaced" onClick={e => { e.preventDefault(); setConfirm(true); }} disabled={current.available || current.idx === system.store.code_index}>Elimina</button>
                                                <button className="ok auto spaced" onClick={handleEdit} disabled={current.available || current.tipo_codice === 128}>Modifica</button>
                                                <button className="ok auto spaced" onClick={handleReset} disabled={current.available}>Annulla</button>
                                                <button className="ok auto spaced" onClick={handleSubmit} disabled={current.available}>Applica</button>
                                            </div>
                                        </div>
                                    </fieldset>
                                </>
                            )}
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const ChiaviCodici = connect(mapStateToProps, mapDispatchToProps)(_ChiaviCodici);

export default ChiaviCodici;