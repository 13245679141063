import React from 'react';
import { version } from '../../../../package.json';
global.appVersion = version;

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.replace('-', '.').split(/\./g);
  const versionsB = versionB.replace('-', '.').split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());
    const b = Number(versionsB.shift());
    if (a === b) {
      continue;
    }
    return a > b || isNaN(b);
  }
  return false;
}

export default class CacheBuster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: async () => {
        console.log('Clearing cache and hard reloading...')
        if (caches) {
          const names = await caches.keys();
          await Promise.all(names.map(name => caches.delete(name)));
        }
        window.location.reload();
      }
    };
  }

  componentDidMount() {
    let _headers = new Headers();
    _headers.append('Pragma', 'no-cache');
    _headers.append('Cache-Control', 'no-cache');
    _headers.append('Cache-Control', 'no-store');
    fetch('/meta.json?t='+Date.now(), _headers)
      .then((response) => response.json())
      .then((meta) => {
          const latestVersion = meta.version;
          const currentVersion = global.appVersion;
          const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);          
          if (shouldForceRefresh) {
            console.log(`New version available - ${latestVersion}. Forcing refresh...`);
            this.setState({ loading: false, isLatestVersion: false });
          } else {
            console.log(`Updated to latest version ${latestVersion}.`);
            this.setState({ loading: false, isLatestVersion: true });
          }
      })
  }

  render() {
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
    return this.props.children({ loading, isLatestVersion, refreshCacheAndReload });
  }
}