import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import ReactSlider from 'react-slider';

import {
    doSocketRequest,
} from '../../../../actions';

import Modal from '../../../elements/Modal';

const mapStateToProps = state => ({
    system: state.system,
    socket: state.socket,
    data_socket: state.data_socket
});

const mapDispatchToProps = (dispatch) => ({
    doSocketVariation: (socket, trama, payload, noSuccess = false, noError = false) => dispatch(doSocketRequest(socket, trama, payload, true, noSuccess, noError)),
});

const _Tempi = ({ system, socket, doSocketVariation, data_socket }) => {
    const codeForm = useRef(null);
    const trama = 0x00E4;
    const [firstLoad, setFirstLoad] = useState(true);
    const [current, setCurrent] = useState(null);
    const [processed, setProcessed] = useState(false);
    const [tempoAllarme, setTempoAllarme] = useState(0);
    const [tempoCampanello, setTempoCampanello] = useState(0);
    const [tempoElettroserratura, setTempoElettroserratura] = useState(0);
    const [tempoFuoco, setTempoFuoco] = useState(0);
    const [tempoRonda, setTempoRonda] = useState(0);
    const [tempoSilenzioso, setTempoSilenzioso] = useState(0);

    const status = !!system && !!socket ? system.store.status : null;

    useEffect(
        () => {
            if (firstLoad && !!system.store.status) {
                if (!processed) {
                    doSocketVariation(socket, (trama + 0x0100), [], true, true);
                    setProcessed(true);
                }
                setFirstLoad(false);
            }
            return () => null;
        },
        [firstLoad, setFirstLoad, system, trama, current, doSocketVariation, processed, setProcessed, socket]
    );

    if (data_socket && processed) {
        setProcessed(false);
        if (parseInt(data_socket.type) === parseInt(trama)) {
            let _x = data_socket.payload.split(',').map(x => parseInt(x));
            let _current = {
                trama,
                payload: _x
            };
            setCurrent(_current);
            setTempoAllarme((_x[0] << 8) + _x[1]);
            setTempoCampanello(_x[2]);
            setTempoElettroserratura(_x[3]);
            setTempoFuoco(_x[4]);
            setTempoRonda((_x[5] << 8) + _x[6]);
            setTempoSilenzioso(_x[7]);
        }
    }

    var teknox = 0;
    if (!status || !system.store.connected) {
        return (
            <Modal
                title={`LinceCloud -> Impianto`}
                text={`Impianto non connesso`}
                redirect={`/europlus/${system.id}`}
            />
        );
    } else {
        if (!!status.structs) {
            teknox = status.structs.isTeknoxAuthorized.auth_level;
            if (teknox != 3) {
                return (
                    <Modal
                        title={`LinceCloud -> Impianto`}
                        text={`Necessari permessi di Installatore`}
                        redirect={`/europlus/${system.id}`}
                    />
                );
            }
        } else {
            return (<section></section>);
        }
    }

    const makePayload = () => {
        const payload = [
            (tempoAllarme >> 8 & 0xFF),
            (tempoAllarme & 0xFF),
            (tempoCampanello),
            (tempoElettroserratura),
            (tempoFuoco),
            (tempoRonda >> 8 & 0xFF),
            (tempoRonda & 0xFF),
            (tempoSilenzioso)
        ];
        return payload;
    }

    const handleSubmit = e => {
        e.preventDefault();
        const payload = makePayload();
        doSocketVariation(socket, current.trama, payload);
    }

    if (!!!current || firstLoad) {
        return <>caricamento in corso...</>;
    }

    return (
        <section>
            <div className="innerSection">
                <div className="rack">
                    <div className="w100 formSection">
                        <form ref={codeForm}>
                            {current && (
                                <>
                                    <h1>Tempi</h1>
                                    <fieldset>
                                        <div className="rack">
                                            <div className="w50">
                                                <p>Allarme</p>
                                                <ReactSlider
                                                    defaultValue={((current.payload[0] << 8) + current.payload[1])}
                                                    onChange={value => { setTempoAllarme(value); }}
                                                    max={5999}
                                                    min={0}
                                                    className="horizontal-slider"
                                                    thumbClassName="slider-thumb"
                                                    trackClassName="slider-track"
                                                    renderThumb={(props, state) => {
                                                        const [hrs, min, sec] = [
                                                            Math.floor(parseInt(state.valueNow) / 3600),
                                                            Math.floor((parseInt(state.valueNow) % 3600) / 60),
                                                            ((parseInt(state.valueNow) % 3600) % 60),
                                                        ];
                                                        return (<div {...props}>{`${(hrs ? `${hrs} ora` : ``)} ${min} min ${sec} sec`}</div>);
                                                    }}
                                                />
                                            </div>
                                            <div className="w50">
                                                <p>Fuoco</p>
                                                <ReactSlider
                                                    defaultValue={((current.payload[4]))}
                                                    onChange={value => { setTempoFuoco(value); }}
                                                    max={255}
                                                    min={0}
                                                    className="horizontal-slider"
                                                    thumbClassName="slider-thumb"
                                                    trackClassName="slider-track"
                                                    renderThumb={(props, state) => {
                                                        const [min, sec] = [
                                                            Math.floor(parseInt(state.valueNow) / 60),
                                                            parseInt(state.valueNow) % 60,
                                                        ];
                                                        return (<div {...props}>{`${min} min ${sec} sec`}</div>);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="rack">
                                            <div className="w50">
                                                <p>Silenzioso</p>
                                                <ReactSlider
                                                    defaultValue={(current.payload[7])}
                                                    onChange={value => { setTempoSilenzioso(value); }}
                                                    max={99}
                                                    min={0}
                                                    className="horizontal-slider"
                                                    thumbClassName="slider-thumb"
                                                    trackClassName="slider-track"
                                                    renderThumb={(props, state) => {
                                                        const [sec, dec] = [
                                                            Math.floor(parseInt(state.valueNow) / 10),
                                                            parseInt(state.valueNow) % 10,
                                                        ];
                                                        return (<div {...props}>{`${sec} sec ${dec} dec`}</div>);
                                                    }}
                                                />
                                            </div>
                                            <div className="w50">
                                                <p>Campanello</p>
                                                <ReactSlider
                                                    defaultValue={(current.payload[2])}
                                                    onChange={value => { setTempoCampanello(value); }}
                                                    max={99}
                                                    min={0}
                                                    className="horizontal-slider"
                                                    thumbClassName="slider-thumb"
                                                    trackClassName="slider-track"
                                                    renderThumb={(props, state) => {
                                                        const [sec, dec] = [
                                                            Math.floor(parseInt(state.valueNow) / 10),
                                                            parseInt(state.valueNow) % 10,
                                                        ];
                                                        return (<div {...props}>{`${sec} sec ${dec} dec`}</div>);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="rack">
                                            <div className="w50">
                                                <p>Elettroserratura</p>
                                                <ReactSlider
                                                    defaultValue={(current.payload[3])}
                                                    onChange={value => { setTempoElettroserratura(value); }}
                                                    max={99}
                                                    min={0}
                                                    className="horizontal-slider"
                                                    thumbClassName="slider-thumb"
                                                    trackClassName="slider-track"
                                                    renderThumb={(props, state) => {
                                                        const [sec, dec] = [
                                                            Math.floor(parseInt(state.valueNow) / 10),
                                                            parseInt(state.valueNow) % 10,
                                                        ];
                                                        return (<div {...props}>{`${sec} sec ${dec} dec`}</div>);
                                                    }}
                                                />
                                            </div>
                                            <div className="w50">
                                                <p>Ronda</p>
                                                <ReactSlider
                                                    defaultValue={((current.payload[5] << 8) + current.payload[6])}
                                                    onChange={value => { setTempoRonda(value); }}
                                                    max={5999}
                                                    min={0}
                                                    className="horizontal-slider"
                                                    thumbClassName="slider-thumb"
                                                    trackClassName="slider-track"
                                                    renderThumb={(props, state) => {
                                                        const [hrs, min, sec] = [
                                                            Math.floor(parseInt(state.valueNow) / 3600),
                                                            Math.floor((parseInt(state.valueNow) % 3600) / 60),
                                                            ((parseInt(state.valueNow) % 3600) % 60),
                                                        ];
                                                        return (<div {...props}>{`${(hrs ? `${hrs} ora` : ``)} ${min} min ${sec} sec`}</div>);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </fieldset>
                                    <fieldset>
                                        <div className="rack">
                                            <div className="w100 right">
                                                <button className="ok auto spaced" onClick={e => { e.preventDefault(); setFirstLoad(true); }}>Annulla</button>
                                                <button className="ok auto spaced" onClick={handleSubmit}>Applica</button>
                                            </div>
                                        </div>
                                    </fieldset>
                                </>
                            )}
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const Tempi = connect(mapStateToProps, mapDispatchToProps)(_Tempi);

export default Tempi;