import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
    system: state.system,
});

const _StatoImpianto = ({ system }) => {
    const status = !!system ? system.store.status : null;
    if (!status) {
        return (<section></section>);
    }
    const stato_centrale = [
        ['generali_1', 'guasto', 'red', 'gray', 'Guasto'],
        ['generali_5', 'fusibile_ven', 'red', 'green', 'Fusibile Uscite'],
        ['generali_1', 'rete', 'green', 'gray', 'Rete 220V presente'],
        ['generali_1', 'batteria_ex', 'green', 'gray', 'Stato di carica batteria esterna'],
        ['generali_1', 'batteria_in', 'green', 'gray', 'Stato di carica batteria di Centrale'],
        ['generali_1', 'servizio', 'red', 'gray', 'Stato di Servizio'],
        ['generali_1', 'allarme', 'red', 'gray', 'Allarme'],
    ];
    const espansioni = [
        ['espansioni', 'presente0', 'green', 'gray', 'Espansione 1'],
        ['espansioni', 'presente1', 'green', 'gray', 'Espansione 2'],
        ['espansioni', 'presente2', 'green', 'gray', 'Espansione 3'],
        ['espansioni', 'presente3', 'green', 'gray', 'Espansione 4'],
        ['espansioni', 'presente4', 'green', 'gray', 'Espansione 5'],
        ['espansioni', 'tastiera_radio', 'green', 'gray', 'Espansione Radio'],
        ['espansioni', 'conflitto', 'red', 'gray', 'Conflitto Espansione Radio'],
    ];

    const sabotaggi = [
        ['generali_1', 'as24_in', 'red', 'gray', 'Sabotaggio Centrale'],
        ['generali_1', 'as', 'red', 'gray', 'Sabotaggio Allarme Ingresso'],
        ['generali_4', 'as24', 'red', 'gray', 'Sabotaggio Ingressi'],
        ['generali_3', 'as24_remoto', 'red', 'gray', 'Sabotaggio Dispositivi su BUS'],
        ['generali_3', 'bus', 'red', 'gray', 'Sabotaggio Allarme integrità BUS'],
        ['generali_4', 'ingressi_aperti', 'red', 'gray', 'Ingressi Aperti'],
        ['generali_4', 'ingressi_esclusi', 'red', 'gray', 'Ingressi Esclusi'],
    ];

    const memorie = [
        ['generali_2', 'mem_as24_in', 'red', 'gray', 'Memoria Sabotaggio Centrale'],
        ['generali_2', 'mem_as_in', 'yellow', 'gray', 'Memoria Sabotaggio Allarme Ingresso'],
        ['generali_4', 'mem_as24_allarme', 'yellow', 'gray', 'Memoria Sabotaggio Ingressi'],
        ['generali_2', 'mem_24_inseritori', 'yellow', 'gray', 'Memoria Sabotaggio Dispositivi su BUS'],
        ['generali_2', 'mem_bus', 'yellow', 'gray', 'Memoria Sabotaggio Allarme integrità BUS'],
    ];

    return (
        <section>
            <div className="innerSection">
                <h1>Stato Impianto</h1>
                <div className="rack">
                    <div className="w50 light-boxed">
                        <h2>Stato Centrale</h2>
                        <div className="padp">
                            {
                                stato_centrale.map((item, index) => (
                                    <p key={`stato_centrale_${index}`}><i className="fa fa-circle" style={(status.structs[item[0]][item[1]] ? { color: [item[2]] } : { color: [item[3]] })} /> {item[4]}</p>
                                ))
                            }
                            <br />
                            <div className="rack">
                                <div className="w50">
                                    <p><strong>Tensione Batteria (V): <br /><big>{status.vbatt}</big></strong></p>
                                </div>
                                <div className="w50">
                                    <p><strong>Rel. SW Centrale: <br /><big>{`${status.firmware_ver_L}.${(status.firmware_ver_H).toString().padStart(2, '0')}`}</big></strong></p>
                                </div>
                            </div>
                            <div className="rack">
                                <div className="w50">
                                    <p><strong>Tensione Bus (V): <br /><big>{status.vbus}</big></strong></p>
                                </div>
                                <div className="w50">
                                    <p><strong>Temperatura (°C)*: <br /><big>{status.celsius}</big></strong></p>
                                </div>
                            </div>
                            <p><small><em>* La temperatura fa riferimento alla scheda EuroNET e non all'ambiente in cui la scheda è installata.</em></small></p>
                        </div>
                    </div>
                    <div className="w50 light-boxed">
                        <h2>Espansioni</h2>
                        <div className="padp">
                            {
                                espansioni.map((item, index) => (
                                    <p key={`espansioni_${index}`}><i className="fa fa-circle" style={(status.structs[item[0]][item[1]] ? { color: [item[2]] } : { color: [item[3]] })} /> {item[4]}</p>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className="rack">
                    <div className="w100">
                        <h2>Sabotaggi - Ingressi - Memorie</h2>
                        <div className="padp">
                            <div className="rack">
                                <div className="w50">
                                    {
                                        sabotaggi.map((item, index) => (
                                            <p key={`sabotaggi_${index}`}><i className="fa fa-circle" style={(status.structs[item[0]][item[1]] ? { color: [item[2]] } : { color: [item[3]] })} /> {item[4]}</p>
                                        ))
                                    }
                                </div>
                                {
                                    memorie.map((item, index) => (
                                        <p key={`memorie_${index}`}><i className="fa fa-circle" style={(status.structs[item[0]][item[1]] ? { color: [item[2]] } : { color: [item[3]] })} /> {item[4]}</p>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const StatoImpianto = connect(mapStateToProps)(_StatoImpianto);

export default StatoImpianto;