import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';

import Modal from '../../../elements/Modal';
import { b2jEvent } from '../../../../lib/goldParser/physicalMap';
import { bcd2str, hexstringToArrayInt } from '../../../../lib/bytesFunctions';
import { clone } from '../../../../lib/clone';

import {
    goldDoRequest,
    setStore
} from '../../../../actions';

const filter_arr = ['b','a','c','c','c','c','c','c','c','c',//0..9
				  'c','d','e','e','e','e','e','e','e','e',//10..19
				  'e','e','f','g','a','a','i','e','c','a',//20..29
				  'x','h','h','a','h','h','h','a','h','h',//30..39
				  'e','e','e','x','x','h','x','x','x','h',//40..49
				  'h','h','e','c','a','c','c','h','h','h'];

const mapStateToProps = state => ({
    data: state.queue_data,
    errors: state.queue_errors,
    system: state.system,
    sync: state.system.store.state.sync
});

const mapDispatchToProps = dispatch => ({
    goldGetMemoryEvents: (payload, login) => dispatch(goldDoRequest('goldGetMemoryEvents', payload, login, false)),
    goldGetMemoryEventsLast: payload => dispatch(goldDoRequest('goldGetMemoryEventsLast', payload)),
    goldClearMemory: payload => dispatch(goldDoRequest('goldClearMemory', payload)),
    setStore: data => dispatch(setStore(data))
});

const _MemoriaEventi = ({ data, system, errors, goldClearMemory, goldGetMemoryEvents, goldGetMemoryEventsLast, sync, setStore}) => {
    const MAX_CELLS = 511;
    const [pos, setPos] = useState(MAX_CELLS);
    const [running, setRunning] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [processed, setProcessed] = useState(false);
    const [processed1, setProcessed1] = useState(null);
    const [processed2, setProcessed2] = useState(false);
    const [success, setSuccess] = useState(null);
    const [events, setEvents] = useState([]);
    const [filter, setFilter] = useState("0");
    const [confirm, setConfirm] = useState(null);
    const [sort, setSort] = useState(1);

    const umount = useCallback(async () => {
        setStore({ polling_pause: false });
    }, []);

    useEffect(() => {
        setStore({ polling_pause: true });
        goldGetMemoryEventsLast({ id_centrale: system.id_centrale });
        setProcessed1(true);
        return async () => {
            await umount();
            return true;
        }
    }, [umount]);

    useEffect(() => {
        if(sync === 1 && running) {
            setRunning(false);
        } 
        return () => null
    }, [running, sync]);

    const getEvents = e => {
        e.preventDefault();
        if(completed) {
            setCompleted(false);
            setEvents([]);
            setPos(MAX_CELLS);
            goldGetMemoryEvents({ id_centrale: system.id_centrale, pos: MAX_CELLS }, true);
        } else {
            goldGetMemoryEvents({ id_centrale: system.id_centrale, pos }, true);
        }
        setRunning(true);
        setProcessed(true);
    };

    const stopGetEvents = e => {
        e.preventDefault();
        setRunning(false);
    };

    const handleConfirm = () => {
        setConfirm(false);
        goldClearMemory({ id_centrale: system.id_centrale, value: 1 });
        setProcessed2(true);
    }

    const download = () => {
        const element = document.createElement("a");
        const content = `Data/Ora Centrale;Tipo;Descrizione\n`;
        const file = new Blob([`${content}\n${(
            events.map(ev => {
                let _ev = b2jEvent(parseInt(ev.evento), parseInt(ev.cod1), parseInt(ev.cod2), system.store.pm);
                return `${ev.data} ${ev.ora};${_ev.name};${_ev.description}`;
            }).join('\n'))}`], { type: 'text/csv' });
        element.href = URL.createObjectURL(file);
        element.download = "MemoriaEventi.csv";
        document.body.appendChild(element);
        element.click();
    };

    if (!!!system.store || !!!system.store.auth || !(system.store.auth & 6)) {
        return (
            <Modal
                title={`LinceCloud -> Impianto`}
                text={`Permessi insufficenti`}
                redirect={`/gold/${system.id}`}
            />
        );
    }

    if(data && processed) {
        setProcessed(null);
        if(!!data[0] && data[0].event) {
            const event = hexstringToArrayInt(data[0].event);
            if(event[5] !== 0xFF) {
                const _events = clone(events);
                const year = parseInt(bcd2str(event[2])) + 2000;
                const month = bcd2str(event[1]);
                const day = bcd2str(event[0]);
                const hour = bcd2str(event[3]);
                const minute = bcd2str(event[4]);                
                _events.push({
                    evento: event[5],
                    cod1: event[6],
                    cod2: event[7],
                    data: `${day}/${month}/${year}`,
                    ora: `${hour}:${minute}`,
                    f: filter_arr[event[5]],
                    pos: data[0].pos + 1,
                    date: new Date(year, month - 1, day, hour, minute)
                }); 
                setEvents(_events);
            }
            const nextPos = parseInt(data[0].pos) - 1;
            if(nextPos < 0) {
                setCompleted(true);
                setRunning(false);
            } else {
                setPos(nextPos);
                if(running) {
                    setTimeout(() => {
                        goldGetMemoryEvents({ id_centrale: system.id_centrale, pos: nextPos }, false);
                        setProcessed(true);
                    }, 500);
                }
            }
        }
        setSuccess(!errors);
    }

    if(data && processed1) {
        setProcessed1(null);
        if(!!data[0] && data[0].status === "OK") {
            let res = hexstringToArrayInt(data[0].data[0]);
            let _pos = (((res[0] << 8) & 0xFF00) + (res[1] & 0xFF)) / 8;
            setPos(_pos);
        }
    }

    if(data && processed2) {
        setProcessed2(null);
        setEvents([]);
        setSuccess(!errors);
        setCompleted(false);
    }

    return (
        <section>
            {success === false && <Modal title={`Impianto -> LinceCloud`} text={`Si é verificato un errore`} onClose={() => { setSuccess(null); stopGetEvents({ preventDefault: () => null }); }}/> }
            <div className="innerSection">
                {confirm && (
                    <Modal
                        title={`Sicuro di voler procedere?`}
                        text={"La Memoria Eventi verrà cancellata"}
                        onClose={() => { setConfirm(false); }}
                        onConfirm={() => { handleConfirm(); }}
                    />
                )}
                <div className="rack">
                    <div className="w100 formSection">
                        <form>
                            <h1>Memoria Eventi</h1>
                            <fieldset>
                                <br />
                                <div className="rack">
                                    <div className="w25">
                                        <label>Filtro</label>
                                        <select defaultValue="0" name="filter" onChange={e => { setFilter(e.target.value); }}>
                                            <option value="0">Nessun filtro</option>
                                            <option value="a">Allarmi</option>
                                            <option value="b">Attivazioni/Disattivazioni</option>
                                            <option value="c">Sabotaggi</option>
                                            <option value="d">Supertasti</option>
                                            <option value="e">Guasti</option>
                                            <option value="f">Passaggio Ronda</option>
                                            <option value="g">Elettroserratura</option>
                                            <option value="h">Sistema</option>
                                            <option value="i">Campanello</option>
                                            <option value="j">Connessioni</option>
                                        </select>
                                    </div>
                                    <div className="w25">
                                        <label>Ordina</label>
                                        <select defaultValue="1" onChange={e => { setSort(parseInt(e.target.value))}}>
                                            <option value="1">Più recenti</option>
                                            <option value="0">Meno recenti</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="rack">
                                    <div className="w25">&nbsp;</div>
                                    <div className="w75" style={{ textAlign: 'right' }}>
                                        {running
                                            ? <button className="yellowButton ok auto spaced" onClick={stopGetEvents}>Interrompi</button>
                                            : <button className="yellowButton ok auto spaced" onClick={getEvents}>Scarica Memoria</button>
                                        }
                                        {` `}
                                        <button className="ok auto spaced" disabled={events.length === 0 || running} onClick={e => { e.preventDefault(); download(); }}>Esporta CSV</button>
                                        <button className="ok auto spaced red" disabled={running} onClick={e => { e.preventDefault(); setConfirm(true); }}>Cancella</button>
                                    </div>
                                </div>
                                <div className="rack">
                                    <div className="w100">
                                    {running
                                            ? <p className="loadingText">Download Memoria Eventi in corso</p>
                                            : completed 
                                                ? <p>Download completato</p>
                                                : <p>{' '}</p>
                                    }               
                                    </div>
                                    <div className="w100">
                                        <fieldset>
                                            <table className="controlPanelTable responsiveTable">
                                                <thead>
                                                    <tr>
                                                        <th style={{textAlign: 'left', maxWidth: "4em" }}>Posizione Memoria</th>
                                                        <th style={{textAlign: 'left' }}>Data/Ora Centrale</th>
                                                        <th style={{textAlign: 'left' }}>Tipo</th>
                                                        <th style={{textAlign: 'left' }}>Descrizione</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {events
                                                        .filter(ev => filter === "0" || filter === ev.f)
                                                        .sort((a,b) => { 
                                                            const comparison = a.date > b.date ? -1 : 1;
                                                            return comparison * (sort ? 1 : -1);
                                                        })
                                                        .map((acc => (ev, i) => {
                                                            if(parseInt(ev.evento) === 0x39) {
                                                                let [D, M, Y] = ev.data.split('/');
                                                                let [H, P] = ev.ora.split(':');
                                                                let d1 = new Date(Y, parseInt(M)-1, D, H, P);
                                                                if(!!acc && Math.abs(d1.getTime()-acc.getTime()) <= 60) {
                                                                     return <React.Fragment key={`ev_${i}`} />;
                                                                }
                                                                acc = d1;
                                                            } else {
                                                                acc = null;
                                                            }
                                                            let _ev = b2jEvent(parseInt(ev.evento), parseInt(ev.cod1), parseInt(ev.cod2), system.store.pm);
                                                            return (
                                                                <tr key={`ev_${i}`}>
                                                                    <td><span className="head">Posizione Memoria</span>#{ev.pos}</td>
                                                                    <td><span className="head">Data/Ora Centrale</span>{ev.data} {ev.ora}</td>
                                                                    <td><span className="head">Tipo</span>{_ev.name}</td>
                                                                    <td><span className="head">Descrizione</span>{`${_ev.description}`}</td>
                                                                </tr>
                                                            );
                                                        })(null))}
                                                </tbody>
                                            </table>
                                        </fieldset>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section >
    );
};

const MemoriaEventi = connect(mapStateToProps, mapDispatchToProps)(_MemoriaEventi);

export default MemoriaEventi;