import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import Modal from '../../../elements/Modal';
import {
    setStore
} from '../../../../actions';
import { goldDelPin, goldExitSystem } from '../../../../api/Cloud';

const mapStateToProps = state => ({
    system: state.system
});

const mapDispatchToProps = (dispatch) => ({
    setStore: data => dispatch(setStore(data)),
});

const _StatoGSM = ({ system, setStore }) => {
    const codeForm = useRef(null);
    const [current, setCurrent] = useState(null);
    const [deletePinOpen, setDeletePinOpen] = useState(false);
    const [pin, setPin] = useState('');
    const [success, setSuccess] = useState(null);

    useEffect(
        () => {
            setStore({
                polling_requests: ['goldGetGsmStat'],
                polling_payloads: [{ id_centrale: system.id_centrale }]
            });
            return () => {
                setStore({
                    polling_requests: [],
                    polling_payloads: []
                });
            };
        },
        []
    );

    useEffect(() => {
        setCurrent(system.store.gsm_state);
    }, [system.store.gsm_state]);

    const confirmDelete = async () => {
        setDeletePinOpen(false);
        const [__data, __code] = await goldDelPin({ id_centrale: system.id_centrale, edata: pin.split('').map(x => x.charCodeAt(0)) });
        const _success = (__code === 200 && __data.status === 'OK');
        if(_success) {
            setTimeout(async () => {
                await goldExitSystem({ id_centrale: system.id_centrale }); 
            }, 10000)
        }
        setSuccess(_success);
        setPin('');
    };

    if (!!!system.store || !!!system.store.auth || !(system.store.auth & 6)) {
        return (
            <Modal
                title={`LinceCloud -> Impianto`}
                text={`Permessi insufficenti`}
                redirect={`/gold/${system.id}`}
            />
        );
    }

    if (!!!current) {
        return <>caricamento in corso...</>;
    }

    return (
        <section>
            {success === true && <Modal title={`Impianto -> LinceCloud`} text={`Comando inviato con successo`} onClose={() => { setSuccess(null); }}/> }
            {success === false && <Modal title={`Impianto -> LinceCloud`} text={`Si é verificato un errore`} onClose={() => { setSuccess(null); }}/> }
            <div className="innerSection">
                <div className="rack">
                    <div className="w100 formSection">
                        {deletePinOpen && (
                            <Modal
                                title={`Cancellazione PIN`}
                                text={`Confermare l'eliminazione definitiva?`}
                                onClose={() => { setDeletePinOpen(false); }}
                                onConfirm={async e => { e.preventDefault(); await confirmDelete(); }}
                            />
                        )}
                        <form ref={codeForm} className="stackableW33">
                            <h1>Stato GSM</h1>
                            <fieldset>
                                <div className="rack">
                                    <div className="w33">
                                        <p><strong>Livello Segnale</strong></p>
                                        <div style={{
                                            border: '1px solid black',
                                            height: '1.5em',
                                            textAlign: 'center',
                                            display:'inline-block',
                                            width:'70%',
                                            position: 'relative',
                                            lineHeight: '1.5em',
                                            overflow: 'hidden'
                                        }}>
                                            <span style={{
                                                display: 'block',
                                                position: 'absolute',
                                                zIndex: 1,
                                                top: 0,
                                                width: `${(parseInt((current.segnale / 31) * 100))}%`,
                                                minWidth:`2%`,
                                                height: '1.5em',
                                                backgroundColor: '#337ab7'
                                            }}/>
                                            <span style={{
                                                display: 'block',
                                                zIndex: 2,
                                                width: '100%',
                                                position: 'absolute'
                                            }}>
                                                {(parseInt((current.segnale / 31) * 100))}%
                                            </span>
                                        </div>
                                        <div style={{
                                            margin: '0 1em',
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            lineHeight: '1.5em'
                                        }}>
                                            [{current.segnale}/31]
                                        </div>
                                    </div>
                                    <div className="w33">
                                        <p><strong>Stato Modulo GSM</strong></p>
                                        <p>{current.stato_gsm}</p>
                                    </div>
                                    <div className="w33">
                                        <p><strong>Stato SIM</strong></p>
                                        <p>{current.stato_sim}</p>
                                        <br />
                                    </div>
                                    <div className="w33">
                                        <p><strong>Stato Rete</strong></p>
                                        <p>{current.stato_network}</p>
                                        <br />
                                    </div>
                                    <div className="w33">
                                        <p><strong>Operatore</strong></p>
                                        <p>{current.operatore}</p>
                                        <br />
                                    </div>
                                    <div className="w33">
                                        <p><strong>Credito Residuo</strong></p>
                                        <p>{current.credito_residuo} Euro</p>
                                        <br />
                                    </div>
                                </div>
                            </fieldset>
                            {current.stato_sim_codice === 1 && (
                                <>
                                    <h1>Elimina PIN</h1>
                                    <fieldset>
                                        <br />
                                        <div className="rack">
                                            <div className="w33">
                                                <input
                                                    type="password"
                                                    onKeyPress={e => {
                                                        if (e.which < 48 || e.which > 57) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    maxLength={4}
                                                    autoComplete={`off`}
                                                    placeholder="Inserisci PIN"
                                                    name="pin"
                                                    defaultValue={``}
                                                    onChange={e => { setPin(e.target.value); }}
                                                />
                                            </div>
                                            <div className="w33">
                                                <button className="ok auto spaced" disabled={(pin.length != 4)} onClick={e => { e.preventDefault(); setDeletePinOpen(true); }}>Elimina</button>
                                            </div>
                                        </div>
                                    </fieldset>
                                </>
                            )}
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const StatoGSM = connect(mapStateToProps, mapDispatchToProps)(_StatoGSM);

export default StatoGSM;