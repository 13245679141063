export const defaultStore = {
    auth: null,
    code_index: null,
    code: null,
    lm: null,
    pm: null,
    sync_progress: 0,
    polling_start: new Date(),
    polling_pause: false,
    polling_payloads: [],
    polling_requests: [],
    dev_stats: {
        filare: { 0: [], 1: [] },
        radio: { 0: [], 1: [], 2: [], 3: [] },
        bus: { 0: [], 1: [] }
    },
    gsm_state: {
        stato_gsm: '-',
        stato_sim: 'Sconosciuta',
        stato_network: 'Non Registrato',
        operatore: '-',
        segnale: 0,
        credito_residuo: 0,
        scadenza_anno: null,
        scadenza_mese: null,
        scadenza_giorno: null,
        versione_modulo: '-'
    },
    state: {
        stato:{
            sabotaggio_centrale: false, 
            sabotaggio_as_esterno: false, 
            memoria_sabotaggio: false,
            memoria_sabotaggio_as: false,
            memoria_allarme_ingressi: false,
            memoria_sabotaggio_ingresso: false,
            allarme_inserito: false,
            servizio: false
        },
        alim: {
            rete_220_vca: false,
            stato_batteria_interna: false,
            fusibile: false,
            stato_batteria_esterna: false,
            presenza_batteria_interna: false,
            allarme_a: false,
            allarme_k: false,
            allarme_tecnologico: false
        },
        uscite: {},
        wifi: {},
        vbatt: 0,
        corrente: 0,
        prog: {
            g1: false,
            g2: false,
            g3: false
        },
        ingr: {
            supervisione_ingressi: false,
            guasto_ingressi_radio: false,
            sabotaggio_ingressi: false
        },
        bus: {
            tamper_bus: false,
            dispositivo_bus_intruso: false,
            sabotaggio_hw_bus: false
        },
        sync: 2,
        connesso: 0,
        fw_ver: 0
    }
};