import React from 'react';
import { connect } from 'react-redux';

import get_bits, { arrayIntToString } from '../../../../lib/bytesFunctions';

const mapStateToProps = state => ({
    system: state.system,
});

const _StatoIngressiRadio = ({ system }) => {
    const status = !!system ? system.store.status : null;
    if (!status) {
        return (<section></section>);
    }

    const ingressiRadio = [];
    for (let i = 0x23; i <= 0x62; i++) {
        ingressiRadio.push(system.store[i]);
    }

    return (
        <section>
            <div className="innerSection">
                <h1>Stato Ingressi Radio</h1>
                <div className="rack">
                    <div className="w100">
                        <form>
                            <fieldset>
                                <table className="controlPanelTable responsiveTable">
                                    <thead>
                                        <tr>
                                            <th>Nome Ingresso</th>
                                            <th>Allarme 24h</th>
                                            <th>Memoria 24h</th>
                                            <th>Ingresso Allarme</th>
                                            <th>Memoria Allarme</th>
                                            <th>Super Visione</th>
                                            <th>Batteria Scarica</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ textAlign: "center" }}>
                                        {ingressiRadio.map((item, index) => {
                                            let name = arrayIntToString(item.split(',').slice(3, 19));
                                            let group = Math.floor(index / 8);
                                            let bit = index % 8;
                                            return (
                                                <tr key={`row_${index}`}>
                                                    <td><span className="head">Nome Ingresso</span>{`${index + 1} - ${!!name ? name : `Ingresso Radio ${index + 1}`}`}</td>
                                                    <td><span className="head">Allarme 24h</span>{(get_bits(status[`as_radio_${group}`], bit) ? 'A' : '')}</td>
                                                    <td><span className="head">Memoria 24h</span>{(get_bits(status[`mem_as_radio_${group}`], bit) ? 'M' : '')}</td>
                                                    <td><span className="head">Ingresso Aperto</span>{(get_bits(status[`oi_radio_${group}`], bit) ? 'A' : '')}</td>
                                                    <td><span className="head">Memoria Allarme</span>{(get_bits(status[`mem_oi_radio_${group}`], bit) ? 'M' : '')}</td>
                                                    <td><span className="head">Super Visione</span>{(get_bits(status[`supervisioni_radio_${group}`], bit) ? 'S' : '')}</td>
                                                    <td><span className="head">Batteria Scarica</span>{(get_bits(status[`lo_batt_radio_${group}`], bit) ? 'S' : '')}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const StatoIngressiRadio = connect(mapStateToProps)(_StatoIngressiRadio);

export default StatoIngressiRadio;