export const GOLD_AUTH = {
    1: 'Utente',
    2: 'Installatore',
    4: 'Amministratore'
}

export const USCITE = [
    'Nessuno',
    'Allarme A',
    'Allarme K',
    'Stato Impianto',
    'Fuoco',
    'Silenzioso',
    'Campanello',
    'Elettroserratura',
    'Prog. G1',
    'Prog. G2',
    'Prog. G3',
    'Disturbo Radio',
    'Remoto',
    'Guasto',
    'Timer 1',
    'Timer 2',
    'Allagamento',
    'Ingressi Aperti'
];

const MCC_code = [
    202, 204, 206, 208, 212, 213, 214, 216, 218, 219, 220, 222, 225, 226, 228, 230, 231, 232, 234, 235,
    238, 240, 242, 244, 246, 247, 248, 250, 255, 257, 259, 260, 262, 266, 268, 270, 272, 274, 276, 278
];
const MCC_desc = [
    "GR", "NL", "BE", "FR", "MC", "AD", "ES", "HU", "BA", "HR", "RS", "IT", "VA", "RO", "CH", "CZ", "SK", "AT", "GB", "GB",
    "DK", "SE", "NO", "FI", "LT", "LV", "EE", "RU", "UA", "BY", "MD", "PL", "DE", "GI", "PT", "LU", "IE", "IS", "AL", "MT"
];

export const MCC = ((keys, values) => {
    let obj = {};
    for(let i=0; i<keys.length;i++) {
      obj[keys[i]] = values[i];
    }
    return obj
  })(MCC_code, MCC_desc);
