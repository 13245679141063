import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';

import {
    doRequest,
    goldDoRequest,
    setStore
} from '../../../../actions';
import { clone } from '../../../../lib/clone';
import { parsePhysicalMap, b2jGsmState } from '../../../../lib/goldParser/physicalMap';
import { checkZoneAperte, stateParser } from '../../../../lib/goldParser/stateParser';
import Modal from '../../../elements/Modal';
import { getSystem } from '../../../../api/Cloud';

const mapStateToProps = state => ({
    data: state.data,
    queue_data: state.queue_data,
    socket: state.socket,
    system: state.system
});

const mapDispatchToProps = (dispatch) => ({
    goldCheckSync: payload => dispatch(goldDoRequest('goldCheckSync', payload)),
    goldDoRequests: (requests, payloads, login = true) => dispatch(goldDoRequest(requests, payloads, login)),
    goldLogin: payload => dispatch(doRequest('goldLogin', payload)),
    goldSendComm: payload => dispatch(goldDoRequest('goldSendComm', payload)),
    setStore: data => dispatch(setStore(data))
});

const _PannelloControllo = ({ data, queue_data, socket, system, goldCheckSync, goldDoRequests, goldLogin, goldSendComm, setStore }) => {
    const codiceForm = useRef(null);
    const programmiForm = useRef(null);
    const [error, setError] = useState(null);
    const [code, setCode] = useState(null);
    const [processedLogin, setProcessedLogin] = useState(false);
    const [processedSendComm, setProcessedSendComm] = useState(false);
    const [processedSetOut, setProcessedSetOut] = useState(false);
    const [comm, setComm] = useState(null);
    const [touched, setTouched] = useState(false);
    const [program, setProgram] = useState(null);
    const [goldState, setGoldState] = useState(null);
    const [confirmComm, setConfirmComm] = useState(false);

    useEffect(() => {
        pollSystem();
        const interval = setInterval(async () => {
            await pollSystem();
        }, 3000);
        if(sessionStorage.getItem('systemIDC') === system.id_centrale) {
            goldCheckSync({ id_centrale: system.id_centrale });
        }
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if(!touched) {
            setProgram(system.store.state.programs);
        }
        return () => null;
    }, [system.store.state.programs, touched]);


    const pollSystem = async () => {
        if(system.store.pm) {
            setGoldState(null);
        } else {
            const [__data, __code] = await getSystem(system.id);
            if(__code === 200) {
                setGoldState(__data.goldstate);
            }    
        }
    };

    const handleSubmitLogin = (mode = 'login', check = true) => {
        const form = codiceForm.current;
        const checkZone = checkZoneAperte(system.store.state, (mode === 'on' ? 7 : null));
        if(mode === 'on' && check && !!checkZone[0]) {
            setConfirmComm([...checkZone, 'on']);
        } else {
            const _code = !!form['codice'].value ? form['codice'].value : (!!system.store.pm ? sessionStorage.getItem('systemCode') : ""); 
            if(_code === "") {
                setError(`Reinserisci codice`);
            } else {
                goldLogin({ id_centrale: system.id_centrale, code: _code, mode: mode === 'login' ? null : mode });
                setCode(form['codice'].value);
                form.reset();
                setTimeout(() => {
                    setProcessedLogin(mode);
                }, 100);    
    
            }
        }
    };

    const handleSubmitProgram = (check = true) => {
        const form = programmiForm.current;
        let prog = 0;
        prog += form['g1'].checked ? 1 : 0;
        prog += form['g2'].checked ? 2 : 0;
        prog += form['g3'].checked ? 4 : 0;
        const checkZone = checkZoneAperte(system.store.state, prog);
        if(!!checkZone[0] && check && prog > 0) {
            setConfirmComm([...checkZone, 'program']);
        } else {
            setTouched(true);
            setProgram(prog);
            goldSendComm({
                id_centrale: system.id_centrale,
                prog
            });
            setError(null);
            setComm(prog);
            setProcessedSendComm(true);    
        }
    };

    const handleSubmitSuper = index => {
        setError(null);
        if(index === 4) {
            goldLogin({ id_centrale: system.id_centrale, code: system.store.code, mode: 'on' });
            setTimeout(() => {
                const _i = system.store.code_index
                let _comm = !!system.store.pm.codici[_i].g1 ? 1 : 0;
                _comm+= !!system.store.pm.codici[_i].g2 ? 2 : 0;
                _comm+= !!system.store.pm.codici[_i].g3 ? 4 : 0;
                setTouched(true);
                setComm(_comm);
                setProgram(_comm);
                setProcessedSendComm(true);    
            }, 100);
        } else {
            const tempoAllarme = system.store.pm.tempi.allarme;
            const requests = [];
            const payloads = [];
            if((index & 1) > 0) {
                requests.push('goldSetOut');
                payloads.push({ id_centrale: system.id_centrale, pos: 0, value: 1, type:"filare", time: tempoAllarme });
            }
            if((index & 2) > 0) {
                requests.push('goldSetOut');
                payloads.push({ id_centrale: system.id_centrale, pos: 1, value: 1, type:"filare", time: tempoAllarme });            
            }
            goldDoRequests(requests, payloads);
            setTimeout(() => {
                setProcessedSetOut(true);
            }, 100);
        }
    };

    if(data && processedLogin) {
        const mode = processedLogin;
        if(data.status === 'OK') {
            setTouched(false);
            const _store = {
                auth: data.auth,
                code_index: data.index,
                code: data.code
            };
            sessionStorage.setItem('systemCode', code);
            sessionStorage.setItem('systemIDC', system.id_centrale);
            if(!!data.pm && !!data.sync && data.sync === 2) {
                _store.pm = parsePhysicalMap(data.pm);
            }
            _store.lm = data.lm || null;
            socket.on('onGoldState', _data => {
                if(!!system.store.state.no_update) {
                    let _state = clone(system.store.state);
                    _state.no_update = null;
                    setStore({
                        state: _state
                    });
                } else {
                    setStore({
                        state: stateParser(_data)
                    });
                }
            });
            socket.on('onGoldGsmState', _data => {
                setStore({
                    gsm_state: b2jGsmState(_data)
                });
            });
            setStore(_store);
            setTimeout(() => { 
                let requests = [];
                let payloads = [];
                if(mode === 'comm' && data.auth !== 2) {
                    let _mode = 'on';
                    let _prog = system.store.state.prog;
                    let _progs = 0;
                    _progs += !!_prog.g1 ? 1 : 0;
                    _progs += !!_prog.g2 ? 2 : 0;
                    _progs += !!_prog.g3 ? 4 : 0;
                    let _code = system.store.pm.codici[data.index];
                    let _comms = 0;
                    _comms += !!_code.g1 ? 1 : 0;
                    _comms += !!_code.g2 ? 2 : 0;
                    _comms += !!_code.g3 ? 4 : 0;
                    if(!!(_progs & _comms)) {
                        _mode = 'off';
                    }
                    requests.push('goldLogin');
                    payloads.push({ id_centrale: system.id_centrale, code: data.code, mode: _mode });
                }
                requests.push('goldCheckSync');
                payloads.push({ id_centrale: system.id_centrale });
                goldDoRequests(requests, payloads);
            }, 100);
        } else if(data.status === 'BUSY' || data.status === 'ODD') {
            setError(`Si é verificato un errore, riprovare`);
        } else if(data.status === 'NOT FOUND') {
            setError(`Problema di comunicazione con il Cloud`);
        } else {
            socket.off('onGoldState');
            socket.off('onGoldGsmState');
            setStore({
                auth: null,
                code_index: null,
                code: null
            });
            setError(`Codice non valido`);
            sessionStorage.removeItem('systemCode');
            sessionStorage.removeItem('systemIDC');
        }
        setProcessedLogin(false);
        setCode(null);
    }

    if(data && processedSendComm) {
        if(!!data && data.status === 'OK') {
            const prog = parseInt(comm);
            const _state = clone(system.store.state);
            _state.prog = {
                g1: !!(prog & 1),
                g2: !!(prog & 2),
                g3: !!(prog & 4)
            };
            _state.prog_active = !!(prog & 7);
            _state.no_update = true;
            setStore({
                state: _state
            });
        } else {
            setError(`Si é verificato un errore, riprovare`);
        }
        setProcessedSendComm(false);
        setComm(null);
    }

    if(queue_data && processedSetOut) {
        if((!!queue_data[0] && queue_data[queue_data.length -1].status === 'OK')) {
            setError(`Comando inviato con successo`);
        } else {
            setError(`Si é verificato un errore, riprovare`);
        }
        setProcessedSetOut(false);
    }

    return (
        <section>
            {error && (
                <Modal
                    title={`LinceCloud -> Impianto`}
                    text={error}
                    onClose={() => { setError(null); }}
                />
            )}
            {!!confirmComm && (
                <Modal
                    title={`Attenzione`}
                    text={(!!confirmComm[2]
                        ? `Centrale in supervisione. Verificare e/o escludere dispositivo in supervisione per effettuare la commutazione.`
                        : `Ci sono i seguenti impedimenti alla commutazione: ${confirmComm[1]}. Procedere comunque?`
                    )}
                    onConfirm={!!confirmComm[2]
                        ? null
                        : e => {
                            e.preventDefault(); 
                            setConfirmComm(null);
                            if(confirmComm[3] === 'on') {
                                handleSubmitLogin('on', false);
                            } else if(confirmComm[3] === 'program') {
                                handleSubmitProgram(false);                            
                            }
                        }
                    }
                    onClose={() => { setConfirmComm(null); }}
                />
            )}
            <div className="innerSection">
                <h1>Pannello di Controllo</h1>
                <div className="rack">
                    <div className="w50">
                        <div className="padp">
                            {!!system.store && system.store.state && !!(system.store.state.sync != 1) && (
                                <>
                                    <p>Codice</p>
                                    <form ref={codiceForm}>
                                        <fieldset>
                                            <input
                                                type="password"
                                                onKeyPress={e => {
                                                    if (e.which < 48 || e.which > 57) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                maxLength={6}
                                                autoComplete={`off`}
                                                placeholder="Inserisci Codice"
                                                name="codice"
                                                defaultValue={``}
                                                onKeyDown={e => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                        handleSubmitLogin();
                                                    }
                                                }}
                                            />
                                            <p>
                                                <button className="yellowButton" onClick={e => { e.preventDefault(); handleSubmitLogin(); }}>Login</button>&nbsp;
                                                {` `}
                                                <button className="yellowButton" onClick={e => { e.preventDefault();  handleSubmitLogin('on'); }}>Attivazione totale</button>&nbsp;
                                                {` `}
                                                <button className="yellowButton" onClick={e => { e.preventDefault();  handleSubmitLogin('off'); }}>Disattivazione totale</button>&nbsp;
                                            </p>
                                            <p><em>Inserire codici amministratore o installatore</em></p>
                                            {goldState && (!!(goldState.prog & 0x80)) && (
                                                <p><i className="fa fa-exclamation-triangle" /> La centrale è in stato di autenticazione</p>
                                            )}
                                        </fieldset>
                                    </form>
                                </>
                            )}
                            {program != null && !!system.store.pm && system.store.code_index != null && (
                                <>
                                    <p>Stato Programmi</p>
                                    <form ref={programmiForm}>
                                        <div className="bottomBorder">
                                            <div className="switchButton controlPanelButton">
                                                <input type="checkbox" className="switch" id="program_label_g1" name="g1" defaultValue="0" checked={!!(program & 1)} onChange={() => { setTouched(true); setProgram(program ^ 1); }} disabled={!system.store.pm.codici[system.store.code_index].g1} />
                                                <label htmlFor={`program_label_g1`}>
                                                    <span>
                                                        <span />
                                                    </span>
                                                    <blockquote className="programLabel programLabelFW red">{system.g1}</blockquote>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="bottomBorder">
                                            <div className="switchButton controlPanelButton">
                                                <input type="checkbox" className="switch" id="program_label_g2" name="g2" defaultValue="0" checked={!!(program & 2)} onChange={() => { setTouched(true); setProgram(program ^ 2); }} disabled={!system.store.pm.codici[system.store.code_index].g2} />
                                                <label htmlFor={`program_label_g2`}>
                                                    <span>
                                                        <span />
                                                    </span>
                                                    <blockquote className="programLabel programLabelFW yellow">{system.g2}</blockquote>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="bottomBorder">
                                            <div className="switchButton controlPanelButton">
                                                <input type="checkbox" className="switch" id="program_label_g3" name="g3" defaultValue="0" checked={!!(program & 4)} onChange={() => { setTouched(true); setProgram(program ^ 4); }} disabled={!system.store.pm.codici[system.store.code_index].g3} />
                                                <label htmlFor={`program_label_g3`}>
                                                    <span>
                                                        <span />
                                                    </span>
                                                    <blockquote className="programLabel programLabelFW green">{system.g3}</blockquote>
                                                </label>
                                            </div>
                                        </div>
                                        <fieldset>
                                            <div className="bottomBorder">
                                                <button className="yellowButton" onClick={e => { e.preventDefault(); handleSubmitProgram(); }} disabled={!touched || system.store.auth === 2}>Conferma stato programmi</button>&nbsp;
                                            </div>
                                        </fieldset>
                                    </form>
                                </>
                            )}
                        </div>
                    </div>
                    {!!system.store && !!system.store.auth && !!system.store.pm && (
                        <div className="w50">
                            <div className="padp">
                                <p>Attiva uscita relè</p>
                                <button className="superButton" disabled={!!!system.store.pm.supertasti.supertasto1 || system.store.auth === 2} onClick={e => { e.preventDefault(); handleSubmitSuper(1); }}>Supertasto 1</button>
                                <br />
                                <p>Attiva uscita OUT 1</p>
                                <button className="superButton" disabled={!!!system.store.pm.supertasti.supertasto2 || system.store.auth === 2} onClick={e => { e.preventDefault(); handleSubmitSuper(2); }}>Supertasto 2</button>
                                <br />
                                <p>Attiva uscita relè + uscita OUT 1</p>
                                <button className="superButton" disabled={!!!system.store.pm.supertasti.supertasto3 || system.store.auth === 2} onClick={e => { e.preventDefault(); handleSubmitSuper(3); }}>Supertasto 3</button>
                                <br />
                                <p>Inserimento totale impianto</p>
                                <button className="superButton" disabled={!!!system.store.pm.supertasti.supertasto4 || (system.store.state.prog.g1 && system.store.state.prog.g2 && system.store.state.prog.g3) || system.store.auth === 2} onClick={e => { e.preventDefault(); handleSubmitSuper(4); }}>Supertasto 4</button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const PannelloControllo = connect(mapStateToProps, mapDispatchToProps)(_PannelloControllo);

export default PannelloControllo;