import React from 'react';
import ReactSlider from 'react-slider';

import { roles } from '../../../constants';

const Association = ({ association = null, systems, handleChange, handleSubmit, saveButton = 'Salva', cancelButton = null, handleCancel = null, email = false, cat = false }) => {

    return (
        <div className="formSection">
            <form>
                <fieldset>
                    <div className="rack">
                        {
                            email
                                ? (
                                    <div className="w33">
                                        <label>Email</label>
                                        <input type="email" disabled={true} defaultValue={email} />
                                    </div>
                                )
                                : (
                                    <div className="w33">
                                        <label>Email*</label>
                                        <input type="email" defaultValue={``} name="associated_email" onChange={handleChange} />
                                    </div>
                                )
                        }
                        <div className="w33">
                            <label>Seleziona impianto*</label>
                            <select defaultValue={association != null ? association.system_id : 0} name="system_id" onChange={handleChange}>
                                <option value="0"></option>
                                {
                                    systems && (
                                        systems
                                            .filter(el => el.profiles.split(',').includes('proprietario'))
                                            .filter(el => el.valid === 1)
                                            .map((value, index) => {
                                                return <option key={index} value={value.id}>{value.name}</option>
                                            })
                                    )
                                }
                            </select>
                        </div>
                        {
                            cat
                                ? (
                                    <div className="w33">
                                        <label>Ore di attivazione*</label>
                                        <ReactSlider
                                            defaultValue={1}
                                            onChange={value => { handleChange({ target: value }); }}
                                            max={72}
                                            min={1}
                                            className="horizontal-slider horizontal-slider-association"
                                            thumbClassName="slider-thumb"
                                            trackClassName="slider-track"
                                            renderThumb={(props, state) => {
                                                return (<div {...props}>{`${state.valueNow}`}</div>);
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <div className="w33">
                                        <label>Profilo*</label>
                                        <select defaultValue={association != null ? association.profile : ``} name="profile" onChange={handleChange}>
                                            <option value="" disabled></option>
                                            {
                                                roles.map((value, index) => value === 'proprietario' ? null : <option key={index} value={value}>{value}</option>).filter(el => el != null)
                                            }
                                        </select>
                                    </div>
                                )
                        }
                    </div>
                    <div className="rack buttonPanel">
                        <div className="w50 left">
                            <p>&nbsp;</p>
                            {
                                cancelButton && (<button className="ok auto" onClick={handleCancel}>{cancelButton}</button>)
                            }
                        </div>
                        <div className="w50 right">
                            {
                                saveButton && (
                                    <>
                                        <p><small>I campi contrassegnati con (*) sono obbligatori</small></p>
                                        <button className="ok auto" onClick={handleSubmit}>{saveButton}</button>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    );
};

export default Association;