import React, { useState } from 'react';
import { matchPath } from 'react-router';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Activation from './Activation';
import AddUser from './AddUser';
import Cat from './Cat';
import Header from '../Header';
import Home from './Home';
import Login from './Login';
import NewSystem from './NewSystem';
import EditSystem from './EditSystem';
import NoMatch from './NoMatch';
import PasswordRecovery from './PasswordRecovery';
import PasswordReset from './PasswordReset';
import Premium from './Premium';
import Sim from './Sim';
import Profile from './Profile';
import SessionAuth from './SessionAuth';
import Settings from './Settings';
import Signup from './Signup';
import Gold from './Gold';
import Europlus from './Europlus';
import IsProfileComplete from '../../../lib/IsProfileComplete';

import './index.scss';

const mapStateToProps = state => ({
  auth: state.user.email,
  user: state.user,
});

const _Main = ({ auth, user, history }) => {

  const isCat = user.expiration_date != null;
  const profileComplete = isCat || IsProfileComplete(user);

  const [wide, setWide] = useState(window.screen.width < 1024);

  const handleToggleMenu = (value) => {
    setWide(value);
  }

  const isControlPanel = (auth && profileComplete)
      ? !!matchPath(history.location.pathname,'/gold/:id')
        ? 'gold'
        : !!matchPath(history.location.pathname,'/europlus/:id')
          ? 'europlus'
          : null
      : null
  ;

  return (
    <>
      <main className={(auth ? wide ? 'private wide' : 'private' : 'public')}>
        {auth
          ?
          <>
            <Header email={auth} pic={user.pic} handlerWide={handleToggleMenu} isControlPanel={isControlPanel} />
            {
              profileComplete
                ?
                isControlPanel
                  ? (
                    <Switch>
                      <Route path="/gold/:id([0-9]+)" render={(props) => <Gold {...props} />} />
                      <Route path="/europlus/:id([0-9]+)" render={(props) => <Europlus {...props} />} />
                      <Route component={NoMatch} />
                    </Switch>
                  ) : isCat
                    ? (
                      <Switch>
                        <Route exact path="/" render={(props) => <Home {...props} />} />
                        <Route component={NoMatch} />
                      </Switch>
                    ) : (
                      <Switch>
                        <Route exact path="/" render={(props) => <Home {...props} />} />
                        <Route exact path="/new-system" render={(props) => <NewSystem {...props} />} />
                        <Route exact path="/edit-system/:id([0-9]+)" render={(props) => <EditSystem {...props} />} />
                        <Route exact path="/add-user" render={(props) => <AddUser {...props} />} />
                        <Route exact path="/cat" render={(props) => <Cat {...props} />} />
                        <Route exact path="/profile" render={(props) => <Profile {...props} />} />
                        <Route exact path="/premium" render={(props) => <Premium {...props} />} />
                        <Route exact path="/sim" render={(props) => <Sim {...props} />} />
                        <Route exact path="/settings" render={(props) => <Settings {...props} />} />
                        <Route component={NoMatch} />
                      </Switch>
                    )
                : (
                  <Route render={(props) => <Profile {...props} />} />
                )
            }
          </>
          :
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/activation" component={Activation} />
            <Route exact path="/password-recovery" component={PasswordRecovery} />
            <Route exact path="/password-reset" component={PasswordReset} />
            <Route exact path="/session-auth" component={SessionAuth} />
            <Route exact path="/signup" component={Signup} />
            <Route component={NoMatch} />
          </Switch>
        }
      </main>
    </>
  );
};

const Main = withRouter(connect(mapStateToProps)(_Main));

export default Main;
