import React, { useState, useEffect, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import {
    errorRead,
    doRequest,
    getSystem
} from '../../../../actions';

import Outcome from '../../../elements/Outcome';

const mapStateToProps = state => ({
    data: state.data,
    stateError: state.error,
    system: state.system,
});

const mapDispatchToProps = (dispatch) => ({
    editSystem: (payload, id) => dispatch(doRequest('editSystem', { payload, id })),
    errorRead: () => dispatch(errorRead()),
    getEvents: () => dispatch(doRequest('getEvents')),
    getSystem: id => dispatch(getSystem(id)),
});

const _NotificheEventi = ({ system, editSystem, data, getEvents, stateError, errorRead }) => {
    const codeForm = useRef(null);
    const gExtEnabled = system.brand === 'lince-europlus';
    const [errors, setErrors] = useState("");
    const [events, setEvents] = useState([]);
    const [firstLoad, setFirstLoad] = useState(false);
    const [payload, setPayload] = useState(system.notifications || 0);
    const [payload1, setPayload1] = useState(system.receivers || 0);
    const [processed, setProcessed] = useState(false);
    const [processed1, setProcessed1] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(
        () => {
            if (!firstLoad) {
                setFirstLoad(true);
                setProcessed(true);
                getEvents();
            }
            if (stateError) {
                setErrors(stateError);
                errorRead();
            }

            return () => null;

        },
        [firstLoad, setFirstLoad, setProcessed, setErrors, errorRead, stateError]
    );
    const handleChange = (e) => {
        if (e.target.type === 'checkbox') {
            const v = e.target.checked ? payload + parseInt(e.target.value) : payload - parseInt(e.target.value);
            setPayload(v);
        }
    };

    const handleChange1 = (e) => {
        if (e.target.type === 'checkbox') {
            const v = e.target.checked ? payload1 + parseInt(e.target.value) : payload1 - parseInt(e.target.value);
            setPayload1(v);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors("");
        setSuccess(false);
        setProcessed1(true);
        editSystem({
            notifications: payload,
            receivers: payload1
        }, system.id);

    };

    if (Array.isArray(data) && processed) {
        setProcessed(false);
        setEvents(data);
    }

    if (data && processed1) {
        setProcessed1(false);
        setSuccess(true);
    }

    if (!!!system) {
        return (<></>);
    }
    return (
        <section>
            <div className="innerSection">
                {
                    success
                        ? (
                            <Outcome text="Operazione effettuata con successo" type="ok" onClose={setSuccess} />
                        )
                        : !!errors && errors.length
                            ? <Outcome text={errors} type="ko" onClose={setErrors} />
                            : <></>
                }
                <div className="rack">
                    <div className="w100 formSection">
                        <form ref={codeForm}>
                            <h1>Notifiche eventi</h1>
                            <fieldset>
                                <div className="rack">
                                    <div className="w50">
                                        <h4>Tipologie di eventi</h4>
                                        {events.length > 0 && events.map((el, index) => {
                                            if(el.code === 1024 && !gExtEnabled) {
                                                return <Fragment key={index} />
                                            }
                                            return <div key={index}>
                                                <input type="checkbox" id={`event_${index}`} name={`event_${index}`} defaultValue={el.code} defaultChecked={system.notifications & el.code ? 1 : 0} onChange={handleChange} />
                                                <label htmlFor={`event_${index}`}>
                                                    <span />
                                                    <strong style={{ textTransform: "capitalize" }}>{el.name}</strong>
                                                </label>
                                            </div>
                                        })}
                                    </div>
                                    <div className="w50">
                                        <h4>Profili che ricevono la notifica</h4>
                                        <div>
                                            <input type="checkbox" id={`profile_1`} name={`profile_1`} defaultValue={4} defaultChecked={system.receivers & 4 ? 1 : 0} onChange={handleChange1} />
                                            <label htmlFor={`profile_1`}>
                                                <span />
                                                <strong>Proprietario</strong>
                                            </label>
                                        </div>
                                        <div>
                                            <input type="checkbox" id={`profile_2`} name={`profile_2`} defaultValue={2} defaultChecked={system.receivers & 2 ? 1 : 0} onChange={handleChange1} />
                                            <label htmlFor={`profile_2`}>
                                                <span />
                                                <strong>Manutentore</strong>
                                            </label>
                                        </div>
                                        <div>
                                            <input type="checkbox" id={`profile_3`} name={`profile_3`} defaultValue={1} defaultChecked={system.receivers & 1 ? 1 : 0} onChange={handleChange1} />
                                            <label htmlFor={`profile_3`}>
                                                <span />
                                                <strong>Utilizzatore</strong>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="rack">
                                    <div className="w100 right">
                                        <button className="ok auto spaced" onClick={handleSubmit}>Salva</button>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section >
    );
};

const NotificheEventi = connect(mapStateToProps, mapDispatchToProps)(_NotificheEventi);

export default NotificheEventi;