import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Multiselect } from 'multiselect-react-dropdown';

import get_bits, { arrayIntToString, stringToArrayInt } from '../../../../lib/bytesFunctions';

import {
    doSocketRequest,
    setStore
} from '../../../../actions';

import Modal from '../../../elements/Modal';
import Outcome from '../../../elements/Outcome';

const mapStateToProps = state => ({
    system: state.system,
    socket: state.socket,
    data_socket: state.data_socket
});

const mapDispatchToProps = (dispatch) => ({
    doSocketVariation: (socket, trama, payload, noSuccess = false, noError = false) => dispatch(doSocketRequest(socket, trama, payload, true, noSuccess, noError)),
    setStore: data => dispatch(setStore(data))
});

const _IngressiRadio = ({ match, system, socket, doSocketVariation, data_socket, setStore }) => {
    const copyForm = useRef(null);
    const radioForm = useRef(null);
    const sub = match.params.sub && match.params.sub <= 64 ? parseInt(match.params.sub) : 1;
    const [trama, setTrama] = useState(sub + 34);
    const [firstLoad, setFirstLoad] = useState(true);
    const [errors, setErrors] = useState("");
    const [redir, setRedir] = useState(null);
    const [current, setCurrent] = useState(null);
    const [ingressi, setIngressi] = useState([]);
    const [ingressiTarget, setIngressiTarget] = useState([]);
    const [IDL, setIDL] = useState(0xFF);
    const [IDH, setIDH] = useState(0xFF);
    const [processed, setProcessed] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const status = !!system && !!socket ? system.store.status : null;

    useEffect(
        () => {
            if (firstLoad && !!system.store.status) {
                setRedir(false);
                if (!processed) {
                    doSocketVariation(socket, (trama + 0x0100), [], true, true);
                    setProcessed(true);
                }
                let _ingressi = [];
                for (let i = 0x0023, k = 0; i <= 0x0062; i++) {
                    if (!!system.store[i]) {
                        let _x = system.store[i].split(',').map(x => parseInt(x));
                        let available = (_x[0] + _x[1]) > 0 && (_x[0] + _x[1]) < 510;
                        let name_array = _x.slice(3, 19);
                        _ingressi.push({
                            trama: i,
                            index: k,
                            group: Math.floor(k / 8),
                            bit: k % 8,
                            name: available ? arrayIntToString(name_array) : "Non Disponibile",
                            label_name: available ? arrayIntToString(name_array) : "Non Disponibile",
                            payload: _x,
                            registered: available
                        });
                    }
                    k++;
                }
                setIngressi(_ingressi);
                setFirstLoad(false);
            }
            return () => null;
        },
        [firstLoad, setFirstLoad, setIngressi, system, setIDH, setIDL, trama, doSocketVariation, sub, setRedir, processed, setProcessed, socket]
    );

    useEffect(
        () => {
            if (current) {
                let _ingressiTarget = ingressi.map((value, index) => {
                    value.label = `${index + 1} - ${value.name}`;
                    return value.trama !== current.trama && (!!value.payload[0] && !!value.payload[1]) ? value : null;
                }
                ).filter(x => !!x);
                setIngressiTarget(_ingressiTarget);
            }
            return () => null;
        },
        [current]);

    if (data_socket && processed) {
        setProcessed(false);
        if (parseInt(data_socket.type) === parseInt(trama)) {
            let _x = data_socket.payload.split(',').map(x => parseInt(x));
            let name_array = _x.slice(3, 19);
            let sum = name_array.reduce((sum, x) => sum + x);
            let _sub = trama - 35;
            let idlidh = _x[0] + _x[1];
            let registered = (idlidh === 0 ^ idlidh === 510) === 0;
            let _current = {
                trama,
                index: _sub,
                group: Math.floor((_sub) / 8),
                bit: _sub % 8,
                name: registered ? arrayIntToString(name_array) : "Non Disponibile",
                label_name: registered ? arrayIntToString(name_array) : "Non Disponibile",
                payload: _x,
                registered
            };
            setCurrent(_current);
            setIDL(_x[0]);
            setIDH(_x[1]);
        }
    }

    var teknox = 0;
    if (!status || !system.store.connected) {
        return (
            <Modal
                title={`LinceCloud -> Impianto`}
                text={`Impianto non connesso`}
                redirect={`/europlus/${system.id}`}
            />
        );
    } else {
        if (!!status.structs) {
            teknox = status.structs.isTeknoxAuthorized.auth_level;
            if (teknox < 2) {
                return (
                    <Modal
                        title={`LinceCloud -> Impianto`}
                        text={`Permessi non sufficenti`}
                        redirect={`/europlus/${system.id}`}
                    />
                );
            }
        } else {
            return (<section></section>);
        }
    }

    const closeModal = e => {
        e.preventDefault();
        setConfirm(false);
    }

    const confirmDelete = e => {
        e.preventDefault();
        setConfirm(false);
        let _current = JSON.parse(JSON.stringify(current));
        doSocketVariation(socket, 0x00F9, [current.index]);
        _current.payload[0] = 255;
        _current.payload[1] = 255;
        for(let i=2; i<_current.payload.length;i++) {
            _current.payload[i] = 0;
        }
        let store = { [_current.trama]: _current.payload.join(',') };
        setStore(store);
        let _ingressi = [...ingressi];
        _ingressi[_current.index].name = "Non Disponibile";
        _ingressi[_current.index].label_name = "Non Disponibile";
        setIngressi(_ingressi);
        setTimeout(() => {
            setCurrent({
                trama: _current.trama,
                index: _current.index,
                name: 'Non Disponibile',
                label_name: 'Non Disponibile',
                payload: _current.payload,
                registered: false
            });
        }, 1000);
    }

    const handleSelectChange = e => {
        const value = parseInt(e.target.value) + 1;
        setCurrent(null);
        setFirstLoad(true);
        setTrama(value + 34);
        setRedir(`/europlus/${system.id}/radio/${value}`);
    };

    const makePayload = (name = null) => {
        const form = radioForm.current;
        const payload = [IDL, IDH];
        payload.push((
            (form['i2631'].checked ? 1 : 0) +
            (form['i2732'].checked ? 2 : 0) +
            (form['i2833'].checked ? 4 : 0) +
            (form['i2934'].checked ? 8 : 0) +
            (form['i3035'].checked ? 16 : 0) +
            (form['supervisionato'].checked ? 32 : 0) +
            (form['escluso'].checked ? 0 : 128)
        ));
        stringToArrayInt(16, (name != null ? name : form["nome"].value), 0x20).map(x => { payload.push(x); return null; });
        return payload;
    }

    const handleSubmit = e => {
        e.preventDefault();
        if (current.registered) {
            const payload = makePayload();
            doSocketVariation(socket, current.trama, payload);
            let store = { [current.trama]: payload.join(',') };
            setStore(store);
            handleSelectChange({ target: { value: current.index } });
        } else {
            setErrors(`Sensore non registrato`);
        }
    }

    const handleCopy = e => {
        e.preventDefault();
        const targets = copyForm.current.getSelectedItems();
        const settings = [];
        for (let i in targets) {
            let _payload = makePayload(targets[i].nome);
            _payload[0] = targets[i].payload[0];
            _payload[1] = targets[i].payload[1];
            settings.push({
                trama: targets[i].trama,
                payload: _payload
            })
        }
        socket.emit('sendQueue', JSON.stringify(settings));
    }

    if (redir) {
        return <Redirect to={redir} />
    }
    if (!!!current) {
        return <>caricamento in corso...</>;
    }

    return (
        <section>
            <div className="innerSection">
                <div className="rack">
                    <div className="w100 formSection">
                        {!!errors && (
                            <Outcome text={errors} type="ko" onClose={setErrors} />
                        )}
                        {
                            confirm && (
                                <>
                                    <Modal
                                        title={`Cancellazione ingresso radio`}
                                        text={`Confermare l'eliminazione definitiva?`}
                                        onClose={closeModal}
                                        onConfirm={confirmDelete}
                                    />
                                </>
                            )
                        }
                        <form ref={radioForm}>
                            {current && (
                                <>
                                    <h1>Parametri di Ingresso</h1>
                                    <fieldset>
                                        <div className="rack">
                                            <div className="w25">
                                                <label>Numero</label>
                                                <select value={current.index} name="numero" onChange={handleSelectChange}>
                                                    {
                                                        ingressi.map((value, index) => <option key={index} value={index}>{`${index + 1} - ${value.label_name} `}</option>)
                                                    }
                                                </select>
                                            </div>
                                            <div className="w25">
                                                <label>Nome</label>
                                                <input type="text" name="nome" defaultValue={current.name || ``} maxLength={16} />
                                            </div>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="supervisionato" name="supervisionato" defaultValue="0" defaultChecked={get_bits(current.payload[2], 5)} onChange={() => null} disabled={teknox !== 3} />
                                                    <label htmlFor={`supervisionato`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        Supervisionato
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="escluso" name="escluso" defaultValue="0" defaultChecked={!get_bits(current.payload[2], 7)} onChange={() => null} />
                                                    <label htmlFor={`escluso`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        Escluso
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <h1>Associazioni a ingresso</h1>
                                    <fieldset>
                                        <div className="rack bottomBorder">
                                            <p>
                                                <input type="checkbox" id="2631" name="i2631" defaultValue="0" defaultChecked={get_bits(current.payload[2], 0)} onChange={() => null} disabled={teknox !== 3} />
                                                <label htmlFor={`2631`}>
                                                    <span />
                                                    Ingresso filare associato: 26/31
                                                </label>
                                            </p>
                                            <p>
                                                <input type="checkbox" id="2732" name="i2732" defaultValue="0" defaultChecked={get_bits(current.payload[2], 1)} onChange={() => null} disabled={teknox !== 3} />
                                                <label htmlFor={`2732`}>
                                                    <span />
                                                    Ingresso filare associato: 27/32
                                                </label>
                                            </p>
                                            <p>
                                                <input type="checkbox" id="2833" name="i2833" defaultValue="0" defaultChecked={get_bits(current.payload[2], 2)} onChange={() => null} disabled={teknox !== 3} />
                                                <label htmlFor={`2833`}>
                                                    <span />
                                                    Ingresso filare associato: 28/33
                                                </label>
                                            </p>
                                            <p>
                                                <input type="checkbox" id="2934" name="i2934" defaultValue="0" defaultChecked={get_bits(current.payload[2], 3)} onChange={() => null} disabled={teknox !== 3} />
                                                <label htmlFor={`2934`}>
                                                    <span />
                                                    Ingresso filare associato: 29/34
                                                </label>
                                            </p>
                                            <p>
                                                <input type="checkbox" id="3035" name="i3035" defaultValue="0" defaultChecked={get_bits(current.payload[2], 4)} onChange={() => null} disabled={teknox !== 3} />
                                                <label htmlFor={`3035`}>
                                                    <span />
                                                    Ingresso filare associato: 30/35
                                                </label>
                                            </p>
                                        </div>
                                    </fieldset>
                                    <h1>Stato dell'ingresso</h1>
                                    <fieldset>
                                        <div className="rack">
                                            <div className="w100">
                                                <p><i className="fa fa-circle" style={(get_bits(status[`as_radio_${current.group}`], current.bit) ? { color: 'yellow' } : { color: 'gray' })} /> Allarme 24h</p>
                                                <p><i className="fa fa-circle" style={(get_bits(status[`mem_as_radio_${current.group}`], current.bit) ? { color: 'yellow' } : { color: 'gray' })} /> Memoria 24h</p>
                                                <p><i className="fa fa-circle" style={(get_bits(status[`oi_radio_${current.group}`], current.bit) ? { color: 'red' } : { color: 'gray' })} /> Allarme</p>
                                                <p><i className="fa fa-circle" style={(get_bits(status[`mem_oi_radio_${current.group}`], current.bit) ? { color: 'red' } : { color: 'gray' })} /> Memoria Allarme</p>
                                                <p><i className="fa fa-circle" style={(get_bits(status[`supervisioni_radio_${current.group}`], current.bit) ? { color: 'red' } : { color: 'gray' })} /> Supervisionato</p>
                                                <p><i className="fa fa-circle" style={(get_bits(status[`lo_batt_radio_${current.group}`], current.bit) ? { color: 'red' } : { color: 'gray' })} /> Batteria scarica</p>
                                            </div>
                                            <div className="w100 right">
                                                <button className="ok auto spaced" onClick={e => { e.preventDefault(); setConfirm(true); }} disabled={!current.registered}>Elimina</button>
                                                <button className="ok auto spaced" onClick={() => { handleSelectChange({ target: { value: current.index, scrollToBottom: true } }); }}>Annulla</button>
                                                <button className="ok auto spaced" onClick={handleSubmit} disabled={!current.registered}>Applica</button>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <h1>Copia impostazioni negli ingressi radio</h1>
                                    <fieldset>
                                        {
                                            teknox === 3
                                                ? (
                                                    <div className="rack">
                                                        <div className="w50" style={{ marginBottom: "12em" }}>
                                                            <label>Numero</label>
                                                            <Multiselect
                                                                closeOnSelect={false}
                                                                displayValue="label"
                                                                emptyRecordMsg="Nessun ingresso disponibile"
                                                                options={ingressiTarget}
                                                                placeholder={`Seleziona uno o piu' ingressi`}
                                                                ref={copyForm}
                                                            />
                                                        </div>
                                                        <div className="w50 right">
                                                            <p>
                                                                <br />
                                                                <button className="ok auto" onClick={handleCopy} disabled={!current.registered}>Copia</button>
                                                            </p>
                                                        </div>
                                                    </div>
                                                )
                                                : (
                                                    <p>Necessari permessi da installatore</p>
                                                )
                                        }
                                    </fieldset>
                                </>
                            )}
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const IngressiRadio = connect(mapStateToProps, mapDispatchToProps)(_IngressiRadio);

export default IngressiRadio;