import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addUrlProps, UrlQueryParamTypes } from 'react-url-query';

import { loadStripe } from '@stripe/stripe-js';

import { doRequest, errorRead } from '../../../actions';

import Modal from '../../elements/Modal';
import Outcome from '../../elements/Outcome';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_PK);

const urlPropsQueryConfig = {
    session_id: { type: UrlQueryParamTypes.string },
}

const mapDispatchToProps = (dispatch) => ({
    deleteSubscription: () => dispatch(doRequest('deleteSubscription')),
    getCheckoutSession: type => dispatch(doRequest('getCheckoutSession', type)),
    verifyCheckoutSession: payload => dispatch(doRequest('verifyCheckoutSession', payload)),
    errorRead: () => dispatch(errorRead()),
});

const mapStateToProps = state => ({
    user: state.user,
    data: state.data,
    stateError: state.error
});

const _Premium = ({ data, errorRead, getCheckoutSession, stateError, session_id, user, verifyCheckoutSession, deleteSubscription }) => {
    const [confirm, setConfirm] = useState(false);
    const [processed, setProcessed] = useState(false);
    const [processed2, setProcessed2] = useState(false);
    const [processedDelete, setProcessedDelete] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [errors, setErrors] = useState("");
    const isPremium = user.premium_date;
    const isSimPremium = user.sim_premium_date;

    useEffect(
        () => {
            if (!!session_id && firstLoad) {
                verifyCheckoutSession({ session_id });
                setProcessed2(true);
                setFirstLoad(false);
            }
            if (stateError) {
                setErrors(stateError);
                errorRead();
            }
            return () => setFirstLoad(false);
        },
        [errorRead, setErrors, stateError, session_id, setFirstLoad, firstLoad, verifyCheckoutSession, setProcessed2]
    );

    const handleClickMontlhy = async (e) => {
        e.preventDefault();
        getCheckoutSession('checkout-monthly');
        setProcessed(true);
    }

    const handleClickYearly = async (e) => {
        e.preventDefault();
        getCheckoutSession('checkout-yearly');
        setProcessed(true);
    }

    const handleClickDelete = async (e) => {
        e.preventDefault();
        setConfirm(true);
    }

    const closeModal = e => {
        e.preventDefault();
        setConfirm(false);
    }

    const confirmDelete = e => {
        e.preventDefault();
        deleteSubscription();
        setProcessedDelete(true);
        setConfirm(false);
    }

    const handleAfterClick = async sessionId => {
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({ sessionId });
        if (error) {
            setErrors(error.message);
        }
    };

    if (data && processed) {
        setErrors("");
        setProcessed(false);
        const { id } = data;
        handleAfterClick(id);
    }

    if (data && processed2) {
        setErrors("");
        setProcessed2(false);
        window.location.replace(window.location.pathname);
        return (<></>);
    }

    if (data && processedDelete) {
        setErrors("");
        setProcessedDelete(false);
        window.location.replace(window.location.pathname);
        return (<></>);
    }

    return (
        <section>
            <div className="innerSection">
                <h1>Servizi a pagamento</h1>
                {!!errors && errors.length
                    ? <Outcome text={errors} type="ko" onClose={setErrors} />
                    : <></>
                }
                <div className="formSection">
                    {
                        confirm && (
                            <>
                                <Modal
                                    title={`Elimina abbonamento`}
                                    text={`Confermare la cancellazione definitiva dell'account Premium?`}
                                    onClose={closeModal}
                                    onConfirm={confirmDelete}
                                />
                            </>
                        )
                    }
                    <form>
                        <fieldset>
                            <div className="rack">
                                <div className="w50">
                                    <p><strong>Scopri i vantaggi dell'utenza Premium:</strong></p>
                                    <ul>
                                        <li>Gestione senza limiti di più impianti</li>
                                        {/*<li>Import/Export della configurazioni degli impianti</li>*/}
                                        <li>Manutenzione impianto installatore tramite utenza CAT</li>
                                    </ul>
                                    <p><strong>Costi e pagamenti</strong></p>
                                    <ul>
                                        <li>Attivazione dell’account pro con un mese di prova gratuita</li>
                                        <li>1,99 €/mese per un minimo di tre mesi</li>
                                        <li>19,9 €/anno se pagati in unica soluzione</li>
                                    </ul>
                                </div>
                                {
                                    isSimPremium
                                        ? (
                                            <div className="w50">
                                                <h3>Account premium attivo</h3>
                                                <p>Sei un utente Premium in quanto hai attivato il servizio SIM</p>
                                                <Link title={`Gestione SIM`} to={`/sim`}>
                                                    Vai alla Gestione SIM
                                                </Link>
                                            </div>
                                        ) : (
                                            isPremium
                                            ? (
                                                <div className="w50">
                                                    <h3>Account premium attivo</h3>
                                                    <p>Scadenza: {((new Date(isPremium)).toLocaleDateString('it-it', { year: 'numeric', month: 'long', day: 'numeric' }))}</p>
                                                    <button role="link" onClick={handleClickDelete}>
                                                        Interrompi abbonamento
                                                    </button>
                                                </div>
                                            ) : (
                                                <div className="w50">
                                                    <div>
                                                        <h3>Abbonamento mensile</h3>
                                                        <button role="link" onClick={handleClickMontlhy}>
                                                            Vai al pagamento
                                                        </button>
                                                    </div>
                                                    <div>
                                                        <h3>Abbonamento annuale</h3>
                                                        <button role="link" onClick={handleClickYearly}>
                                                            Vai al pagamento
                                                        </button>
                                                    </div>
                                                </div>
                                            )
    
                                        )
                                }
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        </section>
    );
};

const Premium = addUrlProps({ urlPropsQueryConfig })(connect(mapStateToProps, mapDispatchToProps)(_Premium));

export default Premium;